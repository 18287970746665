// 本地存储


export default {
    // 存
    set(key, value) {
        return localStorage.setItem(key, value)
    },
    // 取
    get(key) {
        return localStorage.getItem(key)
    },
    // 删除一条
    remove(key) {
        return localStorage.removeItem(key)
    },
    // 清空
    clear() {
        return localStorage.clear()
    }
}