<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">分类列表</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增分类</el-button
            >
            <!-- 分类列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 图片名称 -->
                <el-table-column label="分类名称" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.catName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 轮播图 -->
                <!-- <el-table-column label="分类图标">
                  <template slot-scope="scope">
                    <div>
                      <img class="bannerImg" :src="scope.row.catIcon" alt="" />
                    </div>
                  </template>
                </el-table-column> -->
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="200">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <!-- 新增的弹窗 -->
              <el-dialog title="新增分类" :visible.sync="dialogFormVisible">
                <el-form :model="addForm">
                  <!-- <el-form-item label="分类图标:">
                    <el-upload
                      ref="uploads"
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :limit="1"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item> -->

                  <el-form-item label="分类名称:">
                    <el-input
                      style="width: 200px"
                      v-model="addForm.catName"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="分类排序:">
                    <el-input
                      style="width: 200px"
                      v-model="addForm.sort"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 编辑的弹窗 -->
              <el-dialog title="编辑分类" :visible.sync="dialogFormVisibles">
                <el-form :model="editForm">
                  <!-- <el-form-item label="分类图标:">
                    <el-upload
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :limit="1"
                      :file-list="imgUrl"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item> -->

                  <el-form-item label="分类名称:">
                    <el-input v-model="editForm.catName">{{
                      editForm.catName
                    }}</el-input></el-form-item
                  >
                  <el-form-item label="分类排序:">
                    <el-input style="width: 200px" v-model="editForm.sort">{{
                      editForm.sort
                    }}</el-input>
                  </el-form-item>
                </el-form>

                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      imgUrl: [], //商品图片
      img: "",
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogVisible: false,
      dialogImageUrl: "",
      editForm: {},
      addForm: {
        catName: "",
        sort: "",
      },
      form: {
        name: "",
      },
      // 表格数据
      tableData: [],
      actlist: [],
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },

    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({ url: v.split("?")[0] });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove() {
      this.imgUrl.splice(0, 1);
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.typeList();
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(index, row);
      this.editForm = row;
      this.dialogFormVisibles = true;
      this.imgUrl = [];
      this.imgUrl.push({ url: row.catIcon });
    },
    handleDelete(index, row) {
      console.log(index, row);
      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.id;
        that.$api.storetypeDel({ id: id }).then((res) => {
          if (res.status == 200) {
            this.currPage = 1;
            that.typeList();

            this.$message.success(res.result);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    // 新增广告
    add() {
      this.imgUrl = [];
      this.dialogFormVisible = true;
    },
    ok() {
      if (this.imgUrl == []) {
        this.$message.error("请添加图片");
        return;
      }
      if (this.addForm.catName === "") {
        this.$message.error("请输入分类名称!");
        return;
      }
      if (this.addForm.sort === "") {
        this.$message.error("请输入分类排序!");
        return;
      }
      let img = "";
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img = item.url;
        });
        console.log(img, "imgUrl");
      }
      let typeAdd = {
        catName: this.addForm.catName,
        catIcon: img,
        catType: 2,
        sort: this.addForm.sort,
      };
      this.$api.storetypeAdd(typeAdd).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.typeList();
          this.dialogFormVisible = false;
          this.$refs.upload.clearFiles();
          this.addForm = { brand_right: 0 };
        } else {
          this.$message.error(res.message);
        }
      });
    },
    oks() {
      let img = "";
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img = item.url;
        });
        console.log(img, "imgUrl");
      }
      let typeEdit = {
        id: this.editForm.id,
        catId: this.editForm.catId,
        catName: this.editForm.catName,
        catIcon: img,
        catType: 2,
        sort: this.editForm.sort,
      };
      this.$api.storetypeEdit(typeEdit).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibles = false;
          this.$message.success(res.result);
          this.typeList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    typeList() {
      let type = {
        page: this.currPage,
        pageSize: this.pageSize,
        catType: 2,
      };
      this.$api.storetype(type).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  created() {
    this.typeList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 100px;
    height: 100px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>