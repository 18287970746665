<template>
  <div class="login" id="app">
    <!-- 表单 -->
    <div class="form">
      <el-radio-group v-model="resource">
        <el-radio label="平台登录"></el-radio>
        <el-radio label="商家登录"></el-radio>
      </el-radio-group>
      <!-- 输入框 -->
      <el-form
        :model="loginForm"
        :rules="rules"
        status-icon
        ref="formName"
        class="demo-ruleForm"
        size="small"
      >
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            class="ipt"
            prefix-icon="iconfont icon-yonghu"
          ></el-input>
        </el-form-item>
        <!-- 密码框 -->

        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            class="ipt"
            @keyup.enter.native="submitBtn('formName')"
            prefix-icon="iconfont icon-mima"
            :type="isshow ? 'password' : 'text'"
            :suffix-icon="
              isshow ? 'iconfont icon-yanjing_bi' : 'iconfont icon-yanjing_kai'
            "
            @click.native="changeType"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            @click="submitBtn('formName')"
            class="ipt"
          >
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部备案号 -->
    <div class="footer">
      <a href="https://beian.miit.gov.cn">蜀ICP备2023022871号</a>
    </div>
  </div>
</template>

<script>
// 引入local
import local from "@/utils/local";
export default {
  // 引入username
  data() {
    return {
      show: false,
      resource: "",
      loginForm: {
        username: "",
        password: "",
      },
      // 表单验证
      rules: {
        // 账号验证
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          // { min: 3, max: 6, emessage: "请输入3-6位数", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          // { min: 3, max: 6, message: "请输入3-6位数", trigger: "blur" },
        ],
      },
      // 控制眼睛开或者闭
      isshow: true,
    };
  },

  methods: {
    addByEnterKey(e) {
      console.log(111);
      if (e.keyCode == 13) {
        this.submitBtn();
      }
    },
    //点击登录按钮
    submitBtn(formName) {
      //vali表示所有验证的结果
      this.$refs[formName].validate(async (vali) => {
        if (vali) {
          if (this.resource == "平台登录") {
            this.$api.login(this.loginForm).then((res) => {
              if (res.status == 200) {
                localStorage.setItem("loginMsg", JSON.stringify(res.result));
                localStorage.setItem("id", res.result.loginUser.id);
                localStorage.setItem("type", 1);
                localStorage.setItem(
                  "token_access",
                  res.result.loginUser.token
                );
                this.$router.push("/platform");
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$api.shopLogin(this.loginForm).then((res) => {
              if (res.status == 200) {
                localStorage.setItem("loginMsg", JSON.stringify(res.result));
                localStorage.setItem("id", res.result.shopLoginInfo.id);
                localStorage.setItem("type", 2);
                localStorage.setItem(
                  "token_access",
                  res.result.shopLoginInfo.token
                );
                this.$router.push("/writeoffs/shopwriteoff");
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },

    // 点击眼睛开或者闭
    changeType(e) {
      if (e.target.className.includes("icon-yanjing")) {
        this.isshow = !this.isshow;
      }
    },
  },
  created() {
    this.resource = "平台登录";
  },
};
</script>

<style lang="less" scoped>
.login {
  background: #2d3a4b;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /deep/ .el-radio__label {
    font-size: 20px;
  }
  .el-radio-group {
    margin-bottom: 20px;
  }

  .form {
    // width: 400px;
    // border: 1px red solid;

    text-align: center;
    h1 {
      font-size: 24px;
      width: 400px;
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
    }
    .ipt {
      width: 400px;
    }
    .btn {
      width: 300px;
      height: 300px;
      padding: 40px;
      margin: 10px;
      color: white;
      font-size: 25px;
      background: skyblue;
    }
  }
  .footer{
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
    a{
      color: #fff;
    }
  }
}
</style>



