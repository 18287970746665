<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <div class="right-bottom">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>新增商家</span>
          </div>
          <!-- 表单 -->
          <el-form
            ref="formItemName"
            :model="form"
            label-width="90px"
            :rules="rules"
            label-position="right"
          >
            <!-- 权重 -->
            <el-form-item label="权重:" prop="sw">
              <el-input v-model="form.sw" style="width: 230px"></el-input>
            </el-form-item>

            <!-- 年龄段 -->
            <el-form-item label="年龄段:" prop="labelage">
              <el-select v-model="form.labelage" placeholder="选择分类">
                <el-option
                  v-for="(item, index) in labelsage"
                  :key="index"
                  :label="item.attrName"
                  :value="item.attrName"
                ></el-option>
              </el-select>
            </el-form-item>

            <!-- 商家副标题 -->
            <el-form-item label="副标题:" prop="secondName">
              <el-input
                v-model="form.secondName"
                style="width: 230px"
              ></el-input>
            </el-form-item>

            <!-- 商家名称 -->
            <el-form-item label="商家名称:" prop="shopName">
              <el-input v-model="form.shopName" style="width: 230px"></el-input>
            </el-form-item>

            <!-- 浏览量 -->
            <el-form-item label="浏览量:" prop="shopView">
              <el-input v-model="form.shopView" style="width: 230px"></el-input>
            </el-form-item>

            <!-- 商家分类 -->
            <el-form-item label="商家分类:" prop="goodsType">
              <el-select v-model="form.goodsType" placeholder="选择分类">
                <el-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :label="item.catName"
                  :value="item.catId"
                ></el-option>
              </el-select>
            </el-form-item>

            <!-- 推广标签 -->
            <el-form-item label="推广" prop="attrType">
              <el-select v-model="form.attrType" placeholder="请选择推广类型">
                <el-option
                  v-for="(item, index) in labeList"
                  :key="index"
                  :label="item.attrName"
                  :value="item.attrName"
                ></el-option>
              </el-select>
            </el-form-item>

            <!-- 活动标签 -->
            <el-form-item label="活动标签:" prop="label">
              <el-checkbox-group v-model="form.label" @change="getLabel">
                <el-checkbox
                  v-for="(item, index) in labels"
                  :key="item.id"
                  :label="item"
                  :name="index.id"
                  >{{ item.attrName }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <!-- 活动属性 -->
            <el-form-item label="活动属性:" prop="labelSx">
              <el-checkbox-group v-model="form.labelSx" @change="getLabelSx">
                <el-checkbox
                  v-for="(item, index) in labelsSx"
                  :key="item.id"
                  :label="item"
                  :name="index.id"
                  >{{ item.attrName }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>

            <!-- 营业时间 -->
            <!-- <el-form-item label="营业开始时间:" label-width="100px">
              <div class="block">
                <el-time-picker
                  v-model="stime"
                  :picker-options="{
                    selectableRange: '0:00:00 - 23:59:59',
                  }"
                  placeholder="任意时间点"
                >
                </el-time-picker>
              </div>
            </el-form-item>
            <el-form-item label="营业结束时间:" label-width="100px">
              <div class="block">
                <el-time-picker
                  v-model="etime"
                  :picker-options="{
                    selectableRange: '0:00:00 - 23:59:59',
                  }"
                  placeholder="任意时间点"
                >
                </el-time-picker>
              </div>
            </el-form-item> -->

            <!-- 商家电话或者账号 -->
            <el-form-item
              label="联系方式(账号):"
              prop="phone"
              label-width="120px"
            >
              <el-input v-model="form.phone" style="width: 230px"></el-input>
            </el-form-item>
            <!-- 商家密码 -->
            <el-form-item label="商家密码:" prop="pwd">
              <el-input v-model="form.pwd" style="width: 230px"></el-input>
            </el-form-item>

            <!-- 营业时间 -->
            <el-form-item label="营业时间:" label-width="100px">
              <el-time-picker
                format="HH:mm"
                is-range
                v-model="dataValue"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
              >
              </el-time-picker>
            </el-form-item>

            <!-- 上传图片 -->
            <!-- on-preview	点击文件列表中已上传的文件时的钩子 -->
            <!--on-remove 文件列表移除文件时的钩子  -->
            <el-form-item label="首页展示:">
              <el-upload
                ref="uploads"
                action
                :before-upload="beforeUpload"
                :http-request="uploadimg1"
                list-type="picture-card"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemovesindex"
                :limit="1"
              >
                <i class=""> 建议尺寸:170*128</i>
              </el-upload>
            </el-form-item>

            <el-form-item label="门店拍照:">
              <el-upload
                ref="uploadss"
                action
                :before-upload="beforeUpload"
                :http-request="uploadimg2"
                list-type="picture-card"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class=""> 建议尺寸:375*180</i>
              </el-upload>
            </el-form-item>

            <el-form-item label="商家资质:">
              <el-upload
                ref="upload"
                action
                :http-request="uploadimg3"
                list-type="picture-card"
                :before-upload="beforeUpload"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemoves"
              >
                <i class=""> 建议尺寸:375*180</i>
              </el-upload>
            </el-form-item>

            <!-- 商家描述 -->
            <el-form-item label="商家简介:" porp="desc">
              <editor-bar v-model="form.desc" :isClear="isClear"></editor-bar>
            </el-form-item>
            <!-- 商家地址 -->
            <el-form-item label="商家地址:" prop="region">
              <el-select
                v-model="value1"
                placeholder="请选择"
                @change="Cprovince"
              >
                <el-option
                  v-for="item in province"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="value2"
                placeholder="请选择"
                @change="Cprovincecity"
              >
                <el-option
                  v-for="item in city"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <el-select
                v-model="value3"
                placeholder="请选择"
                @change="Cprovincereg"
              >
                <el-option
                  v-for="item in regions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div style="color: red; font-size: 14px">
                *
                商家地址和年卡地址相对应，如年卡区域为四川成都青羊区，商家地址也需为四川成都青羊区
              </div>
            </el-form-item>
            <!-- 地图 -->
            <div
              class="amap-page-container"
              style="width: 80%; height: 500px; margin: 30px 10%"
            >
              <el-amap-search-box
                class="search-box"
                :search-option="searchOption"
                :on-search-result="onSearchResult"
              ></el-amap-search-box>
              <el-amap
                ref="map"
                vid="amapDemo"
                :amap-manager="amapManager"
                :center="center"
                :zoom="zoom"
                :plugin="plugin"
                :events="events"
                class="amap-demo"
              >
              </el-amap>
              <p style="font-size: 14px; margin-top: 10px">
                详细地址：{{ address }} 经纬度：{{ lat }} {{ lng }}
              </p>
              <!-- <p>坐标经纬度：{{ center }}</p> -->
            </div>
            <el-button type="primary" @click="storage">新增提交</el-button>
          </el-form>
          <div></div>
        </el-card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import cityData from "@/assets/js/china_address.js";
import EditorBar from "@/components/wangEnduit.vue";

import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
export default {
  name: "newStore2",
  components: {
    HeaderNav,
    Card,
    EditorBar,
  },
  data() {
    const self = this;
    return {
      dataValue: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 999,
      // 总条数
      total: 999,
      options: [],
      province: [],
      city: [],
      regions: [],
      typeList: [],
      goodsType: "",
      value1: "",
      value2: "",
      value3: "",
      // 地图相关数据
      searchOption: {
        city: "", //搜索范围
        citylimit: false, //限制搜索范围
        //city: "", citylimit: false, // 不限制搜索范围搜索，比如想全国搜索
      },
      lng: "",
      lat: "",
      address: "",
      marker: "",
      region: "",
      amapManager,
      zoom: 12,
      center: [116.39753, 39.909111],
      events: {
        init: (o) => {},
        moveend: () => {},
        zoomchange: () => {},
        click: (e) => {
          //   console.log(e.lnglat);
          self.lng = e.lnglat.lng;
          self.lat = e.lnglat.lat;
          self.center = [self.lng, self.lat];
          //   console.log(e.lnglat, 1999);

          let o = amapManager.getMap();
          if (!self.marker) {
            self.marker = new AMap.Marker({
              position: e.lnglat,
            });
            self.marker.setMap(o);
          }
          self.marker.setPosition(e.lnglat);
          let geocoder = new AMap.Geocoder({});

          geocoder.getAddress(e.lnglat, function (status, result) {
            if (status === "complete" && result.regeocode) {
              self.address = result.regeocode.formattedAddress;

              //   console.log(self.address, "999地址");
            } else {
              log.error("根据经纬度查询地址失败");
            }
          });
        },
      },
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              let par =
                document.getElementsByClassName("search-box-wrapper")[0];
              par.firstChild.setAttribute(
                "placeholder",
                "请输入新增商家详细地址"
              );

              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                // console.log(JSON.stringify(result));
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.address = result.formattedAddress;
                  console.log(self.address, "地址");
                  self.center = [self.lng, self.lat];
                  //   console.log(self.center, 99666);
                  let o = amapManager.getMap();
                  if (!self.marker) {
                    self.marker = new AMap.Marker({
                      position: self.center,
                    });
                    self.marker.setMap(o);
                  }
                  self.marker.setPosition(self.center);
                }
              });
            },
          },
        },
      ],
      //---------------------------------------------
      width: "",
      title: "hhh",
      isClear: false,
      dialogImageUrl: "",
      dialogVisible: false,
      imgUrl: [],
      imgUrls: [],
      imgUrlsIndex: "",
      labels: [],
      labelValue: [],
      labelsage: [],
      labelsSx: [],
      labelSx: [],
      labelValueSx: [],
      labelValueage: [],
      timeData: false,
      forms: {},
      stime: "",
      etime: "",
      form: {
        id: "",
        name: "", //商户名
        region: "", //区域
        bulletin: "", //公告
        address: "", //地址
        phone: "", //电话
        pwd: "", //密码
        pics: [], //店铺图片
        longitude: "",
        latitude: "",
        label: [],
        labelSx: [],
        labelage: "",
        goodsType: "",
        attrType: "",
        shopView: "",
      },
      labeList: [],
      rules: {
        shopName: [
          { required: true, message: "请输入商家名称", trigger: "blur" },
        ],
        secondName: [
          { required: true, message: "请输入商家副标题", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入商家电话", trigger: "change" },
        ],
        sw: [
          {
            required: true,
            message: "请输入权重，四位数以下",
            trigger: "blur",
          },
        ],
        pwd: [{ required: true, message: "请输入商家密码", trigger: "blur" }],

        goodsType: [
          { required: true, message: "请选择商家分类", trigger: "change" },
        ],
        attrType: [
          { required: true, message: "请选择推广标签", trigger: "change" },
        ],
        label: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动标签",
            trigger: "change",
          },
        ],
        labelSx: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动属性",
            trigger: "change",
          },
        ],
        labelage: [
          { required: true, message: "请选择年龄段", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写商家描述", trigger: "blur" }],
      },
      provinceList: [], // 省列表
      cityList: [], // 市列表
      originList: [], // 区列表
      valueProvince: "", // 所选的省
      valueCity: "", // 所选的市
      valueOrigin: "", // 所选的区
      cityOptions: [], // 市下拉框数据
      originOptions: [], // 区下拉框数据
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },

  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },

    storage() {
      let img1 = [];
      let img2 = [];
      let img3 = "";
      let list = [];
      let listSx = [];
      if (this.imgUrlsIndex.length) {
        this.imgUrlsIndex.forEach((item) => {
          img3 = item.url;
        });
        console.log(img3, "imgUrlsIndex");
      }
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img1.push(item.url);
        });
        console.log(img1, "imgurl");
      }
      if (this.imgUrls.length) {
        this.imgUrls.forEach((item) => {
          img2.push(item.url);
        });
        console.log(img2, "imgurls");
      }
      if (this.labelValue.length) {
        this.labelValue.forEach((item) => {
          list.push(item.attrName);
        });
      }
      if (this.labelValueSx.length) {
        this.labelValueSx.forEach((item) => {
          listSx.push(item.id);
        });
      }

      if (this.address != "" && this.value1 != "" && this.value2 != "") {
        var moment = require("moment");
        moment().format();
        // var time1 = this.$moment(this.stime).format("h:mm:ss");
        // var time2 = this.$moment(this.etime).format("h:mm:ss");

        var time1 = this.$moment(this.dataValue[0]).format("h:mm:ss");
        var time2 = this.$moment(this.dataValue[1]).format("h:mm:ss");

        console.log(this.etime, "this.etime");
        var t1 = (this.$moment(time1, "h:mm:ss").valueOf() / 1000) * 1000;
        var t2 = (this.$moment(time2, "h:mm:ss").valueOf() / 1000) * 1000;
        console.log(t1, t2);

        this.$refs.formItemName.validate((valid) => {
          if (valid) {
            let regionId = "";
            if (this.value3 == "") {
              regionId = this.value2;
            } else {
              regionId = this.value3;
            }
            let Addshop = {
              shopName: this.form.shopName,
              secondName: this.form.secondName,
              address: this.address,
              startTime: t1,
              endTime: t2,
              headImg: img3,
              shopDesImg: img1,
              shopQuaImg: img2,
              lat: this.lat,
              lng: this.lng,
              region: this.region,
              regionId: regionId,
              username: this.form.phone,
              password: this.form.pwd,
              shopDescription: this.form.desc,
              sw: Number(this.form.sw),
              catId: this.form.goodsType,
              label: list.join("-"),
              attr: listSx.join("-"),
              age: this.form.labelage,
              extend: this.form.attrType,
              shopView: this.form.shopView || 0,
            };


            if (
              this.imgUrlsIndex != "" ||
              this.imgUrl.length != 0 ||
              this.imgUrls.length != 0
            ) {
              this.$api.storage(Addshop).then((res) => {
                if (res.status == 200) {
                  this.$message.success(res.result);
                  this.$refs.formItemName.resetFields();
                  // this.form = { brand_right: 0 };
                  // this.form.labelSx = [];
                  // this.form.label = [];
                  this.time = [];
                  this.stime = "";
                  this.etime = "";
                  this.value1 = "";
                  this.value2 = "";
                  this.value3 = "";
                  this.form.desc = "";
                  this.$refs.upload.clearFiles();
                  this.$refs.uploads.clearFiles();
                  this.$refs.uploadss.clearFiles();
                } else {
                  this.$message.error(res.message);
                }
              });
            } else {
              this.$message.error("请添加图片");
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$message.error("请选择商家地址和商家详细地址");
      }
    },
    getLabel(item) {
      this.labelValue = item;
      console.log(item, "item");
    },
    getLabelSx(item) {
      this.labelValueSx = item;
      console.log(item, "item");
    },
    // 选择省
    changeProvince(val) {
      this.provinceList.forEach((province, index) => {
        if (val.toString() === this.provinceList[index].value) {
          this.cityOptions = this.provinceList[index].children;
          this.valueCity = "";
          this.originOptions = this.provinceList[index].children[0].children;
          this.valueCity = "";
          this.valueOrigin = "";
        }
      });
    },
    // 选择市
    changeCity(val) {
      this.cityList.forEach((city, index) => {
        if (val.toString() === this.cityList[index].value) {
          this.originOptions = this.cityList[index].children;
          this.valueOrigin = "";
        }
      });
    },
    // 选择区
    changeOrigin(val) {
      this.valueOrigin = val;
    },
    // 初始化省市区数据
    _getJsonData() {
      this.provinceList = [];
      this.cityList = [];
      this.originList = [];
      cityData.forEach((item) => {
        if (item.value.match(/0000$/)) {
          this.provinceList.push({
            value: item.value,
            label: item.name,
            children: [],
          });
        } else if (item.value.match(/00$/)) {
          this.cityList.push({
            value: item.value,
            label: item.name,
            children: [],
          });
        } else {
          this.originList.push({
            value: item.value,
            label: item.name,
          });
        }
      });
      for (let index in this.provinceList) {
        for (let index1 in this.cityList) {
          if (
            this.provinceList[index].value.slice(0, 2) ===
            this.cityList[index1].value.slice(0, 2)
          ) {
            this.provinceList[index].children.push(this.cityList[index1]);
          }
        }
      }
      for (let item1 in this.cityList) {
        for (let item2 in this.originList) {
          if (
            this.originList[item2].value.slice(0, 4) ===
            this.cityList[item1].value.slice(0, 4)
          ) {
            this.cityList[item1].children.push(this.originList[item2]);
          }
        }
      }
    },
    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrlsIndex.push({ url: v.split("?")[0] });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex");
            } else {
              that.imgUrlsIndex.push({ url: v });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex1111");
            }
          });
      }
      multipartUpload();
    },
    // 门店拍照
    uploadimg2(file) {
      const that = this;

      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v, name: file.file.name });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    uploadimg3(file) {
      const that = this;

      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrls.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrls, "that.imgUrls");
            } else {
              that.imgUrls.push({ url: v, name: file.file.name });
              console.log(that.imgUrls, "that.imgUrls1111");
            }
          });
      }
      multipartUpload();
    },
    // 门店拍照删除
    handleRemove(file, fileList) {
      const filePath = file.name;
      this.imgUrl.forEach((val, index) => {
        if (val.name == filePath) {
          this.imgUrl.splice(index, 1);
        }
      });
    },
    // 资质删除
    handleRemoves(file, fileList) {
      const filePath = file.name;
      this.imgUrls.forEach((val, index) => {
        if (val.name == filePath) {
          this.imgUrls.splice(index, 1);
        }
      });
    },
    // 展示删除
    handleRemovesindex(file, fileList) {
      this.imgUrlsIndex.splice(0, 1);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onSearchResult(pois) {
      if (pois.length > 0) {
        let { lng, lat, name, location } = pois[0];
        let center = [lng, lat];
        this.lng = lng;
        this.lat = lat;
        this.center = [lng, lat];
        let o = amapManager.getMap();
        if (!this.marker) {
          this.marker = new AMap.Marker({
            position: center,
          });
          this.marker.setMap(o);
        }
        this.marker.setPosition(center);
        // 近来补充  根据经纬度查询地址
        let geocoder = new AMap.Geocoder({});
        let that = this;
        geocoder.getAddress(location, function (status, result) {
          console.log(status, result);
          if (status === "complete" && result.regeocode) {
            that.address = result.regeocode.formattedAddress;
          } else {
            console.log("根据经纬度查询地址失败");
          }
        });
      }
    },
    // 省
    Searchaddress() {
      this.$api.Searchaddress().then((res) => {
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.province = prsArrs;
        }
      });
    },
    // 城市
    Searchcity() {
      let pid = this.value1;
      this.$api.citys(pid).then((res) => {
        console.log('查看地址',res)
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.city = prsArrs;
        }
      });
    },
    // 城市
    searchreg() {
      let pid = this.value2;
      this.$api.citys(pid).then((res) => {
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.regions = prsArrs;
          console.log(this.regions, 22222);
        }
      });
    },
    Cprovince(e) {
      console.log('选中参数',e)
      console.log(this.value1, 222);
      this.value2='';
      this.value3='';
      this.Searchcity();
    },
    Cprovincecity() {
        this.value3='';
      this.searchreg();
      console.log(this.value2, 222);
    },
    Cprovincereg() {
      console.log(this.value3, 222);
    },
    typeSel() {
      let type = {
        page: this.currPage,
        pageSize: 999,
        catType: 1,
      };
      this.$api.storetype(type).then((res) => {
        if (res.status == 200) {
          this.typeList = res.result.records;
          console.log(res);
        }
      });
    },
    labellist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 0,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labels = res.result.records;
        }
      });
    },
    labellist2() {
      let label = {
        page: 1,
        pageSize: 999,
        attrType: 3,
      };
      this.$api.labelList(label).then((res) => {
        console.log('查看推广',res)
        if (res.status == 200) {
          this.labeList = res.result.records;
        }
      });
    },
    labelSxlist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 1,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labelsSx = res.result.records;
        }
      });
    },
    labelagelist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 2,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labelsage = res.result.records;
        }
      });
    },
  },

  created() {
    this._getJsonData();
    this.Searchaddress();
    this.typeSel();
    this.labellist();
    this.labellist2();
    this.labelSxlist();
    this.labelagelist();
    // if (this.provinceList.length == 0) {
    // 		this.cityList.push({
    // 			label: '请选择省份',
    // 			value: 1
    // 		})
    // 	}
  },
};
</script>

<style lang="less" scoped>
.content .el-range-editor.el-input__inner {
  width: 220px !important;
}
.content {
  .search-box {
    position: absolute;
    top: 25px;
    left: 20px;
  }
  .amap-page-container {
    position: relative;
  }
  /deep/.amap-page-container {
    width: 600px !important;
    height: 400px !important;
    margin: 0 !important;
  }

  .rigth-top {
    // width: 100%;
    height: 65px;
    background: #fff;
    overflow: hidden;
  }
  .right-bottom {
    overflow: auto;
    padding: 10px;
    margin: 10px;
    // background: #fff;
    height: 100%;
    /deep/ .el-textarea__inner {
      height: 90px;
      width: 600px;
    }
    /deep/.avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 140px;
      height: 140px;
      line-height: 140px;
      text-align: center;
    }
    /deep/.avatar {
      width: 140px;
      height: 140px;
      border-radius: 5px;
    }
    // 店铺图片
    /deep/ .el-upload--picture-card {
      width: 130px;
      height: 130px;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item-actions {
      width: 130px;
      height: 130px;
    }
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 130px;
    height: 130px;
  }

  .el-button {
    margin-left: 50%;
    transform: translateZ(-50%);
    margin-top: 50px;
  }
  /deep/ .el-select {
    width: 120px;
    margin-right: 10px;
  }
  .el-range-editor.el-input__inner {
    width: 530px;
  }
}
</style>
