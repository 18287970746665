<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">会员列表</span>
          <div slot="content">
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.name"
                  placeholder="请输入会员名"
                  style="width: 250px; margin-right: 30px"
                ></el-input>
                <el-select
                  v-model="form.isCard"
                  placeholder="会员类型"
                  style="margin-right: 30px"
                >
                  <el-option label="普通" value="1"></el-option>
                  <el-option label="年卡" value="2"></el-option>
                </el-select>
                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 商家列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 活动名称 -->
                <el-table-column label="会员名" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.nickName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 电话 -->
                <el-table-column label="电话" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.mobile
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 头像 -->
                <el-table-column label="头像" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img
                        class="bannerImg"
                        style="width: 50px; height: 50px"
                        :src="scope.row.header"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 会员类型 -->
                <el-table-column label="会员类型" width="150">
                  <template slot-scope="scope">
                    <span> {{ scope.row.isCard >= 1 ? "年卡" : "普通" }}</span>
                  </template>
                </el-table-column>
                <!-- 注册时间 -->
                <el-table-column label="注册时间" width="200">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      @click="handleSearch(scope.$index, scope.row)"
                      >查看流水</el-button
                    >
                    <el-button size="mini" type="danger" style="margin-left: 15px;" @click="deleteMemberById(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <el-pagination
              style="margin: 20px"
              background
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
            >
            </el-pagination>
            <!-- 流水弹窗 -->
            <el-dialog title="会员流水" :visible.sync="dialogFormVisible">
              <div class="table">
                <el-table :data="waterData" style="width: 100%">
                  <!-- 商品名称 -->
                  <el-table-column label="商品名称" width="200">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{
                        scope.row.actName
                      }}</span>
                    </template>
                  </el-table-column>
                  <!-- 购买日期 -->
                  <el-table-column label="购买日期" width="200">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{
                        scope.row.buyTime | dateFmt("YYYY-MM-DD")
                      }}</span>
                    </template>
                  </el-table-column>
                  <!-- 金额 -->
                  <el-table-column label="金额" width="200">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{
                        scope.row.price
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">返回</el-button>
              </div>
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChanges"
                layout="prev, pager, next"
                :total="totals"
                :page-size="pageSize"
              >
              </el-pagination>
            </el-dialog>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
import {Message} from "element-ui";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      currPages: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      totals: 1,
      size: 10,
      form: {
        name: "",
      },
      // 表格数据
      tableData: [],
      waterData: [],
      dialogFormVisible: false,
    };
  },
  methods: {
    async deleteMemberById(row){
      try{
        const res =await this.$api.deleteMemberById({id:row.id});
        Message.success('删除成功!');
        this.memberList();
      }catch (e) {
        console.log(e);
      }
    },
    handleSearch(index, row) {
      console.log(row);
      this.currPages = 1;
      this.dialogFormVisible = true;
      let member = {
        page: this.currPages,
        pageSize: this.pageSize,
        memberId: row.id,
      };
      this.$api.waterSearch(member).then((res) => {
        if (res.status == 200) {
          this.waterData = res.result.records;
          this.totals = res.result.total;
          sessionStorage.setItem("memberId", row.id);
        }
      });
    },
    search() {
      let memberList = {
        isCard: this.form.isCard,
        name: this.form.name,
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.memberList(memberList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    memberList() {
      let memberList = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.memberList(memberList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.search();
    },
    handleCurrentChanges(val) {
      // 给当前页数重新赋值
      this.currPages = val;
      // 重绘视图
      // this.handleSearch();
      let member = {
        page: this.currPages,
        pageSize: this.pageSize,
        memberId: sessionStorage.getItem("memberId"),
      };
      this.$api.waterSearch(member).then((res) => {
        if (res.status == 200) {
          this.waterData = res.result.records;
          this.totals = res.result.total;
        }
      });
    },
  },
  created() {
    this.memberList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
  .el-button + .el-button {
    margin: 0;
    margin-top: 5px;
  }
}
</style>
