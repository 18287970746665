<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">轮播列表</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增轮播</el-button
            >
            <!-- 轮播列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 图片名称 -->
                <el-table-column label="图片名称" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.imgName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 轮播图 -->
                <el-table-column label="轮播图" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img class="bannerImg" :src="scope.row.url" alt="" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="轮播类型" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px" v-if="scope.row.type==1 && scope.row.skipType==1">首页轮播</span>
                    <span style="margin-left: 10px" v-else-if="scope.row.type==2 && scope.row.skipType==0">商家轮播</span>
                    <span style="margin-left: 10px" v-else-if="scope.row.type==1 && scope.row.skipType==2">年卡轮播</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="200">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>

              <!-- 新增的弹窗 -->
              <el-dialog title="新增广告图" :visible.sync="dialogFormVisible">
                <el-form :model="addForm">
                  <el-upload
                    ref="uploads"
                    action
                    :http-request="uploadimg1"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                  >
                    <i>建议尺寸:350*140</i>
                  </el-upload>

                  <!-- <el-upload
                    ref="uploads"
                    action="https://admin.bbtedu03.com:444/product/file_upload/uploadImg"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    slot-tip="冲冲冲"
                    :on-success="shopheadersuccess"
                    :limit="1"
                  >
                    <i class="">建议尺寸:350*140</i>
                  </el-upload> -->
                  <!-- <div style="margin: 20px 0; color: red"></div> -->

                  <el-form-item label="图片名称" style="margin-top: 20px">
                    <el-input
                      style="width: 210px"
                      v-model="addForm.imgName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="首页轮播" v-if="actshow">
                    <el-select
                      clearable
                      @clear="clear"
                      @change="changAct"
                      v-model="addForm.skipUrl"
                      placeholder="请选择跳转的活动"
                    >
                      <el-option
                        :label="item.actName"
                        :value="item.id"
                        v-for="(item, index) in actlist"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="商家轮播" v-if="shopshow">
                    <el-select
                      clearable
                      @clear="clear"
                      @change="changShop"
                      v-model="addForm.ShopUrl"
                      placeholder="请选择跳转的商家"
                    >
                      <el-option
                        :label="item.shopName"
                        :value="item.id"
                        v-for="(item, index) in shops"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="年卡轮播" v-if="showAnnualCard">
<!--                    <el-select
                      clearable
                      @clear="clear"
                      v-model="addForm.skipUrl2"
                      @change="changAnnualCard"
                      placeholder="请选择跳转的年卡"
                    >
                      <el-option
                        :label="item.name"
                        :value="item.id"
                        v-for="(item, index) in annualCard"
                        :key="index"
                      ></el-option>
                    </el-select>-->
                    <el-select
                        clearable
                        @clear="clear"
                        v-model="addForm.skipUrl2"
                        @change="changAnnualCard"
                        placeholder="请选择跳转的年卡"
                    >
                      <el-option
                          label="年卡列表"
                          value="0"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <div><span style="color: red">提示： 只能单选</span></div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>

              <!-- 编辑的弹窗 -->
              <el-dialog title="编辑广告图" :visible.sync="dialogFormVisibles">
                <el-form :model="editForm">
                  <el-upload
                    action
                    :http-request="uploadimg1"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :file-list="imgUrl"
                  >
                    <i>建议尺寸:350*140</i>
                  </el-upload>
                  <!-- <div style="margin: 20px 0; color: red">
                  </div> -->

                  <el-form-item label="图片名称:" style="margin-top: 20px">
                    <el-input style="width: 210px" v-model="editForm.imgName">{{
                      editForm.imgName
                    }}</el-input></el-form-item
                  >
                  <el-form-item label="首页轮播" v-if="actshow">
                    <el-select
                      @change="changAct"
                      clearable
                      @clear="clear"
                      v-model="editForm.skipUrl1"
                      placeholder="请选择跳转的活动"
                    >
                      <el-option
                        :label="item.actName"
                        :value="item.id"
                        v-for="(item, index) in actlist"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="商家轮播" v-if="shopshow">
                    <el-select
                      @change="changShop"
                      clearable
                      @clear="clear"
                      v-model="editForm.skipUrl2"
                      placeholder="请选择跳转的商家"
                    >
                      <el-option
                        :label="item.shopName"
                        :value="item.id"
                        v-for="(item, index) in shops"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="年卡轮播" v-if="showAnnualCard">
<!--                    <el-select
                      clearable
                      @clear="clear"
                      v-model="editForm.skipUrl3"
                      @change="changAnnualCard"
                      placeholder="请选择跳转的年卡"
                    >
                      <el-option
                        :label="item.name"
                        :value="item.id"
                        v-for="(item, index) in annualCard"
                        :key="index"
                      ></el-option>
                    </el-select>-->
                    <el-select
                        clearable
                        @clear="clear"
                        v-model="editForm.skipUrl3"
                        @change="changAnnualCard"
                        placeholder="请选择跳转的年卡"
                    >
                      <el-option
                          label="年卡列表"
                          value="0"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <div><span style="color: red">提示： 只能单选</span></div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  name: "banner",
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      imgUrl: [], //商品图片
      img: "",
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      shops: [],
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogVisible: false,
      dialogImageUrl: "",
      editForm: {},
      addForm: {
        imgName: "",
      },
      form: {
        name: "",
      },
      // 表格数据
      tableData: [],
      actlist: [],
      shopshow: true,
      actshow: true,
      showAnnualCard: true,
      type: "",
      annualCard: [],
      skipType: "",
      skipUrl: "",
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    uploadimg1(file) {
      const that = this;
      that.imgUrl = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({ url: v.split("?")[0] });
              that.imglists.push(v.split("?")[0]);
            } else {
              that.imgUrl.push({ url: v });
              that.imglists.push(v);
            }
          });
      }
      multipartUpload();
    },
    clear() {
      this.shopshow = true;
      this.actshow = true;
      this.showAnnualCard = true;
    },
    changAct() {
      this.addForm.ShopUrl = "";
      this.editForm.ShopUrl = "";
      this.shopshow = false;
      this.showAnnualCard = false;
      this.type = 1;
      this.skipType = 1;
    },
    changShop() {
      this.addForm.skipUrl = "";
      this.editForm.skipUrl = "";
      this.actshow = false;
      this.showAnnualCard = false;
      this.type = 2;
      this.skipType = "";
    },
    changAnnualCard() {
      this.actshow = false;
      this.shopshow = false;
      this.addForm.skipUrl = "";
      this.editForm.skipUrl = "";
      this.type = 1;
      this.skipType = 2;
    },
    shopheadersuccess(file) {
      this.imgUrl = file.result;
      this.img = file.result;
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove() {
      this.imgUrl = fileList;
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;

      // 重绘视图
      this.bannerList();
    },
    // 表格编辑删除
    handleEdit(index, row) {
      this.imgUrl = [];

      let obj = { ...row };
      if (row.type == 1 && row.skipType == 1) {
        // 首页
        this.actshow = true;
        this.shopshow = false;
        this.showAnnualCard = false;
        obj.skipUrl1 = row.skipUrl;
      } else if (row.type == 1 && row.skipType == 2) {
        // 年卡
        this.actshow = false;
        this.shopshow = false;
        this.showAnnualCard = true;
        obj.skipUrl3 = row.skipUrl;
      } else if (row.type == 2 && row.skipType == 0) {
        // 商家
        this.actshow = false;
        this.shopshow = true;
        this.showAnnualCard = false;
        obj.skipUrl2 = row.skipUrl;
      }
      this.editForm = Object.assign({}, obj);

      this.dialogFormVisibles = true;
      this.imgUrl.push({ url: row.url });
    },
    handleDelete(index, row) {
      console.log(index, row);

      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该广告, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.id;
        that.$api.bannerDel(id).then((res) => {
          if (res.status == 200) {
            that.bannerList();
          }
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.bannerList();
      });
    },
    // 新增广告
    add() {
      this.actshow = true;
      this.shopshow = true;
      this.showAnnualCard = true;
      this.dialogFormVisible = true;
    },
    ok() {
      if (this.imgUrl === []) {
        this.$message.error("请上传轮播图，不能为空!");
        return;
      }
      if (this.addForm.imgName == "") {
        this.$message.error("图片名称不能为空!");
        return;
      }
      if (this.type === "") {
        this.$message.error("请选择跳转的活动或者商家!");
        return;
      }
      let bannerAdd = {
        imgName: this.addForm.imgName,
        // skipUrl: this.type == 1 ? this.addForm.skipUrl : this.addForm.ShopUrl,
        skipUrl: "",
        url: this.imgUrl.length >= 1 ? this.imgUrl[0].url : "",
        type: this.type,
        skipType: this.skipType,
      };
      if (this.type == 1 && this.skipType == 1) {
        bannerAdd.skipUrl = this.addForm.skipUrl;
      } else if (this.type == 1 && this.skipType == 2) {
        bannerAdd.skipUrl = this.addForm.skipUrl2;
      } else if (this.type == 2 && this.skipType == "") {
        bannerAdd.skipUrl = this.addForm.ShopUrl;
      }

      this.$api.bannerAdd(bannerAdd).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.addForm = { brand_right: 0 };
          this.$refs.uploads.clearFiles();
          this.bannerList();
          this.dialogFormVisible = false;
        }
      });
    },
    oks() {
      let bannerEdit = {
        createTime: this.editForm.createTime,
        id: this.editForm.id,
        imgName: this.editForm.imgName,
        // skipUrl:
        //   this.type == 1 ? this.editForm.skipUrls : this.editForm.ShopUrl,
        skipUrl: "",
        url: this.imgUrl.length >= 1 ? this.imgUrl[0].url : "",
        type: this.type,
        skipType: this.skipType,
      };
      if (this.type == 1 && this.skipType == 1) {
        bannerEdit.skipUrl = this.editForm.skipUrl1;
      } else if (this.type == 1 && this.skipType == 2) {
        bannerEdit.skipUrl = this.editForm.skipUrl3;
      } else if (this.type == 2 && this.skipType == "") {
        bannerEdit.skipUrl = this.editForm.skipUrl2;
      }
      this.$api.bannerEdit(bannerEdit).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibles = false;
          this.$message.success(res.result);
          this.bannerList();
        }
      });
    },
    bannerList() {
      let banner = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.banner(banner).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    act() {
      let act = {
        page: this.currPage,
        pageSize: 9999,
        pubStatus: 1,
      };
      this.$api.activityList(act).then((res) => {
        if (res.status == 200) {
          this.actlist = res.result.records;
        }
      });
    },
    storeList() {
      let storeList = {
        page: this.currPage,
        pageSize: 9999,
        status: 1,
      };
      this.$api.storeList(storeList).then((res) => {
        if (res.status == 200) {
          this.shops = res.result.records;
        }
      });
    },
    getCardList() {
      this.$api.getCardList({ city: "" }).then((res) => {
        if (res.status == 200) {
          this.annualCard = res.result;
        }
      });
    },
  },
  created() {
    this.bannerList();
    this.act();
    this.storeList();
    // this.getCardList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>
