<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">代金券列表</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增代金券</el-button
            >
            <!-- 代金券列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 代金券图 -->
                <el-table-column label="代金券图" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img
                        class="bannerImg"
                        :src="scope.row.couponUrl"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column>
<!--                <el-table-column label="所属商户" prop="shopName">-->
<!--                </el-table-column>-->
                <!-- 图片名称 -->
                <el-table-column label="代金券名称" prop="couponName">
                </el-table-column>

                <el-table-column label="销售价格">
                  <template slot-scope="scope">
                    <span>{{ scope.row.saleMoney }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="代金券价格">
                  <template slot-scope="scope">
                    <span>{{ scope.row.couponMoney }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="销量">
                  <template slot-scope="scope">
                    <span>{{ scope.row.saleCount }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="状态">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.couponStatus == 1 ? "启用" : "禁用"
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" width="300">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="on(scope.$index, scope.row)"
                      >{{
                        scope.row.couponStatus == 1 ? "禁用" : "启用"
                      }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <!-- 新增的弹窗 -->
              <el-dialog
                title="新增代金券"
                width="30%"
                :visible.sync="dialogFormVisible"
              >
                <el-form :model="addForm" label-width="90px">
                  <el-form-item label="代金券图片:">
                    <el-upload
                      ref="upload"
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :limit="1"
                    >
                      <i class="">建议尺寸380*208</i>
                    </el-upload>
                  </el-form-item>
                  <!-- <div style="font-size: 14px; color: red; margin: 15px 0">
                  </div> -->
                  <el-form-item label="代金券名称:">
                    <el-input
                      style="width: 210px"
                      v-model="addForm.name"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="代金券金额:">
                    <el-input
                      style="width: 210px"
                      v-model="addForm.money"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="绑定商家:">
                    <el-select
                      filterable
                      v-model="addForm.shop"
                      multiple
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in shops"
                        :key="item.id"
                        :label="item.shopName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="绑定商家:">
                    <el-select
                      style="width: 210px"
                      filterable
                      v-model="addForm.shop"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in shops"
                        :key="item.id"
                        :label="item.shopName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="有效期:">
                    <el-date-picker
                      v-model="time"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions1"
                      style="width: 210px"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <!-- 营业时间 -->
                  <el-form-item label="展示时间:">
                    <el-input
                      style="width: 210px"
                      v-model="addForm.texttime"
                      placeholder="例如'周一至周日 17:00-20:00'"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="购买上限:">
                    <el-input
                      style="width: 210px"
                      v-model="addForm.maxBuy"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="销量:">
                    <el-input
                      style="width: 210px"
                      v-model="addForm.saleCount"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="销售价格:">
                    <el-input
                      style="width: 210px"
                      v-model="addForm.saleMoney"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="使用规则:">
                    <editor-bar
                      v-model="addForm.desc"
                      :isClear="isClear"
                    ></editor-bar>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 编辑的弹窗 -->
              <el-dialog
                title="编辑代金券"
                width="30%"
                :visible.sync="dialogFormVisibles"
              >
                <el-form :model="editForm" label-width="90px">
                  <el-form-item label="代金券图片:">
                    <el-upload
                      ref="uploads"
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :limit="1"
                      :file-list="imgUrl"
                    >
                      <i class="">建议尺寸380*208</i>
                    </el-upload>
                  </el-form-item>
                  <!-- <div style="font-size: 14px; color: red; margin: 15px 0">
                    建议尺寸380*208
                  </div> -->

                  <el-form-item label="代金券名称:">
                    <el-input
                      style="width: 210px"
                      v-model="editForm.couponName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="代金券金额:">
                    <el-input
                      style="width: 210px"
                      v-model="editForm.couponMoney"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="绑定商家:">
                    <el-select
                      v-model="editForm.ids"
                      multiple
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in shops"
                        :key="item.id"
                        :label="item.shopName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="绑定商家:">
                    <el-select
                      style="width: 210px"
                      v-model="editForm.ids"
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in shops"
                        :key="item.id"
                        :label="item.shopName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="有效期:">
                    <el-date-picker
                      v-model="time"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions1"
                      style="width: 210px"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <!-- 营业时间 -->
                  <el-form-item label="展示时间:">
                    <el-input
                      style="width: 210px"
                      v-model="editForm.useTime"
                      placeholder="例如'周一至周日 17:00-20:00'"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="购买上限:">
                    <el-input
                      style="width: 210px"
                      v-model="editForm.limitNum"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="销量:">
                    <el-input
                      style="width: 210px"
                      v-model="editForm.saleCount"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="销售价格:">
                    <el-input
                      style="width: 210px"
                      v-model="editForm.saleMoney"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="使用规则:">
                    <editor-bar
                      v-model="editForm.useRule"
                      :isClear="isClear"
                    ></editor-bar>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
import EditorBar from "@/components/wangEnduit.vue";
var moment = require("moment");
moment().format();
export default {
  name: "voucherList",
  components: {
    Card,
    HeaderNav,
    EditorBar,
  },
  data() {
    return {
      imgUrl: [], //商品图片
      img: "",
      isClear: false,
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogVisible: false,
      dialogImageUrl: "",
      editForm: {},
      addForm: {},
      form: {
        name: "",
      },
      vshop: [],
      // 表格数据
      tableData: [],
      name: "",
      time: [],
      stime: "",
      etime: "",
      shops: [],
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },
    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({ url: v.split("?")[0] });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove() {
      this.imgUrl.splice(0, 1);
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;

      // 重绘视图
      this.voucherchList();
    },
    time_to_sec(time) {
      if (time !== null) {
        let s = "";
        s = Date.parse("1970-01-01 " + time) / 1000;
        return s;
      }
    },
    // 表格编辑删除
    handleEdit(index, row) {
      // this.editForm = row;
      // this.editForm.ids = "";
      this.editForm = {
        ...row,
        ids: "",
      };
      console.log(this.editForm, "editForm");
      this.$api.voucherShop({ id: row.id }).then((res) => {
        if (res.status == 200) {
          res.result.infos.forEach((item) => {
            // this.editForm.ids.push(item.id);
            this.editForm.ids = item.id;
          });
        }
      });
      // this.editForm.ids = this.vshop;
      console.log('查看参数',row)
      this.stime =row.createTime;
      this.etime =row.endTime;
      this.time = [row.startTime, row.endTime];
      this.imgUrl = [];
      this.imgUrl.push({ url: row.couponUrl });
      this.img = row.couponUrl;
      this.dialogFormVisibles = true;
    },
    handleDelete(index, row) {
      console.log(index, row);

      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该代金券, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.id;
        that.$api.voucherDel({ id: id }).then((res) => {
          if (res.status == 200) {
            that.voucherchList();
            this.$$message.success(res.result);
          }
        });
      });
    },
    // 新增
    add() {
      this.time = [];
      (this.stime = ""), (this.etime = ""), (this.dialogFormVisible = true);
    },
    ok() {
      let times = [];
      let img = "";
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img = item.url;
        });
        console.log(img, "imgUrlsIndex");
      }
      times.push(moment(new Date(this.stime).getTime()).format("HH:mm:ss"));
      times.push(moment(new Date(this.etime).getTime()).format("HH:mm:ss"));
      console.log(this.addForm.shop, "addForm.shop");
      let Add = {
        couponName: this.addForm.name,
        couponMoney: this.addForm.money,
        startTime: new Date(this.time[0]).getTime(),
        endTime: new Date(this.time[1]).getTime() + 86399999,
        saleCount: this.addForm.saleCount,
        saleMoney: this.addForm.saleMoney,
        useRule: this.addForm.desc,
        useTime: this.addForm.texttime,
        // ids: this.addForm.shop,
        ids: [this.addForm.shop],
        couponUrl: img,
        type: 1,
        limitNum: this.addForm.maxBuy,
      };
      this.$api.voucheradd(Add).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.addForm = { brand_right: 0 };
          this.$refs.upload.clearFiles();
          this.voucherchList();
          this.dialogFormVisible = false;
        }
      });
    },
    oks() {
      let img = "";
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img = item.url;
        });
        console.log(img, "imgUrlsIndex");
      }
      let times = [];
      times.push(moment(new Date(this.stime).getTime()).format("HH:mm:ss"));
      times.push(moment(new Date(this.etime).getTime()).format("HH:mm:ss"));

      let Edit = {
        couponName: this.editForm.couponName,
        couponMoney: this.editForm.couponMoney,
        startTime: new Date(this.time[0]).getTime(),
        endTime: new Date(this.time[1]).getTime(),
        saleCount: this.editForm.saleCount,
        saleMoney: this.editForm.saleMoney,
        useRule: this.editForm.useRule,
        useTime: this.editForm.useTime,
        limitNum: this.editForm.limitNum,
        couponUrl: img,
        id: this.editForm.id,
        // ids: this.editForm.ids,
        ids: [this.editForm.ids],
        type: 1,
      };

      this.$api.voucheredit(Edit).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibles = false;
          this.$message.success(res.result);
          this.voucherchList();
        }
      });
    },
    on(index, row) {
      if (row.couponStatus == 1) {
        let id = row.id;
        this.$api.voucherstop({ id: id }).then((res) => {
          if (res.status == 200) {
            this.voucherchList();
          }
        });
      } else {
        let id = row.id;
        this.$api.voucherstart({ id: id }).then((res) => {
          if (res.status == 200) {
            this.voucherchList();
          }
        });
      }
    },
    voucherchList() {
      let list = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.voucherList(list).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    storeList() {
      let storeList = {
        page: this.currPage,
        pageSize: 9999,
        status: 1,
      };
      this.$api.storeList(storeList).then((res) => {
        if (res.status == 200) {
          this.shops = res.result.records;
        }
      });
    },
  },
  created() {
    this.voucherchList();
    this.storeList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>
