<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">核销列表</span>
          <div slot="content">
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.shopName"
                  placeholder="请输入店铺名称"
                  style="width: 150px; margin-right: 30px"
                ></el-input>
                <el-input
                  v-model="form.actName"
                  placeholder="请输入活动名称"
                  style="width: 150px; margin-right: 30px"
                ></el-input>
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="margin-right: 30px"
                >
                </el-date-picker>
                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form>
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 图片名称 -->
                <el-table-column label="活动名称" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.actName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 活动类型 -->
                <el-table-column label="活动类型" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.actType == 1
                        ? "普通活动"
                        : scope.row.actType == 2
                        ? "亲子活动"
                        : scope.row.actType == 3
                        ? "年卡"
                        : ""
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="200">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      form: {
        name: "",
      },
      // 表格数据
      tableData: [],
      time: [],
       t1: null,
      t2: null,
    };
  },

  methods: {
    search() {
      var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
      var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
      if (this.time && this.time.length == 2) {
        this.t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
        this.t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
        // console.log(this.t1,111);
      }
      let List = {
        actName: this.form.actName,
        shopName: this.form.shopName,
        page: this.currPage,
        pageSize: this.pageSize,
        startTime: this.t1,
        endTime: this.t2,
      };
      this.$api.shopWriteoff(List).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.WriteoffList();
    },
    WriteoffList() {
      let shopWriteoff = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.shopWriteoff(shopWriteoff).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    // // 年卡活动核销
    //  cardWrite() {
    //   let cardWrite = {
    //     page: this.currPage,
    //     pageSize: this.pageSize,
    //   };
    //   this.$api.cardWrite(cardWrite).then((res) => {
    //     if (res.status == 200) {
    //     }
    //   });
    // },
  },
  created() {
    this.WriteoffList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 300px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
}
</style>