<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">意见反馈</span>
          <div slot="content">
            <!-- 订单列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 意见编号
                <el-table-column label="意见编号">
                  <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                  </template>
                </el-table-column> -->
                <!-- 提出用户 -->
                <el-table-column label="提出用户">
                  <template slot-scope="scope">
                    <span>{{ scope.row.username }}</span>
                  </template>
                </el-table-column>
                <!-- 用户电话 -->
                <el-table-column label="用户电话">
                  <template slot-scope="scope">
                    <span>{{ scope.row.mobile }}</span>
                  </template>
                </el-table-column>
                <!-- 意见内容 -->
                <el-table-column label="意见内容">
                  <template slot-scope="scope">
                    <span>{{ scope.row.advice }}</span>
                  </template>
                </el-table-column>
                <!-- 时间 -->
                <el-table-column label="时间">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>

                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                    <el-button
                      @click="lookOver(scope.row)"
                      v-if="scope.row.isRead === 0"
                      size="mini"
                      >查看</el-button
                    >
                    <el-button size="mini" type="info" v-else>已读</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      // 表格数据
      tableData: [],
    };
  },
  created() {
    this.List();
  },
  methods: {
    handleDelete(index, row) {
      console.log(index, row);
      let id = row.id;
      this.$api.Delfeedback(id).then((res) => {
        if (res.status == 200) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.List();
        }
      });
    },
    on(index, row) {},
    // 分页函数
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.List();
    },
    List() {
      let List = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.feedback(List).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
          console.log(this.tableData);
        }
      });
    },
    getNewsNum1() {
      this.$api.getNewsNum1().then((res) => {
        this.$store.commit("setNum1", res.result);
      });
    },
    lookOver(row) {
      this.$api
        .readOpinion({
          id: row.id,
        })
        .then((res) => {
          console.log("res23123", res);
          if (res.status == 200) {
            this.$message({
              type: "success",
              message: "查看成功!",
            });
            this.List();
            this.getNewsNum1();
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--primary {
  margin-bottom: 10px;
}

.table {
  margin-top: 20px;
  .bannerImg {
    width: 300px;
    height: 150px;
  }

  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
.head {
  display: flex;
  align-items: center;
}
.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
.headimg {
  display: flex;
  align-items: center;
}
</style>