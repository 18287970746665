<template>
  <div class="left">
    <el-row>
      <el-menu
        unique-opened
        :default-active="this.$route.path"
        router
        class="el-menu-vertical-demo"
        background-color="#304156"
        text-color="#fff"
        active-text-color="#409eff"
      >
        <div class="header">
          <p>后台管理系统</p>
        </div>
        <!-- 平台管理 -->
        <el-submenu index="/platform" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-s-platform"></i>
            <span>平台管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/platform/banner">banner管理</el-menu-item>
            <el-menu-item index="/platform/use">年卡使用须知</el-menu-item>
            <el-menu-item index="/platform/purchase">年卡购买须知</el-menu-item>
            <el-menu-item index="/platform/about">关于我们</el-menu-item>
            <el-menu-item index="/platform/service">联系客服</el-menu-item>
            <el-menu-item index="/platform/notice">平台公告</el-menu-item>
            <el-menu-item index="/platform/agreement">服务协议</el-menu-item>
            <el-menu-item index="/platform/problem">常见问题</el-menu-item>
            <el-menu-item index="/platform/indeximg">首页图片</el-menu-item>
            <el-menu-item index="/platform/tourtip">旅游提示</el-menu-item>
            <el-menu-item index="/platform/cancel">退订政策</el-menu-item>
            <el-menu-item index="/platform/contract">合同范本</el-menu-item>
            <el-menu-item index="/platform/reserve">预定须知</el-menu-item>
            <el-menu-item index="/platform/safe">安全提示</el-menu-item>
            <el-menu-item index="/platform/qzact">亲子活动说明</el-menu-item>
            <el-menu-item index="/platform/ptact">普通活动说明</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- 商家管理 -->
        <el-submenu index="/store" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-news"></i>
            <span>商家管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/store/newstore">新增商家</el-menu-item>
            <!-- <el-menu-item index="/store/Newactivity">新增活动</el-menu-item> -->
            <el-menu-item index="/store/storelist">商家列表</el-menu-item>
            <el-menu-item index="/store/storetype">商家分类</el-menu-item>
            <!-- <el-menu-item index="/store/qftions">商家资质</el-menu-item> -->
            <el-menu-item index="/store/storeactivity">商家活动</el-menu-item>
            <el-menu-item index="/store/shopgoods">商家商品</el-menu-item>
            <el-menu-item index="/store/labellist">标签列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- 年卡会员 -->
        <el-submenu index="/annual" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-bank-card"></i>
            <span>年卡会员</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/annual/annualrun">年卡管理</el-menu-item>
            <!-- <el-menu-item index="/annual/annuaremarks">年卡备注</el-menu-item> -->
            <el-menu-item index="/annual/annuarecord">记录管理</el-menu-item>
            <el-menu-item index="/annual/annuallist">会员列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- 亲子活动 -->
        <el-submenu index="/parenting" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-thumb"></i>
            <span>亲子活动</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/parenting/parentinglist"
              >亲子活动列表</el-menu-item
            >
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/parenting/Parentingtype"
              >亲子活动分类</el-menu-item
            >
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/parenting/topact">首页活动</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/parenting/grouplist">拼团列表</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/parenting/actseckill">限时秒杀</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- 会员管理 -->
        <el-submenu index="/member" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-s-custom"></i>
            <span>会员管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/member/memberlist">会员列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- 代金券 -->
        <el-submenu index="/voucher" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-s-custom"></i>
            <span>代金券管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/voucher/Voucherlist">代金券管理</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- 出行人管理 -->
        <el-submenu index="/traveler" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-s-custom"></i>
            <span>出行人管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/traveler/Travelerlist"
              >出行人管理</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <!-- 热门搜索 -->
        <el-submenu index="/hotsearch" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-s-custom"></i>
            <span>热门搜索</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/hotsearch/Hotsearchlist"
              >热门搜索</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <!-- 订单管理 -->
        <el-submenu index="/order" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-s-claim"></i>
            <span>订单管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/order/tobepaid">订单列表</el-menu-item>
            <!-- <el-menu-item index="/order/grouplist">团购列表</el-menu-item> -->
            <el-menu-item index="/order/refund">
              <div class="refund-request-box">
                <span>退款申请</span>
                <div class="news" v-if="$store.state.num2 > 0">
                  {{ $store.state.num2 }}
                </div>
              </div>
            </el-menu-item>
            <el-menu-item index="/order/returnlist">退款列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- 核销 -->
        <el-submenu index="/writeoffs">
          <template slot="title">
            <i class="el-icon-s-claim"></i>
            <span>核销管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/writeoffs/writeoff" v-if="type == 1"
              >核销查询</el-menu-item
            >
            <el-menu-item index="/writeoffs/shopwriteoff" v-if="type == 2"
              >商家核销查询</el-menu-item
            >
            <el-menu-item index="/writeoffs/actwriteoff" v-if="type == 2"
              >活动核销</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <!-- 意见反馈 -->
        <el-submenu index="/feedback" v-if="type == 1">
          <template slot="title">
            <i class="el-icon-s-claim"></i>
            <span>意见查看</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/feedback/feedback">
              <div class="feedback-box">
                <span>意见反馈</span>
                <div v-if="$store.state.num1 > 0" class="news">
                  {{ $store.state.num1 }}
                </div>
              </div>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: [],
      type: 1,
    };
  },
  async created() {
    if (localStorage.getItem("type") == 1) {
      this.type = 1;
    } else if (localStorage.getItem("type") == 2) {
      this.type = 2;
    }
    this.getNewsNum1();
    this.getNewsNum2();
    // // 获取用户角色
    // let { role } = await getRole_api();
    // // 定义一个函数用于判断哪些路由有身份角色
    // function hasRoleRouuter(roles, route) {
    //   if (route.meta && route.meta.role) {
    //     // 返回包含角色的路由
    //     return route.meta.roles.includes(roles);
    //   } else {
    //     return true;
    //   }
    // }
    // // console.log(this.$route);
    // // console.log(router);
    // // console.log(asyncRoutes);
    // //  定义一个函数用于过滤有权限的路由函数
    // function filterRouter(role, asyncRoutes) {
    //   let aRouter = asyncRoutes.filter((item) => {
    //     if (hasRoleRouuter(role, item)) {
    //       // 判断子路由的meta是不是存在
    //       if (item.children) {
    //         item.children = filterRouter(role, item.children);
    //       }
    //       // 超级管理员或者普通管理员可以访问的路由
    //       return true;
    //     } else {
    //       // 不需要管理员访问的路由
    //       return false;
    //     }
    //   });
    //   return aRouter;
    // }
    // let fRouter = filterRouter(role, asyncRoutes);
    // console.log(fRouter);
    // //动态给vueRouter添加路由
    // router.addRoutes(fRouter);
    // // 再一次过滤异步路由，拿到所有isshow为true的路由数据
    // let menus = fRouter.filter((item) => item.isshow);
    // // console.log(menus);
    // this.menu = menus;
  },
  methods: {
    getNewsNum1() {
      this.$api.getNewsNum1().then((res) => {
        this.$store.commit("setNum1", res.result);
      });
    },
    getNewsNum2() {
      this.$api.getNewsNum2().then((res) => {
        this.$store.commit("setNum2", res.result);
      });
    },
  },
};
</script>


<style lang="less" scoped>
/deep/ .el-menu-vertical-demo {
  overflow: scroll;
}
.feedback-box,
.refund-request-box {
  position: relative;
  display: flex;
  align-items: center;
  .news {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 60px;
    top: 8px;
    right: 0;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: red;

    font-size: 10px;
    color: #fff;
  }
}
.left {
  width: 200px;
  height: 100%;
  overflow: hidden;
  font-size: 20px !important;
  // background: #304156;
  .header {
    width: 200;
    margin: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    height: 80px;
    box-sizing: border-box;
    // margin:10px auto;
    p {
      color: #fff;
      font-size: 18px;
      margin-left: 20px;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
  }
  /deep/.el-row {
    height: 100%;
    .el-menu {
      height: 100%;
    }
  }
}
</style>
