<template>
  <div class="header">
    <!-- 导航 -->
    <div class="nav">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="line-height: 4; margin-left: 8px"
      >
        <el-breadcrumb-item
          v-for="(item, i) in breadInfo"
          :key="i"
          :to="{ path: item.path }"
          >{{ item.title }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <!-- 头像 -->
    <div class="photo">
      <el-dropdown @command="select">
        <span class="el-dropdown-link">
          <img src="../../img/1614@2x.png" />
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="personal">修改密码</el-dropdown-item>
          <el-dropdown-item command="quit">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <img src="../assets/goodsback_03.jpg" /> -->
    </div>
  </div>
</template>

<script>
// 引入local
import local from "@/utils/local";
export default {
  data() {
    return {
      userInfo: {},
      breadInfo: [],
    };
  },
  methods: {
    select(val) {
      // console.log(val);
      if (val == "personal") {
        // 跳转到个人中心
        this.$router.push("/member/passwordInfomodify");
      } else {
        this.$router.push("/login");
        // 清空本地存储
        local.clear();
        // 手动刷新页面，因为官方的坑 退出的时候必须刷新，否则会一直往动态路由中添加
        location.reload();
      }
    },
    //获取本地数据并渲染到界面
    async getInfo() {
      let { accountInfo } = await personal_api({});
      // 将数据赋值给getInfo
      this.userInfo = accountInfo;
      // 保存数据到本地存储
      local.set("userinfo", JSON.stringify(accountInfo));
    },
    // 动态面包屑
    bread() {
      // 定义一个空的数组
      let arrNew = [{ path: "/platform", title: "首页" }];
      // 遍历this.route.matched
      this.$route.matched.forEach((item) => {
        // 判断
        if (item.path !== "") {
          // 将数据push到arrNew中
          arrNew.push({
            path: item.path,
            title: item.meta.title,
          });
        }
      });

      // 将值赋给breadInfo
      this.breadInfo = arrNew;
    },
  },
  created() {
    // // 调用getInfo
    // this.getInfo();
    // //  接收个人中心页面发过来的通知
    // this.$bus.$on("modifyImg", () => {
    //   // 重绘视图
    //   this.getInfo();
    // });

    //动态面包屑
    this.bread();
    // console.log(this.$route.matched);
  },
  // 监听器
  watch: {
    "$route.path"() {
      this.bread();
    },
  },
};
</script>

<style lang="less" scoped>
.el-dropdown-link {
  display: flex;
  align-items: center;
}
.header {
  height: 70px;
  margin: auto 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /deep/ .el-breadcrumb__inner.is-link {
    font-weight: 500;
  }
  .photo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    img {
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }
  }
}
</style>