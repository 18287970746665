<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">问题列表</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增问题</el-button
            >
            <!-- 问题列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 问题 -->
                <el-table-column label="问题">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.question
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 答案 -->
                <el-table-column label="答案">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.solve }}
                    </div>
                  </template>
                </el-table-column>
                <!-- 顺序 -->
                <el-table-column label="顺序">
                  <template slot-scope="scope">
                    <span>{{ scope.row.sort }}</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      @click="show(scope.$index, scope.row)"
                      >{{
                        scope.row.showStatus == 0 ? "显示" : "隐藏"
                      }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <el-dialog title="新增问题" :visible.sync="dialogFormVisible">
                <el-form :model="typeForm" ref="typeForm">
                  <el-form-item label="问题:" porp="question">
                    <el-input v-model="typeForm.question"></el-input>
                  </el-form-item>
                  <el-form-item label="答案:" porp="key">
                    <el-input type="textarea" v-model="typeForm.key"></el-input>
                  </el-form-item>
                  <el-form-item label="顺序:" porp="num">
                    <el-input type="number" v-model="typeForm.num"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok('ruleForm')"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
              <!-- 编辑的弹窗 -->
              <el-dialog title="编辑问题" :visible.sync="dialogFormVisibles">
                <el-form :model="editForm2">
                  <el-form-item label="问题:">
                    <el-input v-model="editForm2.question"></el-input>
                  </el-form-item>
                  <el-form-item label="答案:">
                    <el-input
                      type="textarea"
                      v-model="editForm2.solve"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="顺序:">
                    <el-input type="number" v-model="editForm2.sort"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
import EditorBar from "@/components/wangEnduit.vue";

export default {
  name: "WriteArticle",
  components: {
    Card,
    HeaderNav,
    EditorBar,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      formLabelWidth: "120px",
      typeForm: {
        question: "",
        key: "",
        num: "",
      },
      editForm: {},
      editForm2: {},
      form: {},
      // 表格数据
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    show(index, row) {
      let id = row.id;
      if (row.showStatus == 1) {
        this.$api.problemHide(id).then((res) => {
          if (res.status == 200) {
            this.$message.success(res.result);
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$api.problemShow(id).then((res) => {
          if (res.status == 200) {
            this.$message.success(res.result);
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    getList(name, val) {
      let schoolVo = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.problemList(schoolVo).then((res) => {
        if (res.status == 200) {
          console.log(res.result, 123);
          this.total = res.result.total;
          this.tableData = res.result.records;
        }
      });
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(row, 1111);
      this.dialogFormVisibles = true;
      this.editForm2 = JSON.parse(JSON.stringify(row));
    },
    handleDelete(index, row) {
      console.log(index, row);
      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该问题, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.id;
        that.$api.problemDel(id).then((res) => {
          if (res.status == 200) {
            this.getList();
            that.$message.success(res.result);
          } else {
            that.$message.error(res.message);
          }
        });
      });
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.getList();
    },
    // 新增文档
    add() {
      this.dialogFormVisible = true;
    },
    ok() {
      if (this.typeForm.question === "") {
        this.$message.error("请输入问题!");
        return;
      }
      if (this.typeForm.key === "") {
        this.$message.error("请输入答案!");
        return;
      }
      if (this.typeForm.num === "") {
        this.$message.error("请输入顺序!");
        return;
      }

      let add = {
        question: this.typeForm.question,
        solve: this.typeForm.key,
        sort: this.typeForm.num,
      };
      this.$api.problemAdd(add).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisible = false;
          this.getList();
          this.$message.success("新增成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 编辑保存
    oks() {
      let edit = {
        id: this.editForm2.id,
        question: this.editForm2.question,
        solve: this.editForm2.solve,
        sort: this.editForm2.sort,
      };
      this.$api.problemEdit(edit).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibles = false;
          this.getList();
          this.$message.success(res.result);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 300px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>