<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">限时秒杀活动</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增活动</el-button
            >
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.actName"
                  placeholder="请输入活动名称"
                  style="width: 150px; margin-right: 30px"
                ></el-input>
                <el-button type="primary" size="medium" @click="pageList()"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 商家列表 -->
            <div class="table">
              <el-table border="" :data="tableData" style="width: 100%">
               <el-table-column type="expand">
                <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                 <el-form-item label="秒杀活动图">
                   <img
                    v-for="(item,index) in props.row.actDefaultImg.split(',')" :key="index"
                        style="width: 100px; height: 80px;margin-right:20px"
                        class="bannerImg"
                        :src="item"
                        alt=""/>
                 </el-form-item>
                </el-form>
                </template>
                </el-table-column>
                <!-- 活动名称 -->
                <el-table-column label="活动名称" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.actName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 所属商家 -->
                <el-table-column label="所属商家" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.shopName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 活动广告图 -->
                <!-- <el-table-column label="活动封面图" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img
                        style="width: 100px; height: 80px"
                        class="bannerImg"
                        :src="scope.row.actDefaultImg"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column> -->
                <!-- 拼团价格 -->
                <el-table-column label="儿童价格" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.childPrice }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="儿童数量" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.childStock }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="成人价格" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.adultPrice }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="成人数量" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.adultStock }}</span>
                  </template>
                </el-table-column>
                <!-- 上下架 -->
                <el-table-column label="状态" width="150">
                  <template slot-scope="scope">
                    <span> {{ scope.row.status == 0 ? "下架" : "上架" }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" width="300">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      @click="Push(scope.$index, scope.row)"
                      >推首页</el-button
                    >
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="on(scope.$index, scope.row)"
                    >
                      {{ scope.row.status == 1 ? "下架" : "上架" }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>

            <!-- 新增的弹窗 -->
            <el-dialog title="新增" width="40%" :visible.sync="dialogadd">
              <!-- 表单 -->
              <el-form
                ref="forms"
                :rules="rules"
                :model="Addform"
                label-width="110px"
              >
                <!-- 店铺名称 -->
                <el-form-item label="活动:" prop="act">
                  <el-select v-model="Addform.act" placeholder="请选择活动">
                    <el-option
                      :label="item.actName"
                      :value="item.id"
                      v-for="(item, index) in actlist"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="活动多图:">
                  <el-upload
                    ref="upload"
                    action
                    :before-upload="beforeUpload"
                    :http-request="uploadimg1"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                  >
                    <i class="">建议尺寸:390*208</i>
                  </el-upload>
                </el-form-item>
                <!-- 时间 -->
                <el-form-item label="售卖时间:">
                  <el-date-picker
                    v-model="time"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions1"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="活动时间段:">
                  <el-date-picker
                    type="dates"
                    v-model="time3"
                    placeholder="选择一个或多个日期"
                  >
                  </el-date-picker>
                </el-form-item>

                <!-- 价格 -->
                <el-form-item label="儿童价格:" porp="childPrice">
                  <el-input
                    v-model="Addform.childPrice"
                    type="number"
                  ></el-input>
                </el-form-item>
                <!-- 成人价格 -->
                <el-form-item label="成人价格:" porp="adultPrice">
                  <el-input v-model="Addform.adultPrice"></el-input>
                </el-form-item>
                <el-form-item label="最低数量(成):" prop="adultmin">
                  <el-input
                    v-model="Addform.adultmin"
                    placeholder="填写成人最低购买数量	"
                  ></el-input>
                </el-form-item>
                <el-form-item label="最低数量(童):" prop="childmin">
                  <el-input
                    v-model="Addform.childmin"
                    placeholder="填写儿童最低购买数量"
                  ></el-input>
                </el-form-item>
                <!--  -->
                <el-form-item label="儿童库存:" porp="childStock">
                  <el-input v-model="Addform.childStock"></el-input>
                </el-form-item>
                <el-form-item label="成人库存:" prop="adultStock">
                  <el-input v-model="Addform.adultStock"></el-input>
                </el-form-item>
                <el-form-item label="销量:" prop="actSale">
                  <el-input v-model="Addform.actSale"></el-input>
                </el-form-item>

                <!-- 活动描述 -->
                <el-form-item label="活动描述:">
                  <el-input v-model="Addform.actIntro"></el-input>
                </el-form-item>
                <!-- 活动详情 -->
                <el-form-item label="活动详情:">
                  <editor-bar
                    v-model="Addform.actDesc"
                    :isClear="isClear"
                  ></editor-bar>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogadd = false">取 消</el-button>
                <el-button type="primary" @click="ok">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 新增End -->

            <el-dialog title="编辑" width="40%" :visible.sync="dialogedit">
              <!-- 表单 -->
              <el-form ref="forms" :model="editForm" label-width="100px">
                <!-- 店铺名称 -->
                <!-- <el-form-item label="活动:">
                  <el-select v-model="editact" placeholder="请选择活动">
                    <el-option
                      :label="item.actName"
                      :value="item.id"
                      v-for="(item, index) in actlist"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="活动多图:">
                  <el-upload
                    action
                    :before-upload="beforeUpload"
                    :http-request="uploadimg1"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemoves"
                    :file-list="imgUrl"
                  >
                    <i class="">建议尺寸:390*208</i>
                  </el-upload>
                </el-form-item>
                <!-- 时间 -->
                <el-form-item label="售卖时间:">
                  <el-date-picker
                    v-model="time"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions1"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="活动时间段:">
                  <el-date-picker
                    type="dates"
                    v-model="time3"
                    placeholder="选择一个或多个日期"
                  >
                  </el-date-picker>
                </el-form-item>
                <!-- 价格 -->
                <el-form-item label="儿童价格:">
                  <el-input
                    v-model="editForm.childPrice"
                    type="number"
                  ></el-input>
                </el-form-item>
                <!-- 成人价格 -->
                <el-form-item label="成人价格:">
                  <el-input v-model="editForm.adultPrice"></el-input>
                </el-form-item>
                <el-form-item label="最低数量(成):">
                  <el-input
                    v-model="editForm.adultBuy"
                    placeholder="填写成人最低购买数量	"
                  ></el-input>
                </el-form-item>
                <el-form-item label="最低数量(童):">
                  <el-input
                    v-model="editForm.childBuy"
                    placeholder="填写儿童最低购买数量"
                  ></el-input>
                </el-form-item>
                <!--  -->
                <el-form-item label="儿童库存:">
                  <el-input v-model="editForm.childStock"></el-input>
                </el-form-item>
                <el-form-item label="成人库存:">
                  <el-input v-model="editForm.adultStock"></el-input>
                </el-form-item>
                <el-form-item label="销量:">
                  <el-input v-model="editForm.actSale"></el-input>
                </el-form-item>
                <!-- 活动描述 -->
                <el-form-item label="活动描述:">
                  <el-input v-model="editForm.actIntro"></el-input>
                </el-form-item>
                <!-- 活动详情 -->
                <el-form-item label="活动详情:">
                  <editor-bar
                    v-model="editForm.actDesc"
                    :isClear="isClear"
                  ></editor-bar>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogedit = false">取 消</el-button>
                <el-button type="primary" @click="okEdit">确 定</el-button>
              </div>
            </el-dialog>
            <el-dialog title="推首页" width="30%" :visible.sync="dialogpush">
              <el-form :model="pform">
                  <el-upload
                    ref="upload"
                    action
                    :http-request="uploadimg1"
                    list-type="picture-card"
                    :before-upload="beforeUpload"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :file-list="imgUrl"
                  >
                    <i class="">建议尺寸:370*140</i>
                  </el-upload>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogpush = false">取 消</el-button>
                <el-button type="primary" @click="indexpush()">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogImageUrl">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
import EditorBar from "@/components/wangEnduit.vue";

export default {
  name: "Actseckill",
  components: {
    Card,
    HeaderNav,
    EditorBar,
  },
  data() {
    return {
      isClear: false,
      imageUrl: "", //商品图片
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      pageSizes: 999,
      // 总条数
      total: 1,
      size: 10,
      shops: [],
      actlist: [],
      pform: {},
      // 新增弹窗
      dialogFormVisible: false,
      dialogVisible: false,
      dialogFormVisibles: false,
      dialogFormVisibless: false,
      dialogpush: false,
      dialogadd: false,
      dialogedit: false,
      dialogFormVisibleSreach: false,
      dialogImageUrl: false,
      Addform: {
        groupPrice: 0,
        act: "",
        childPrice: "",
        adultPrice: "",
        childStock: "",
        adultStock: "",
        actSale: "",
        actIntro: "",
        actDesc: "",
        adultmin: "",
      },
      editForm: {},
      needNumber: 0,
      groupPrice: 0,
      form: {},
      time: "",
      time3: "", //活动时间段
      // 表格数据
      tableData: [],
      timeData: "",
      // 图片
      imgUrl: [],
      imglist: [],
      imgUrlsIndex: [],
      t1: null,
      t2: null,
      shops: [],
      shop: [],
      actType: "",
      typeList: [],
      labels: [],
      label: [],
      labelValue: [],
      labelsSx: [],
      labelSx: [],
      labelValueSx: [],
      editact: "",
      rules: {
        adultmin: [
          {
            required: true,
            message: "请选择成人最低购买数量",
            trigger: "blur",
          },
        ],
        childmin: [
          {
            required: true,
            message: "请选择儿童最低购买数量",
            trigger: "blur",
          },
        ],

        act: [{ required: true, message: "请选择活动", trigger: "change" }],
        childPrice: [
          {
            required: true,
            message: "请填写儿童价格",
            trigger: "blur",
          },
        ],
        adultPrice: [
          {
            required: true,
            message: "请填写成人价格",
            trigger: "blur",
          },
        ],
        childStock: [
          {
            required: true,
            message: "请填写儿童库存",
            trigger: "blur",
          },
        ],
        adultStock: [
          {
            required: true,
            message: "请填写成人库存",
            trigger: "blur",
          },
        ],
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },
    uploadimg1(file) {
      const that = this;
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v, name: file.file.name });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    search() {
      let activityList = {
        isGroup: this.form.isGroup,
        actName: this.form.actName,
        shopName: this.form.shopName,
        type: 2,
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.activityList(activityList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    Push(index, row) {
      this.imgUrl = [];
      this.pform = row;
      this.dialogpush = true;
    },
    indexpush() {
      let img = "";
      if (this.imgUrl != "") {
        this.imgUrl.forEach((val) => {
          img = val.url;
        });
      }
      let list = {
        address: img,
        id: this.pform.actId,
        type: 1,
      };
      // let actId = row.id;
      this.$api.push(list).then((res) => {
        if (res.status == 200) {
          this.$message.success("推送成功");
          this.dialogpush = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 展示删除
    handleRemovesindex(file, fileList) {
      this.imgUrlsIndex.splice(-1);
    },
    handleRemove(file, fileList) {
      const filePath = file.name;
      this.imgUrl.forEach((val, index) => {
        if (val.name == filePath) {
          this.imgUrl.splice(index, 1);
        }
      });
      console.log(this.imgUrl);
    },
    handleRemoves(file, fileList) {
      const filePath = file.url;
      this.imgUrl.forEach((val, index) => {
        if (val.url == filePath) {
          this.imgUrl.splice(index, 1);
        }
      });
      console.log(this.imgUrl);
    },
    // 放大
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // 表格编辑删除
    handleAdd(index, row) {
      console.log(index, row);
      this.Addform = row;
      this.dialogFormVisibles = true;
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(row, "row");
      this.dialogedit = true;
      this.imgUrl = [];
      this.imglist = [];

      this.editForm = row;
      this.editact = row.actId;

      this.time = [row.startTime, row.endTime];
      // this.time3 =
      row.actDefaultImg.split(",").forEach((item) => {
        this.imgUrl.push({ url: item });
      });
      this.time3 = JSON.parse(row.saleTime);
      console.log(this.imgUrl);
    },
    handleDelete(index, row) {
      console.log(row);
      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.actId;
        this.$api.actseckillDel({ id: id }).then((res) => {
          if (res.status == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.pageList();
          }
        });
      });
    },
    handleSearch(index, row) {
      this.dialogFormVisibleSreach = true;
      let id = row.id;
      this.$api.groupSearch(id).then((res) => {
        if (res.status == 200) {
          this.Addform = row;
          console.log(row);
          this.needNumber = res.result.needNumber;
          this.groupPrice = res.result.groupPrice;
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.pageList();
    },
    // 新增广告
    add() {
      this.imgUrl = [];
      this.time = [];
      this.time3 = [];
      this.dialogadd = true;
    },
    ok() {
      if (this.Addform.act === "") {
        this.$message.error("请选择活动!");
        return;
      }
      if (this.imgUrl.length === 0) {
        this.$message.error("请添加活动图片!");
        return;
      }
      if (this.time === "") {
        this.$message.error("请添加活动时间!");
        return;
      }
      if (this.Addform.childPrice === "") {
        this.$message.error("请输入儿童价格!");
        return;
      }
      if (this.Addform.adultPrice === "") {
        this.$message.error("请输入成人价格!");
        return;
      }
      if (this.Addform.childStock === "") {
        this.$message.error("请输入儿童库存!");
        return;
      }
      if (this.Addform.adultStock === "") {
        this.$message.error("请输入成人库存!");
        return;
      }
      if (this.Addform.actSale === "") {
        this.$message.error("请输入销量!");
        return;
      }
      if (this.Addform.actIntro === "") {
        this.$message.error("请输入活动描述!");
        return;
      }
      if (this.Addform.actDesc === "") {
        this.$message.error("请输入活动详情!");
        return;
      }
      if (this.time3 === "") {
        this.$message.error("请输入时间段!");
        return;
      }
      let img = [];
      this.imgUrl.forEach((val) => {
        img.push(val.url);
      });
      img = img.join(",");

      console.log(img);
      var moment = require("moment");
      moment().format();
      var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
      var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
      if (this.time.length == 2) {
        this.t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
        this.t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      }
      let arrtime = [];
      let upMsg = {
        actDefaultImg: img,
        actDesc: this.Addform.actDesc,
        actId: this.Addform.act,
        actIntro: this.Addform.actIntro,
        endTime: this.t2,
        startTime: this.t1,
        adultBuy: this.Addform.adultmin,
        childBuy: this.Addform.childmin,
        adultPrice: this.Addform.adultPrice,
        adultStock: this.Addform.adultStock,
        childPrice: this.Addform.childPrice,
        childStock: this.Addform.childStock,
        actSale: this.Addform.actSale,
        saleTimeList: arrtime,
      };
      this.time3.forEach((item) => {
        arrtime.push(this.$dateFormat(new Date(item).getTime(), "Y-m-d"));
      });
      this.$refs.forms.validate((valid) => {
        if (valid) {
          this.$api.actseckillAdd(upMsg).then((res) => {
            if (res.status == 200) {
              this.$message.success(res.result);
              this.dialogadd = false;
              this.pageList();
              for (let key in this.Addform) {
                this.Addform[key] = "";
              }
              this.time = "";
              this.$refs.upload.clearFiles();
              this.$refs.uploads.clearFiles();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 上下架
    on(index, row) {
      let id = row.actId;
      console.log(row);
      if (row.status == 0) {
        this.$api.actseckillon(id).then((res) => {
          if (res.status == 200) {
            this.pageList();
          }
        });
      } else if (row.status == 1) {
        this.$api.actseckilloff(id).then((res) => {
          if (res.status == 200) {
            this.pageList();
          }
        });
      }
    },
    okEdit() {
      let arrimg = [];
      this.imgUrl.forEach((val) => {
        arrimg.push(val.url);
      });
      arrimg = arrimg.join(",");
      var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
      var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
      if (this.time.length == 2) {
        this.t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
        this.t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      }
      let arrtime = [];
      let Edit = {
        actDefaultImg: arrimg,
        actDesc: this.editForm.actDesc,
        actId: this.editact,
        actIntro: this.editForm.actIntro,
        endTime: this.t2,
        startTime: this.t1,
        adultBuy: this.editForm.adultBuy,
        childBuy: this.editForm.childBuy,
        adultPrice: this.editForm.adultPrice,
        adultStock: this.editForm.adultStock,
        childPrice: this.editForm.childPrice,
        childStock: this.editForm.childStock,
        actSale: this.editForm.actSale,
        saleTimeList: arrtime,
      };
      this.time3.forEach((item) => {
        arrtime.push(this.$dateFormat(new Date(item).getTime(), "Y-m-d"));
      });
      this.$api.actseckillEdit(Edit).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.dialogedit = false;
          this.pageList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    okSearch() {
      let act = {
        actId: this.Addform.id,
        groupPrice: this.Addform.groupPrice,
        needNumber: this.needNumber,
        shopId: this.Addform.shopId,
      };
      this.$api.groupEdit(act).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibleSreach = false;
          this.$message.success(res.result);
        }
      });
    },
    pageList() {
      let pageList = {
        page: this.currPage,
        pageSize: this.pageSize,
        actName: this.form.actName,
      };
      this.$api.actseckill(pageList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          // this.tableData.forEach(val=>{
          //   val.actDefaultImg=val.actDefaultImg.split(',')
          // })
          console.log(this.tableData, "tableDate");

          this.total = res.result.total;
        }
      });
    },

    storeList() {
      let storeList = {
        page: this.currPage,
        pageSize: 9999,
        status: 1,
      };
      this.$api.storeList(storeList).then((res) => {
        if (res.status == 200) {
          this.shops = res.result.records;
        }
      });
    },

    act() {
      let act = {
        page: this.currPage,
        pageSize: 9999,
        pubStatus: 1,
      };
      this.$api.activityList(act).then((res) => {
        if (res.status == 200) {
          this.actlist = res.result.records;
        }
      });
    },

    typeSel() {
      let type = {
        page: this.currPage,
        pageSize: this.pageSizes,
        catType: 2,
      };
      this.$api.storetype(type).then((res) => {
        if (res.status == 200) {
          this.typeList = res.result.records;
          console.log(res);
        }
      });
    },

    getLabel(item) {
      this.labelValue = item;
      console.log(item, "item");
    },

    getLabelSx(item) {
      this.labelValueSx = item;
      console.log(item, "item");
    },

    labellist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 0,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labels = res.result.records;
        }
      });
    },

    labelSxlist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 1,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labelsSx = res.result.records;
        }
      });
    },
  },

  created() {
    this.pageList();
    this.typeSel();
    this.act();
    this.storeList();
    this.labellist();
    this.labelSxlist();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input {
  width: 180px;
}
.el-button {
  margin-bottom: 5px;
}
.table {
  margin-top: 20px;
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>
