
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import { Message } from 'element-ui';
import store from './store';
import axios from 'axios';
import api from './apis/acc.js' // 导入api接口
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/resset.css'  //引入重置样式
import '@/assets/fonts/iconfont.css'  //引入字体图标库
import '@/assets/fonts/iconfont1.css'  //引入字体图标库

import { client, getFileNameUUID } from "@/components/ali-oss.js";
Vue.prototype.$getFileNameUUID = getFileNameUUID;
Vue.prototype.$client = client;
Vue.prototype.$dateFormat = function (timestamp, formats) {
  // formats格式包括
  // 1. Y-m-d
  // 2. Y-m-d H:i:s
  // 3. Y年m月d日
  // 4. Y年m月d日 H时i分
  // 5. Y.m.d
  // 6. 自定义格式
  formats = formats || 'Y-m-d';

  var zero = function (value) {
    if (value < 10) {
      return '0' + value;
    }
    return value;
  };

  var myDate = timestamp ? new Date(timestamp) : new Date();

  var year = myDate.getFullYear();
  var month = zero(myDate.getMonth() + 1);
  var day = zero(myDate.getDate());

  var hour = zero(myDate.getHours());
  var minite = zero(myDate.getMinutes());
  var second = zero(myDate.getSeconds());

  return formats.replace(/Y|m|d|H|i|s/ig, function (matches) {
    return ({
      Y: year,
      m: month,
      d: day,
      H: hour,
      i: minite,
      s: second
    })[matches];
  });
}
// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap,{ak:'CqT98AI3MybgfNFtlwYGlltAXuOXIMqH'})
import VueAMap from 'vue-amap'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '50321f34c95211d97a226a9af98000b0',
  plugin: [




    "AMap.Autocomplete",
    //输入提示插件




    "AMap.PlaceSearch",
    //POI搜索插件




    "AMap.Scale",
    //右下角缩略图插件 比例尺




    "AMap.OverView",
    //地图鹰眼插件




    "AMap.ToolBar",
    //地图工具条




    "AMap.MapType",
    //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制




    "AMap.PolyEditor",
    //编辑 折线多，边形




    "AMap.CircleEditor",
    //圆形编辑器插件




    "AMap.Geolocation",
    //定位控件，用来获取和展示用户主机所在的经纬度位置

    "AMap.Geocoder"


  ],

  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});

import moment from 'moment'
Vue.prototype.$moment = moment
Vue.filter('dateFmt', (input, formatString = "YYYY-MM-DD") => {
  return moment(input).format(formatString)
})


Vue.use(ElementUI, {
  size: 'small'
});
// 中介传参
let bus = new Vue()
Vue.prototype.$bus = bus
Vue.prototype.$message = Message;
Vue.prototype.$api = api;
Vue.prototype.$axios = axios;

//引入一个重置样式 在asset里引入css不需要用from
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

