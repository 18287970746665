<template>
  <div class="content">
    <!-- 右上 面包屑-->
    <div class="rigth-top">
      <HeaderNav> </HeaderNav>
    </div>
    <!-- 右下 -->
    <div class="rigth-bottom">
      <Card>
        <span slot="title">{{ useMsg.name }}</span>
        <div slot="content" class="contentbox">
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="首页图片:">
              <!-- <el-upload
                ref="upload"
                action="https://admin.bbtedu03.com:444/product/file_upload/uploadImg"
                list-type="picture-card"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :on-success="shopheadersuccessIndex"
                :file-list="imgUrl"
                :limit="1"
              >
                <i class="">建议图片尺寸:180*205</i>
              </el-upload> -->
              <el-upload
                ref="uploads"
                action
                :http-request="uploadimg1"
                list-type="picture-card"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :file-list="imgUrl"
                :limit="1"
              >
                <i class="">建议图片尺寸:180*205</i>
              </el-upload>
              <!-- <div style="margin: 20px 0; color: red"></div> -->
            </el-form-item>

            <div class="cut">
              <div style="margin-bottom: 20px; font-size: 14px">
                状态:{{ type == "-1" ? "禁用" : "启用" }}
              </div>

              <el-button type="primary" size="medium" @click="edit">{{
                disabled == true ? "编辑/修改" : "保存"
              }}</el-button>
              <el-button type="primary" size="medium" @click="on"
                >启用</el-button
              >
              <el-button type="danger" size="medium" @click="off"
                >禁用</el-button
              >
            </div>
          </el-form>
          <el-dialog :visible.sync="dialogVisible">
            <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
// 引入卡片
// 引入面包屑
import HeaderNav from "@/components/HeaderNav.vue";
import Card from "@/components/Card.vue";
export default {
  name: "WriteArticle",
  components: {
    HeaderNav,
    Card,
  },
  data() {
    return {
      useMsg: {},
      disabled: false,
      dialogVisible: false,
      dialogImageUrl: "",
      form: {},
      imgUrl: [],
      imgUrlsIndex: "",
      type: "",
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    uploadimg1(file) {
      const that = this;
      that.imgUrl = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }

            if (v.indexOf("?") != -1) {
              that.imgUrl.push({ url: v.split("?")[0] });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v });
              console.log(that.imgUrl, "that.imgUrlsIndex1111");
            }
          });
      }
      multipartUpload();
    },
    // shopheadersuccessIndex(file) {
    //   this.imgUrlsIndex = file.result;
    //   console.log(this.imgUrlsIndex);
    // },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getText(name, val) {
      let id = {
        id: "13",
      };
      this.$api.manager(id).then((res) => {
        if (res.status == 200) {
          this.useMsg = res.result;
          this.type = res.result.tel;
          this.imgUrl.push({ url: res.result.content });
        }
      });
    },
    edit() {
      let updata = {
        content: this.imgUrl.length >= 1 ? this.imgUrl[0].url : "",
        id: 13,
        tel: "1",
        name: "首页图片",
      };
      this.$api.update(updata).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    on() {
      this.$api.onimg().then((res) => {
        if (res.status == 200) {
          this.$message.success(res.message);
          let id = {
            id: "13",
          };
          this.$api.manager(id).then((res) => {
            if (res.status == 200) {
              this.useMsg = res.result;
              this.type = res.result.tel;
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    off() {
      this.$api.stopimg().then((res) => {
        if (res.status == 200) {
          this.$message.success(res.message);
          let id = {
            id: "13",
          };
          this.$api.manager(id).then((res) => {
            if (res.status == 200) {
              this.useMsg = res.result;
              this.type = res.result.tel;
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  created() {
    this.getText();
  },
};
</script>

<style lang="less" scoped>
.content {
  .contentbox {
    display: flex;
    div {
      flex: 1;
    }
    .tip {
      font-size: 40px;
      text-align: center;
    }
    .card {
      border: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      letter-spacing: 1px;
    }
    .cut {
      text-align: center;
    }
  }
}
</style>