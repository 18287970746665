<template>
  <div class="index">
    <!-- 左侧导航 -->
    <div class="left">
     <LeftNavs></LeftNavs>
    </div>
    <!-- 右侧内容 -->
    <div class="content">
      
       <router-view></router-view>
    </div>
  </div>
</template>

<script>
//  import LeftNav from  '@/components/LeftNav.vue'
 import LeftNavs from '@/components/LeftNav copy.vue'
export default {
  components:{
    LeftNavs,
  }
}
</script>

<style lang="less" scoped>
.index {
  display: flex;
  width: 100%;
  height: 100%;
 
  .content{
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>