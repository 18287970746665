<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">标签列表</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增标签</el-button
            >
            <!-- 标签列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 标签名称 -->
                <el-table-column label="标签名称" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.attrName
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="标签类型" width="200">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.attrType == "0" || scope.row.attrType == "标签"
                        ? "标签"
                        : scope.row.attrType == "1" ||
                          scope.row.attrType == "基本属性"
                        ? "基本属性"
                        : scope.row.attrType == "2" ||
                          scope.row.attrType == "年龄段"
                        ? "年龄段"
                          : scope.row.attrType == "3" ||
                          scope.row.attrType == "推广"
                        ? "推广"
                        :""
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="200">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <!-- 新增的弹窗 -->
              <el-dialog title="新增标签" :visible.sync="dialogFormVisible">
                <el-form :model="addForm">
                  <el-form-item label="标签名称:">
                    <el-input v-model="addForm.attrName"></el-input>
                  </el-form-item>
                  <el-form-item label="标签类型">
                    <el-select
                      v-model="addForm.attrType"
                      placeholder="请选择标签类型"
                      @change="gettype"
                    >
                      <el-option label="标签" value="0"></el-option>
                      <el-option label="基本属性" value="1"></el-option>
                      <el-option label="年龄段" value="2"></el-option>
                      <el-option label="推广" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="标签颜色:" v-if="show">
                    <el-input
                      v-model="addForm.color"
                      style="width: 100px"
                      type="color"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 编辑的弹窗 -->
              <el-dialog title="编辑标签" :visible.sync="dialogFormVisibles">
                <el-form :model="editForm">
                  <el-form-item label="标签名称:">
                    <el-input v-model="editForm.attrName"></el-input>
                  </el-form-item>
                  <el-form-item label="标签类型">
                    <el-select
                      v-model="editForm.attrType"
                      placeholder="请选择标签类型"
                      @change="gettype"
                    >
                      <el-option label="标签" value="0"></el-option>
                      <el-option label="基本属性" value="1"></el-option>
                      <el-option label="年龄段" value="2"></el-option>
                      <el-option label="推广" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="标签颜色:" v-if="show">
                    <el-input
                      v-model="editForm.icon"
                      style="width: 100px"
                      type="color"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogVisible: false,
      editForm: {},
      addForm: {
        attrName: "",
        attrType: "",
      },
      form: {
        name: "",
      },
      // 表格数据
      tableData: [],
      actlist: [],
      show: false,
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;

      // 重绘视图
      this.labelList();
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(index, row);
      this.editForm = row;

      if (row.attrType != 1) {
        this.show = false;
      } else {
        this.show = true;
      }
      if (row.attrType == 0) {
        this.editForm.attrType = "标签";
      } else if (row.attrType == 1) {
        this.editForm.attrType = "基本属性";
      } else if (row.attrType == 2) {
        this.editForm.attrType = "年龄段";
      } else if (row.attrType == 3) {
        this.editForm.attrType = "推广";
      }
      this.dialogFormVisibles = true;
      this.imgUrl = [];
      this.imgUrl.push({ url: row.url });
    },
    handleDelete(index, row) {
      console.log(index, row);

      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.id;
        that.$api.labelDel({ id: id }).then((res) => {
          if (res.status == 200) {
            that.labelList();
          }
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.labelList();
      });
    },
    gettype(item) {
      console.log(item);
      if (item == 1) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    // 新增广告
    add() {
      this.dialogFormVisible = true;
    },
    ok() {
      if (this.addForm.attrName === "") {
        this.$message.error("请输入标签名称!");
        return;
      }
      if (this.addForm.attrType === "") {
        this.$message.error("请选择标签类型!");
        return;
      }
      let labelAdd = {
        attrType: this.addForm.attrType,
        attrName: this.addForm.attrName,
        icon: this.addForm.color,
      };
      this.$api.labelAdd(labelAdd).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.labelList();
          this.dialogFormVisible = false;
          this.addForm = { brand_right: 0 };
        } else {
          this.$message.error(res.message);
        }
      });
    },
    oks() {
      if (this.editForm.attrType == "标签") {
        this.editForm.attrType = 0;
      } else if (this.editForm.attrType == "基本属性") {
        this.editForm.attrType = 1;
      } else if (this.editForm.attrType == "年龄段") {
        this.editForm.attrType = 2;
      }
      let bannerEdit = {
        attrType: this.editForm.attrType,
        attrName: this.editForm.attrName,
        id: this.editForm.id,
        icon: this.editForm.icon,
      };
      this.$api.labelEdit(bannerEdit).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibles = false;
          this.$message.success(res.result);
          this.labelList();
        }
      });
    },
    labelList() {
      let label = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
  },
  created() {
    this.labelList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>