<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">出行人列表</span>
          <div slot="content">
            <!-- 出行人列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 出行人名称 -->
                <el-table-column label="出行人名称">
                  <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <!-- 身份证 -->
                <el-table-column label="身份证">
                  <template slot-scope="scope">
                    <span>{{ scope.row.idCard }}</span>
                  </template>
                </el-table-column>
                <!-- 手机号 -->
                <el-table-column label="手机号">
                  <template slot-scope="scope">
                    <span>{{ scope.row.phone }}</span>
                  </template>
                </el-table-column>
                <!-- 性别 -->
                <el-table-column label="性别">
                  <template slot-scope="scope">
                    <span>{{ scope.row.sex == 1 ? "男" : "女" }}</span>
                  </template>
                </el-table-column>
                <!-- 生日 -->
                <el-table-column label="生日">
                  <template slot-scope="scope">
                    <span>{{ scope.row.birDay }}</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      addForm: {},
      // 表格数据
      tableData: [],
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    travelerList() {
      let list = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.travelerList(list).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;

      // 重绘视图
      this.travelerList();
    },
  },
  created() {
    this.travelerList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>