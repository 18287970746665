<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">新增文档</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增文档</el-button
            >
            <!-- 文档列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 文章标题 -->
                <el-table-column label="文章标题" width="250">
                  <template slot-scope="scope">
                    <i class="el-icon-paperclip"></i>
                    <span style="margin-left: 10px">{{ scope.row.title }}</span>
                  </template>
                </el-table-column>
                <!-- 文章内容 -->
                <!-- <el-table-column label="文章内容" width="450">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.content }}
                    </div>
                  </template>
                </el-table-column> -->
                <!-- 作者 -->
                <el-table-column label="作者" width="250">
                  <template slot-scope="scope">
                    <span>{{ scope.row.author }}</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="250">
                  <template slot-scope="scope">
                    <span>{{ scope.row.createTime| dateFmt("YYYY-MM-DD") }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <el-dialog title="新增文档" :visible.sync="dialogFormVisible">
                <el-form :model="typeForm">
                  <el-form-item label="文章标题:">
                    <el-input v-model="typeForm.title"></el-input>
                  </el-form-item>
                  <el-form-item label="文章内容:">
                    <editor-bar
                      v-model="typeForm.description"
                      :isClear="isClear"
                      @change="change"
                    ></editor-bar>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 编辑的弹窗 -->

              <el-dialog title="编辑文档" :visible.sync="dialogFormVisibles">
                <el-form :model="editForm">
                  <el-form-item label="文章标题:">
                    <el-input v-model="editForm.Edittitle"></el-input>
                  </el-form-item>
                  <el-form-item label="文章内容:">
                    <editor-bar
                      v-model="editForm.Editcontent"
                      :isClear="isClear"
                      @change="change"
                    ></editor-bar>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
import EditorBar from "@/components/wangEnduit.vue";

export default {
  name: "WriteArticle",
  components: {
    Card,
    HeaderNav,
    EditorBar,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      formLabelWidth: "120px",
      isClear: false,
      typeForm: {
        title: "",
        content: "",
        description: "",
      },
      editForm: {
        Edittitle: "",
        Editcontent: "",
        id: "",
      },
      form: {
        description: "",
      },
      // 表格数据
      tableData: [],
      formMsg: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    change(val) {
      console.log(val, "......................");
    },
    getList(name, val) {
      let schoolVo = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.articleList(schoolVo).then((res) => {
        if (res.status == 200) {
          console.log(res.result, 123);
          this.total = res.result.total;
          this.tableData = res.result.records;
        }
      });
    },
    //商品图片
    handleAvatarSuccess(res) {
      console.log(res);
      let { code, msg, imgUrl } = res;
      if (code == 0) {
        this.$message({
          type: "success",
          message: msg,
        });
        this.imageUrl = imgUrl;
        // console.log(imgUrl);
      } else {
        this.$message.error(msg);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(row, 1111);
      this.dialogFormVisibles = true;
      this.editForm.Edittitle = row.title;
      this.editForm.Editcontent = row.content;
      this.editForm.id = row.id;
    },
    handleDelete(index, row) {
      console.log(index, row);
      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.id;
        that.$api.del(id).then((res) => {
          if (res.status == 200) {
            this.getList();
          }
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getList();
      });
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.getList();
    },
    // 新增文档
    add() {
      this.dialogFormVisible = true;
    },
    ok() {
      let add = {
        title: this.typeForm.title,
        content: this.typeForm.description,
        id: localStorage.getItem("loginMsg".id),
      };
      if (this.typeForm.title != "" && this.typeForm.description != "") {
        this.$api.add(add).then((res) => {
          if (res.status == 200) {
            this.dialogFormVisible = false;
            this.getList();
            this.$message.success("添加成功");
          }
        });
      } else {
        this.$message("标题或内容不能为空");
      }
    },
    // 编辑保存
    oks() {
      let edit = {
        title: this.editForm.Edittitle,
        content: this.editForm.Editcontent,
        id: this.editForm.id,
      };
      if (this.editForm.Edittitle != "" && this.editForm.Editcontent != "") {
        this.$api.edit(edit).then((res) => {
          if (res.status == 200) {
            this.dialogFormVisibles = false;
            this.getList();
            this.$message.success("保存成功");
          }
        });
      } else {
        this.$message("标题或内容不能为空");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 300px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>