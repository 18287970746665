/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
 import axios from 'axios';
 import router from '../router';
 import Element from 'element-ui'
 import 'element-ui/lib/theme-chalk/index.css'
 /**
  * 设置cookie
  * @param {type} name
  * @param {type} value
  * @returns {undefined}
  */
 function setCookie(name, value) {
     var Days = 30;
     var exp = new Date();
     exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
     document.cookie = name + "=" + escape(value) + ";path=/;expires=" + exp.toGMTString();
 }
 
 /**
  * 获取cookie
  * @param {type} name
  * @returns {unresolved}
  */
 function getCookie(name) {
     var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
     var ss = (arr = document.cookie.match(reg))
     if (ss) {
         return unescape(arr[2]);
     }
     return null;
 }
 
 /**
  * 删除cookie
  * @param {type} name
  * @returns {undefined}
  */
 function delCookie(name) {
     var exp = new Date();
     exp.setTime(exp.getTime() - 1);
     var cval = getCookie(name);
     if (cval !== null)
         document.cookie = name + "=" + cval + ";path=/;expires=" + exp.toGMTString();
 }
 
 
 /**
  * 提示函数
  * 禁止点击蒙层、显示一秒后关闭
  */
 const tip = msg => {
     Element.MessageBox({
         message: msg,
         duration: 1000,
         forbidClick: true
     });
 }
 const sikp = msg => {
 
     Element.MessageBox.alert({
         message: msg,
         overlay: true,
         showCancelButton: true,
         confirmButtonText: '去订阅',
         confirmButtonColor: 'rgba(20,163,101,1)',
         closeOnClickOverlay: true
     }).then(() => {
         topay();
     });
 }
 
 /**
  * 跳转登录页
  * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
  */
 const toLogin = () => {
     router.replace({
         path: '/login',
         query: {
             redirect: router.currentRoute.fullPath
         }
     });
 }
 
 
 /**
  * 请求失败后的错误统一处理
  * @param {Number} status 请求失败的状态码
  */
 const errorHandle = (status, other) => {
     // 状态码判断
     console.log(other, 'other')
     switch (status) {
         // 401: 未登录状态，跳转登录页
         case 401:
             toLogin();
             break;
         // 403 token过期
         // 清除token并跳转登录页
         case 403:
             tip('登录过期，请重新登录');
             delCookie('token_access')
             toLogin();
             break;
         case 500:
             tip(other);
             delCookie('token_access')
             setTimeout(() => {
                 toLogin();
             }, 1000);
             break;
         // 404请求不存在
         case 404:
             tip('请求的资源不存在');
             break;
 
         default: window.console.log(other);
     }
 }
 
 // 创建axios实例
 var instance = axios.create({ timeout: 1000 * 30 });
 var access_token = localStorage.getItem('token_access')
 // 设置post请求头
 instance.defaults.headers.post['Content-Type'] = 'application/json';
 
 /**
  * 请求拦截器
  * 每次请求前，如果存在token则在请求头中携带token
  */
//   console.log(localStorage.getItem('type',111111))

 instance.interceptors.request.use(
     config => {
 
         // 登录流程控制中，根据本地是否存在token判断用户的登录情况
         // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
         // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
         // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
         const token = localStorage.getItem('token_access');
        //  const tokens = localStorage.getItem('token');
         // const token = '';
         token && (config.headers['merchants-path'] = token);

         if(localStorage.getItem('type')==1){
             console.log(1,localStorage.getItem('type'))
            token && (config.headers['backstage-path'] = token);
        }else{
             console.log(2,localStorage.getItem('type'))
        }
         return config;
     },
     error => Promise.error(error))
 
 // 响应拦截器
 instance.interceptors.response.use(
     // 请求成功
     res => {
         if (res.data.status == 403) {
             tip(res.data.message);
             delCookie('token_access')
             setTimeout(() => {
                 toLogin();
             }, 1000);
         }
         if (res.data.status == 500) {
             sikp(res.data.message)
         }
         if (res.data.status == 404) {
             tip(res.data.message);
         }
 
 
         return res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res.data)
     },
 
 
 
     // 请求失败
     error => {
         const { response } = error;
         if (response) {
             // 请求已发出，但是不在2xx的范围
             errorHandle(response.status, response.data.message);
             return Promise.reject(response.data);
         } else {
             // 处理断网的情况
             // eg:请求超时或断网时，更新state的network状态
             // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
             // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
             if (!window.navigator.onLine) {
                 setCookie('status', false)
             } else {
                 return Promise.reject(error);
             }
         }
     }
 );
 
 export default instance;