<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">年卡管理</span>

          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增年卡</el-button
            >
            <!-- 年卡列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 年卡ID -->
                <!-- <el-table-column label="年卡ID" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.id }}</span>
                  </template>
                </el-table-column> -->
                <el-table-column label="年卡名称" width="100">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <!-- 年卡广告图 -->
                <el-table-column label="年卡广告图" width="140">
                  <template slot-scope="scope">
                    <div>
                      <img
                        class="bannerImg"
                        style="width: 100px; height: 100px"
                        :src="scope.row.url"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 价格 -->
                <el-table-column label="价格" width="60">
                  <template slot-scope="scope">
                    <span>{{ scope.row.price }}</span>
                  </template>
                </el-table-column>
                <!-- 地区 -->
                <el-table-column label="地区" width="80">
                  <template slot-scope="scope">
                    <span>{{ scope.row.region }}</span>
                  </template>
                </el-table-column>
                <!-- 状态 -->
                <el-table-column label="状态" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.status == 0 ? "禁用" : "启用" }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="ONoff(scope.$index, scope.row)"
                      >{{ scope.row.status == 1 ? "禁用" : "启用" }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDel(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <!-- 编辑的弹窗 -->
              <el-dialog title="编辑列表" :visible.sync="dialogFormVisible">
                <el-form label-width="100px" ref="editForm" :model="editForm">
                  <el-form-item label="年卡封面:">
                    <el-upload
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemovesindex"
                      :file-list="imgUrlsIndex"
                      :limit="1"
                    >
                      <i class="">建议尺寸:385*220</i>
                    </el-upload>
                  </el-form-item>
                  <div
                    style="margin: 20px 0; color: red; font-size: 14px"
                  ></div>
                  <el-form-item label="年卡背景图:">
                    <el-upload
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg2"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :file-list="imgUrl"
                      :limit="1"
                    >
                      <i class="">建议尺寸:365*80</i>
                    </el-upload>
                  </el-form-item>
                  <!-- <div style="margin: 20px 0; color: red; font-size: 14px">
                    建议图片尺寸:325*75
                  </div> -->
                  <el-form-item label="年卡名称:">
                    <el-input style="width: 220px" v-model="editForm.name">{{
                      editForm.name
                    }}</el-input>
                  </el-form-item>
                  <el-form-item label="价格:">
                    <el-input style="width: 220px" v-model="editForm.price">{{
                      editForm.price
                    }}</el-input>
                  </el-form-item>
                  <!-- 年卡区域 -->
                  <!-- <el-form-item label="年卡区域:">
                    <el-select
                      v-model="value1"
                      placeholder="请选择"
                      @change="Cprovince"
                      style="width: 120px"
                    >
                      <el-option
                        v-for="item in province"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="value2"
                      placeholder="请选择"
                      @change="Cprovincecity"
                      style="width: 120px"
                    >
                      <el-option
                        v-for="item in city"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>

                    <el-select
                      v-model="value4"
                      placeholder="请选择"
                      @change="Cprovincereg"
                      style="width: 120px"
                    >
                      <el-option
                        v-for="item in regions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="年卡颜色:">
                    <el-input
                      v-model="editForm.color"
                      style="width: 100px"
                      type="color"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="年卡描述:">
                    <el-input
                      style="width: 320px"
                      type="textarea"
                      v-model="editForm.description"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="年卡状态:">
                    {{ editForm.status == 0 ? "禁用" : "启用" }}
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>

              <!-- 新增的弹窗 -->
              <el-dialog title="新增年卡" :visible.sync="dialogFormVisibles">
                <el-form
                  label-width="100px"
                  :model="addForm"
                  :rules="rules"
                  ref="formItemName"
                >
                  <el-form-item label="年卡封面:">
                    <el-upload
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemovesindex"
                      :limit="1"
                    >
                      <i class="">建议尺寸:385*220</i>
                    </el-upload>
                  </el-form-item>
                  <!-- <div style="margin: 20px 0; color: red; font-size: 14px">
                    建议图片尺寸:
                  </div> -->
                  <el-form-item label="年卡背景图:">
                    <el-upload
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg2"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :limit="1"
                    >
                      <i class="">建议尺寸:385*80</i>
                    </el-upload>
                  </el-form-item>

                  <el-form-item label="年卡名称:" prop="actName">
                    <el-input
                      v-model="addForm.actName"
                      style="width: 220px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="年卡价格:" prop="price">
                    <el-input
                      v-model="addForm.price"
                      style="width: 220px"
                    ></el-input>
                  </el-form-item>
                  <!-- 年卡区域 -->
                  <el-form-item label="年卡区域:">
                    <el-select
                      v-model="value1"
                      placeholder="请选择"
                      @change="Cprovince"
                      style="width: 120px"
                    >
                      <el-option
                        v-for="item in province"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="value2"
                      placeholder="请选择"
                      @change="Cprovincecity"
                      style="width: 120px"
                    >
                      <el-option
                        v-for="item in city"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>

                    <el-select
                      v-model="value3"
                      placeholder="请选择"
                      @change="Cprovincereg"
                      style="width: 120px"
                    >
                      <el-option
                        v-for="item in regions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="年卡颜色:">
                    <el-input
                      v-model="addForm.color"
                      style="width: 100px"
                      type="color"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="商户名:">
                    <el-select
                      v-model="addForm.shopName"
                      style="width: 415px"
                      placeholder="请选择添加的商户"
                    >
                      <el-option
                        :label="item.shopName"
                        :value="item.id"
                        v-for="(item, index) in shoplist"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="年卡描述:" prop="desc">
                    <el-input
                      style="width: 320px"
                      type="textarea"
                      v-model="addForm.desc"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  data() {
    return {
      options: [],
      province: [],
      city: [],
      regions: [],
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,

      editForm: {},
      addForm: {},
      dialogVisible: false,
      // 表格数据
      tableData: [],
      dialogImageUrl: "",
      imgUrlsIndex: [],
      imgUrlsIndexs: "",
      imgUrl: [],
      imgUrls: "",
      timeData: "",
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      shoplist: [],
      a: {},
      rules: {
        actName: [
          { required: true, message: "请输入年卡名称", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入年卡价格", trigger: "blur" }],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
        sw: [
          {
            required: true,
            message: "请输入权重，四位数以下",
            trigger: "blur",
          },
        ],
        pwd: [{ required: true, message: "请输入商家密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.annuaList();
    this.Searchaddress();
  },
  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },
    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrlsIndex.push({
                url: v.split("?")[0],
              });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex");
            } else {
              that.imgUrlsIndex.push({ url: v });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex1111");
            }
          });
      }
      multipartUpload();
    },
    uploadimg2(file) {
      const that = this;

      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.annuaList();
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(index, row);
      this.editForm = row;
      this.imgUrlsIndex = [];
      this.imgUrlsIndex.push({ url: row.url });
      this.imgUrl = [];
      this.imgUrl.push({ url: row.backPic });
      this.dialogFormVisible = true;
    },
    handleDel(index, row) {
      let that = this;
      this.$confirm("此操作将永久删除该年卡, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let id = row.id;
          that.$api.delCard(id).then((res) => {
            if (res.status == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              that.annuaList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 上下架
    ONoff(index, row) {
      console.log(row);
      let cardId = row.id;
      if (row.status == 0) {
        this.$api.onAnnua(cardId).then((res) => {
          if (res.status == 200) {
            this.annuaList();
          }
        });
      } else if (row.status == 1) {
        this.$api.offAnnua(cardId).then((res) => {
          if (res.status == 200) {
            this.annuaList();
          }
        });
      }
    },

    add() {
      this.imgUrl = [];
      this.imgUrlsIndex = [];
      this.dialogFormVisibles = true;
      this.shop();
    },
    // 年卡列表
    annuaList() {
      let list = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.annuaList(list).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    shop() {
      this.$api.searchShop().then((res) => {
        if (res.status == 200) {
          this.shoplist = res.result;
        }
      });
    },
    // 编辑单图删除
    handleRemovesindex(file, fileList) {
      this.imgUrlsIndex.splice(0, 1);
    },
    // 编辑多图删除
    handleRemove(file, fileList) {
      this.imgUrl.splice(0, 1);
      console.log(this.imgUrl, 2222);
    },
    // 放大
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // 新增单图删除
    handleRemovesindexs(file, fileList) {},
    // 新增多图删除
    handleRemoves(file, fileList) {},
    // 省
    Searchaddress() {
      this.$api.Searchaddress().then((res) => {
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.province = prsArrs;
        }
      });
    },
    // 城市
    Searchcity() {
      let pid = this.value1;
      this.$api.citys(pid).then((res) => {
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.city = prsArrs;
        }
      });
    },
    // 城市
    searchreg() {
      let pid = this.value2;
      this.$api.citys(pid).then((res) => {
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.regions = prsArrs;
          console.log(this.regions, 1111);
        }
      });
    },
    Cprovince() {
      console.log(this.value1, 222);
      this.Searchcity();
    },
    Cprovincecity(e) {
      this.searchreg();
      this.value4 = "";
      this.city.forEach((item) => {
        if (item.value == e) {
          sessionStorage.setItem("name", item.label);
        }
      });
    },
    Cprovincereg(e) {
      this.regions.forEach((item) => {
        if (item.value == e) {
          sessionStorage.setItem("name", item.label);
        }
      });
    },
    ok() {
      if (this.value1 != "" && this.value2 != "") {
        let regionId = "";
        if (this.value3 == "") {
          regionId = this.value2;
        } else {
          regionId = this.value3;
        }
        let img1 = "";
        let img2 = "";
        if (this.imgUrlsIndex.length) {
          this.imgUrlsIndex.forEach((item) => {
            img1 = item.url;
          });
          console.log(img1, "imgUrlsIndex");
        }
        if (this.imgUrl.length) {
          this.imgUrl.forEach((item) => {
            img2 = item.url;
          });
          console.log(img2, "imgurl");
        }
        this.$refs.formItemName.validate((valid) => {
          if (valid) {
            let add = {
              name: this.addForm.actName,
              price: this.addForm.price,
              // description:this.addForm.desc,
              backPic: img2,
              url: img1,
              // shopId: this.addForm.shopName,
              color: this.addForm.color,
              regionId: regionId,
              region: sessionStorage.getItem("name"),
              description: this.addForm.desc,
            };
            if (this.imgUrlsIndexs != "" || this.imgUrl.length != 0) {
              this.$api.addcard(add).then((res) => {
                if (res.status == 200) {
                  this.dialogFormVisibles = false;
                  this.addForm = { brand_right: 0 };

                  this.$message.success(res.result);
                  this.$refs.upload.clearFiles();
                  this.$refs.uploads.clearFiles();
                  this.annuaList();
                  this.$message.success("新增成功");
                } else {
                  this.$message.error(res.message);
                }
              });
            } else {
              this.$message.error("请添加图片");
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$message.error("请选择年卡区域");
      }
    },

    oks() {
      let regionId = "";
      if (this.value4 == "") {
        regionId = this.value2;
      } else {
        regionId = this.value4;
      }
      console.log(this.value4, "3333");

      let img1 = "";
      let img2 = "";
      if (this.imgUrlsIndex.length) {
        this.imgUrlsIndex.forEach((item) => {
          img1 = item.url;
        });
        console.log(img1, "imgUrlsIndex");
      }
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img2 = item.url;
        });
        console.log(img2, "imgurl");
      }
      let editAnnua = {
        id: this.editForm.id,
        name: this.editForm.name,
        price: this.editForm.price,
        backPic: img2,
        color: this.editForm.color,
        // shopId: this.editForm.shopId,
        regionId: this.editForm.regionId,

        region: sessionStorage.getItem("name"),
        url: img1,
        description: this.editForm.desc,
      };
      this.$api.editAnnua(editAnnua).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.dialogFormVisible = false;
          this.annuaList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>
