<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">活动核销</span>
          <div slot="content">
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="活动核销：">
                <el-input
                  v-model="form.number"
                  placeholder="请输入电子券码"
                  style="width: 240px; margin-right: 30px"
                ></el-input>
                <el-button type="primary" size="medium" @click="off"
                  >点击核销</el-button
                >
              </el-form-item>
              <el-form-item label="代金券核销：">
                <el-input
                  v-model="form.vnumber"
                  placeholder="请输入电子券码"
                  style="width: 240px; margin-right: 30px"
                ></el-input>
                <el-button type="primary" size="medium" @click="voff"
                  >点击核销</el-button
                >
              </el-form-item>
              <el-form-item label="年卡活动核销：">
                <el-select
                  @change="AddSelectDept"
                  v-model="form.Cardact"
                  placeholder="请选择年卡活动"
                >
                  <el-option
                    v-for="(item, i) in msg"
                    :key="i"
                    :label="item.actName"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <el-input
                  v-model="form.Cardnumber"
                  placeholder="请输入电子券码"
                  style="width: 240px; margin-left: 30px; margin-right: 30px"
                ></el-input>
                <el-button type="primary" size="medium" @click="cardWrite"
                  >查询用户信息</el-button
                >
              </el-form-item>
            </el-form>

            <!-- 弹窗 -->
            <el-dialog title="查看信息" :visible.sync="dialogFormVisible">
              <el-form :model="sForm">
                <div class="head">
                  用户头像：
                  <div class="headimg">
                    <img :src="sForm.header" alt="" />
                  </div>
                </div>
                <el-form-item label="用户昵称:">
                  <el-input
                    v-model="sForm.username"
                    disabled
                  ></el-input> </el-form-item
                ><el-form-item label="电话号码:">
                  <el-input v-model="sForm.mobile" disabled></el-input>
                </el-form-item>
                <el-form-item label="身份证号:">
                  <el-input v-model="sForm.idCard" disabled></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="Cardoff">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      form: {
        number: "",
        Cardnumber: "",
        Cardact: "",
      },
      sForm: {},
      dialogFormVisible: false,
      msg: [],
      ActName: [],
    };
  },

  methods: {
    /**
     * val 需要添加星号的值
     * front 前面保留几位
     * after 后面保留几位
     */
    addStar(val,front,after){
      return val.substring(0,front)+'*'.repeat(val.length-(front+after))+val.substring(val.length-after)
    },
    AddSelectDept(val) {
      let obj = {};
      obj = this.msg.find((item) => {
        console.log(item);
        return item.id === val;
      });
      console.log(obj.actName); //获取name
      sessionStorage.setItem("actname", obj.actName);
    },
    cardWrite() {
      let that = this;
      if (this.form.Cardnumber == "") {
        this.$message.error("电子卷码不能为空!");
        return;
      }
      var cardCode = that.form.Cardnumber;
      that.$api.cardUser({ cardCode: cardCode }).then((res) => {
        if (res.status == 200) {
          that.dialogFormVisible = true;
          this.sForm = res.result;
          this.sForm.mobile = this.addStar(this.sForm.mobile,3,4);
          this.sForm.idCard = this.addStar(this.sForm.idCard,3,4);
        } else if (res.status == 406) {
          this.$message.error(res.message);
        }
      })
    },
    // 代金券核销
    voff() {
      let that = this;
      // 核销框
      if (this.form.vnumber == "") {
        this.$message.error("请输入电子券码");
      } else {
        this.$confirm("将券码核销, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          // 核销
          let code = this.form.vnumber;
          that.$api.voucherhx({ code: code }).then((res) => {
            if (res.status == 200) {
              this.$message({
                type: "success",
                message: res.result,
              });
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
        });
      }
    },
    off(index, row) {
      let that = this;
      // 核销框
      if (this.form.number == "") {
        this.$message.error("请输入电子券码");
      } else {
        this.$confirm("将券码核销, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          // 核销
          let code = this.form.number;
          that.$api.WriteBtn(code).then((res) => {
            if (res.status == 200) {
              this.$message({
                type: "success",
                message: res.result || '核销成功~',
              });
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
        });
      }
    },

    Cardoff(index, row) {
      let that = this;
      // 核销框
      this.$confirm("将年卡编码核销, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        // 核销
        let cardAct = {
          actId: this.form.Cardact,
          cardCode: this.form.Cardnumber,
          actName: sessionStorage.getItem("actname"),
        };
        this.$api.cardWrite(cardAct).then((res) => {
          if (res.status == 200) {
            this.$message.success(res.result);
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
  },
  created() {
    this.$api.searchAct().then((res) => {
      if (res.status == 200) {
        this.msg = res.result;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
}
.head {
  display: flex;
  align-items: center;
}

.headimg {
  display: flex;
  align-items: center;
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
