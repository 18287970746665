<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">拼团列表</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增拼团商品</el-button
            >
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.actName"
                  placeholder="请输入活动名称"
                  style="width: 150px; margin-right: 30px"
                ></el-input>
                <el-select
                  v-model="form.groupStatus"
                  placeholder="团购状态"
                  style="width: 150px; margin-right: 30px"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="待加入" value="1"></el-option>
                  <el-option label="待成团" value="2"></el-option>
                  <el-option label="已成团" value="3"></el-option>
                  <el-option label="成团失败退款" value="4"></el-option>
                </el-select>
                <el-select
                  v-model="form.groupState"
                  placeholder="启用/禁用"
                  style="width: 150px; margin-right: 30px"
                >
                  <el-option label="禁用" value="0"></el-option>
                  <el-option label="启用" value="1"></el-option>
                </el-select>

                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 拼团列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-form
                      label-position="left"
                      inline
                      class="demo-table-expand"
                    >
                      <el-form-item label="拼团活动图">
                        <img
                          v-for="(item, index) in props.row.groupPic.split(',')"
                          :key="index"
                          style="width: 100px; height: 80px; margin-right: 20px"
                          class="bannerImg"
                          :src="item"
                          alt=""
                        />
                      </el-form-item>
                    </el-form>
                  </template>
                </el-table-column>
                <!-- 活动名称 -->
                <el-table-column label="活动名称" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.actName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 活动图 -->
                <!-- <el-table-column label="活动图" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img class="bannerImg" :src="scope.row.groupPic" alt="" />
                    </div>
                  </template>
                </el-table-column> -->
                <!-- 团购价格 -->
                <el-table-column label="团购价格">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.groupPrice
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 商家名称 -->
                <el-table-column label="商家名称" width="180">
                  <template slot-scope="scope">
                    <span>{{ scope.row.shopName }}</span>
                  </template>
                </el-table-column>
                <!-- 参团人数 -->
                <el-table-column label="参团人数">
                  <template slot-scope="scope">
                    <span>{{ scope.row.joinNumber }}</span>
                  </template>
                </el-table-column>
                <!-- 需求人数 -->
                <el-table-column label="需求人数">
                  <template slot-scope="scope">
                    <span>{{ scope.row.needNumber }}</span>
                  </template>
                </el-table-column>
                <!-- 团购状态 -->
                <el-table-column label="团购状态">
                  <template slot-scope="scope">
                    <span>
                      {{
                        scope.row.groupStatus == 1
                          ? "待加入"
                          : scope.row.groupStatus == 2
                          ? "待成团"
                          : scope.row.groupStatus == 3
                          ? "已成团"
                          : scope.row.groupStatus == 4
                          ? "成团失败退款"
                          : "无"
                      }}</span
                    >
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="150">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="结束时间" width="150">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.groupEnd | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" width="300">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="uptop(scope.$index, scope.row)"
                      >推首页</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="handleON(scope.$index, scope.row)"
                      >{{
                        scope.row.groupState == 0 ? "启用" : "禁用"
                      }}</el-button
                    >
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <!-- 新增的弹窗 -->
              <el-dialog title="新增拼团商品" :visible.sync="dialogFormVisible">
                <el-form :model="addForm" :rules="rules" ref="forms">
                  <el-form-item label="商品图片:">
                    <el-upload
                      ref="uploadss"
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                    >
                      <i class="">建议尺寸:390*208</i>
                    </el-upload>
                  </el-form-item>

                  <el-form-item label="亲子活动" prop="skipUrl">
                    <el-select
                      v-model="addForm.skipUrl"
                      @change="selectUnitName"
                      placeholder="请选择团购亲子活动"
                    >
                      <el-option
                        :label="item.actName"
                        :value="item.id"
                        v-for="(item, index) in actlist"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- 活动属性 -->
                  <el-form-item label="活动属性:">
                    <el-checkbox-group v-model="labelSx" @change="getLabelSx">
                      <el-checkbox
                        v-for="(item, index) in labelsSx"
                        :key="item.id"
                        :label="item"
                        :name="index.id"
                        >{{ item.attrName }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>

                  <!-- 退款 -->
                  <el-form-item label="选择退款:" prop="isReturn">
                    <el-select
                      v-model="addForm.isReturn"
                      placeholder="选择退款"
                    >
                      <el-option label="退款" value="0"></el-option>
                      <el-option label="不退款" value="1"></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- 原来价格 -->
                  <el-form-item label="原来价格:" prop="actPrice">
                    <el-input
                      v-model="addForm.actPrice"
                      style="width: 215px"
                      placeholder="填写原来价格"
                    ></el-input>
                  </el-form-item>
                  <!-- 团购价格 -->
                  <el-form-item label="团购价格:" prop="money">
                    <el-input
                      v-model="addForm.money"
                      style="width: 215px"
                      placeholder="填写拼团价格"
                    ></el-input>
                  </el-form-item>
                  <!-- 成人数量 -->
                  <el-form-item label="成人数量:" prop="adultNum">
                    <el-input
                      v-model="addForm.adultNum"
                      style="width: 215px"
                      placeholder="填写成人数量"
                    ></el-input>
                  </el-form-item>
                  <!-- 儿童数量	 -->
                  <el-form-item label="儿童数量	:" prop="childNum">
                    <el-input
                      v-model="addForm.childNum"
                      style="width: 215px"
                      placeholder="填写儿童数量"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="最低数量(成):" prop="adultmin">
                    <el-input
                      v-model="addForm.adultmin"
                      style="width: 215px"
                      placeholder="填写成人最低购买数量	"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="最低数量(童):" prop="childmin">
                    <el-input
                      v-model="addForm.childmin"
                      style="width: 215px"
                      placeholder="填写儿童最低购买数量"
                    ></el-input>
                  </el-form-item>
                  <!-- 需求人数 -->
                  <el-form-item label="需求人数:" prop="needNumber">
                    <el-input
                      type="number"
                      placeholder="填写需求人数"
                      v-model="addForm.needNumber"
                      style="width: 215px"
                    ></el-input>
                  </el-form-item>

                  <!-- 团购时间 -->
                  <el-form-item label="团购时间:">
                    <el-date-picker
                      style="width: 220px"
                      v-model="Gtime"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions1"
                    >
                    </el-date-picker>
                    <!-- <span>团购时间</span> -->
                  </el-form-item>
                  <!-- 亲子时间 -->
                  <el-form-item label="活动时间:">
                    <el-date-picker
                      v-model="time"
                      style="width: 220px"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions1"
                    >
                    </el-date-picker>
                    <div style="color: red; margin-left: 68px; font-size: 12px">
                      活动开始时间晚于团购结束时间
                    </div>
                  </el-form-item>
                  <!-- 团购描述 -->
                  <el-form-item label="团购描述:" prop="Intro">
                    <el-input
                      v-model="addForm.Intro"
                      placeholder="填写团购描述"
                    ></el-input>
                  </el-form-item>
                  <!-- 团购数量
                  <el-form-item label="团购数量:" prop="grnum">
                    <el-input
                      style="width: 220px"
                      v-model="addForm.grnum"
                      type="number"
                      placeholder="填写需要创建的团购数量"
                    ></el-input>
                  </el-form-item> -->
                  <!-- 团购简介	 -->
                  <el-form-item label="团购简介:" prop="desc">
                    <editor-bar
                      v-model="addForm.desc"
                      :isClear="isClear"
                    ></editor-bar>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 编辑的弹窗 -->
              <el-dialog
                title="编辑拼团商品"
                :visible.sync="dialogFormVisibles"
              >
                <el-form :model="editForm">
                  <el-form-item label="商品图片:">
                    <el-upload
                      ref="uploadss"
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemoves"
                      :file-list="imgUrl"
                    >
                      <i class="">建议尺寸:390*208</i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="亲子活动">
                    <el-select
                      v-model="editForm.actId"
                      @change="selectUnitName"
                      placeholder="请选择团购亲子活动"
                    >
                      <el-option
                        :label="item.actName"
                        :value="item.id"
                        v-for="(item, index) in actlist"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- 活动属性 -->
                  <el-form-item label="活动属性:">
                    <el-checkbox-group v-model="labelSx" @change="getLabelSx">
                      <el-checkbox
                        v-for="(item, index) in labelsSx"
                        :key="item.id"
                        :label="item"
                        :name="index.id"
                        >{{ item.attrName }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>

                  <!-- 退款 -->
                  <el-form-item label="选择退款:" prop="isReturn">
                    <el-select
                      v-model="editForm.isReturn"
                      placeholder="选择退款"
                    >
                      <el-option label="退款" value="0"></el-option>
                      <el-option label="不退款" value="1"></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- 原来价格 -->
                  <el-form-item label="原来价格:">
                    <el-input
                      v-model="editForm.actPrice"
                      style="width: 215px"
                      placeholder="填写原来价格"
                    ></el-input>
                  </el-form-item>
                  <!-- 团购价格 -->
                  <el-form-item label="团购价格:" prop="money">
                    <el-input
                      v-model="editForm.groupPrice"
                      style="width: 215px"
                      placeholder="填写拼团价格"
                    ></el-input>
                  </el-form-item>
                  <!-- 成人数量 -->
                  <el-form-item label="成人数量:">
                    <el-input
                      v-model="editForm.adultNum"
                      style="width: 215px"
                      placeholder="填写成人数量"
                    ></el-input>
                  </el-form-item>
                  <!-- 儿童数量	 -->
                  <el-form-item label="儿童数量	:">
                    <el-input
                      v-model="editForm.childNum"
                      style="width: 215px"
                      placeholder="填写儿童数量"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="最低数量(成):" prop="adultmin">
                    <el-input
                      v-model="editForm.adultBuy"
                      style="width: 215px"
                      placeholder="填写成人最低购买数量	"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="最低数量(童):" prop="childmin">
                    <el-input
                      v-model="editForm.childBuy"
                      style="width: 215px"
                      placeholder="填写儿童最低购买数量"
                    ></el-input>
                  </el-form-item>
                  <!-- 需求人数 -->
                  <el-form-item label="需求人数:">
                    <el-input
                      type="number"
                      placeholder="填写需求人数"
                      v-model="editForm.needNumber"
                      style="width: 215px"
                    ></el-input>
                  </el-form-item>
                  <!-- 团购时间 -->
                  <el-form-item label="团购时间:">
                    <el-date-picker
                      v-model="Gtime"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions1"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <!-- 亲子时间 -->
                  <el-form-item label="活动时间:">
                    <el-date-picker
                      v-model="time"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions1"
                    >
                    </el-date-picker>
                    <div style="color: red; margin-left: 68px; font-size: 12px">
                      活动开始时间晚于团购结束时间
                    </div>
                  </el-form-item>
                  <!-- 团购描述 -->
                  <el-form-item label="团购描述:">
                    <el-input
                      v-model="editForm.groupIntro"
                      style="width: 300px"
                    ></el-input>
                  </el-form-item>
                  <!-- 团购简介	 -->
                  <el-form-item label="团购简介:">
                    <editor-bar
                      v-model="editForm.groupDesc"
                      :isClear="isClear"
                    ></editor-bar>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog title="推首页" width="30%" :visible.sync="dialogpush">
      <el-form :model="pform">
        <el-form-item label="首页图片">
          <el-upload
            ref="uploads"
            action
            :before-upload="beforeUpload"
            :http-request="uploadimg1"
            list-type="picture-card"
            :headers="headers"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemovesindex"
            :file-list="imgUrl"
            :limit="1"
          >
            <i class="">建议尺寸:370*140</i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogpush = false">取 消</el-button>
        <el-button type="primary" @click="indexpush()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import EditorBar from "@/components/wangEnduit.vue";

export default {
  components: {
    Card,
    HeaderNav,
    EditorBar,
  },
  data() {
    return {
      imgUrl: [], //商品图片
      imgUrlEdit: [],
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      pageSizes: 999,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogVisible: false,
      dialogpush: false,
      dialogImageUrl: "",
      editForm: {},
      addForm: {
        skipUrl: "",
        isReturn: "",
        actPrice: "",
        money: "",
        adultNum: "",
        childNum: "",
        needNumber: "",
        Intro: "",
        desc: "",
      },
      form: {
        name: "",
      },
      // 表格数据
      labelSx: [],
      tableData: [],
      actlist: [],
      actinfo: {},
      typeList: [],
      time: [],
      Gtime: [],
      Status: "",
      pform: {},
      isClear: false,
      labelsSx: [],
      imgUrlsIndex: [],
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      rules: {
        adultmin: [
          {
            required: true,
            message: "请选择成人最低购买数量",
            trigger: "blur",
          },
        ],
        childmin: [
          {
            required: true,
            message: "请选择儿童最低购买数量",
            trigger: "blur",
          },
        ],
        skipUrl: [
          { required: true, message: "请选择亲子活动", trigger: "change" },
        ],
        labelSx: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动属性",
            trigger: "change",
          },
        ],
        isReturn: [
          { required: true, message: "请选择是/否退款", trigger: "change" },
        ],
        actPrice: [
          { required: true, message: "请输入原来价格", trigger: "blur" },
        ],
        // grnum: [
        //   { required: true, message: "请输人团购创建数量", trigger: "blur" },
        // ],
        money: [{ required: true, message: "请输入团购价格", trigger: "blur" }],
        adultNum: [
          { required: true, message: "请选择成人数量", trigger: "blur" },
        ],
        childNum: [
          { required: true, message: "请选择成人数量", trigger: "blur" },
        ],
        needNumber: [
          { required: true, message: "请输入需求人数", trigger: "blur" },
        ],
        Intro: [{ required: true, message: "填写团购描述", trigger: "blur" }],
        desc: [{ required: true, message: "请填写团购简介", trigger: "blur" }],
      },
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },

    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v, name: file.file.name });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    // 展示删除
    handleRemovesindex(file, fileList) {
      this.imgUrl.splice(-1);
    },
    // 放大
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleRemove(file, fileList) {
      const filePath = file.name;
      this.imgUrl.forEach((val, index) => {
        if (val.name == filePath) {
          this.imgUrl.splice(index, 1);
        }
      });
      console.log(this.imgUrl, "irrr");
    },

    handleRemoves(file, fileList) {
      const filePath = file.url;
      this.imgUrl.forEach((val, index) => {
        if (val.url == filePath) {
          this.imgUrl.splice(index, 1);
        }
      });
      console.log(this.imgUrl, "2222");
    },
    handleRemovesindex(file, fileList) {
      this.imgUrl.splice(0, 1);
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;

      // 重绘视图
      this.GroupList();
    },
    // 表格编辑删除
    handleEdit(index, row) {
      this.dialogFormVisibles = true;
      this.labelSx = [];
      console.log(index, row);
      this.editForm = row;
      row.isReturn == 1
        ? (this.editForm.isReturn = "不退款")
        : (this.editForm.isReturn = "退款");
      this.time = [row.actStart, row.actEnd];
      this.Gtime = [row.groupStart, row.groupEnd];
      this.actinfo = [];
      this.imgUrl = [];
      var imgs = [];
      imgs = row.groupPic.split(",");
      imgs.forEach((item) => {
        this.imgUrl.push({ url: item });
      });
      console.log(this.imgUrl, "12321");
      let Labelsx = row.label.split("-");
      for (let i = 0; i < this.labelsSx.length; i++) {
        for (let j = 0; j < Labelsx.length; j++) {
          if (this.labelsSx[i].attrName == Labelsx[j]) {
            this.labelSx.push(this.labelsSx[i]);
          }
        }
      }
      console.log(this.labelSx);
    },
    handleDelete(index, row) {
      console.log(index, row);

      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该团购, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        that.$api.groupDel({ id: row.id }).then((res) => {
          if (res.status == 200) {
            that.GroupList();
            that.$message.success(res.result);
          } else {
            that.$message.error(res.message);
          }
        });
      });
    },
    // 新增广告
    add() {
      this.imgUrl = [];
      this.time = [];
      this.Gtime = [];
      this.dialogFormVisible = true;
    },
    // 启用禁用
    handleON(index, row) {
      let id = row.id;
      if (row.groupState == 1) {
        this.$api.groupoff(id).then((res) => {
          if (res.status == 200) {
            this.GroupList();
            this.$message.success(res.result);
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$api.groupons(id).then((res) => {
          if (res.status == 200) {
            this.GroupList();
            this.$message.success(res.result);
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 推首页
    uptop(index, row) {
      this.imgUrl = [];
      this.pform = row;
      this.dialogpush = true;
    },
    indexpush() {
      let img = "";
      if (this.imgUrl != "") {
        this.imgUrl.forEach((val) => {
          img = val.url;
        });
      }
      let list = {
        address: img,
        id: this.pform.id,
        type: 2,
      };
      this.$api.grouppush(list).then((res) => {
        if (res.status == 200) {
          this.$message.success("推送成功");
          this.dialogpush = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    ok() {
      console.log(this.imgUrl, "1111");
      // 处理时间
      var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
      var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
      var t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      var t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;

      var time3 = this.$moment(this.Gtime[0]).format("YYYY-MM-D");
      var time4 = this.$moment(this.Gtime[1]).format("YYYY-MM-D");
      var Gt1 = (this.$moment(time3, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      var Gt2 = (this.$moment(time4, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      let listSx = [];
      this.labelSx.forEach((item) => {
        listSx.push(item.attrName);
      });

      // 处理图片
      let img = [];
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img.push(item.url);
        });
        img = img.join(",");
        console.log(img, "imgurl");
      }
      let groupInfo = {
        actEnd: t2 + 86400000,
        actId: this.addForm.skipUrl,
        actName: this.actinfo.actName,
        actPrice: this.addForm.actPrice,
        actStart: t1,
        adultNum: this.addForm.adultNum,
        catId: this.actinfo.catId,
        childNum: this.addForm.childNum,
        groupDesc: this.addForm.desc,
        groupEnd: Gt2 + 86399999, //
        groupIntro: this.addForm.Intro,
        groupPic: img,
        groupPrice: this.addForm.money,
        groupStart: Gt1,
        isReturn: this.addForm.isReturn,
        joinNumber: 0,
        adultBuy: this.addForm.adultmin,
        childBuy: this.addForm.childmin,
        needNumber: this.addForm.needNumber,
        shopId: this.actinfo.shopId,
        shopName: this.actinfo.shopName,
        label: listSx.join("-"),
      };
      let grAdd = {
        groupInfo: groupInfo,
        num: 1,
      };
      this.$refs.forms.validate((valid) => {
        if (valid) {
          if (
            this.addForm.adultmin <= this.addForm.adultNum ||
            this.addForm.childmin <= this.addForm.childNum
          ) {
            if (
              new Date(this.time[0]).getTime() >
              new Date(this.Gtime[1].getTime())
            ) {
              if (
                Number(this.addForm.adultNum) + Number(this.addForm.childNum) >=
                  2 &&
                Number(this.addForm.needNumber) >= 2
              ) {
                if (img != "") {
                  if (this.time.length != 0 && this.Gtime.length != 0) {
                    this.$api.Addgr(grAdd).then((res) => {
                      if (res.status == 200) {
                        this.$message.success(res.result);
                        this.GroupList();
                        this.dialogFormVisible = false;
                      } else {
                        this.$message.error(res.message);
                      }
                    });
                  } else {
                    this.$message.error("请添加时间");
                  }
                } else {
                  this.$message.error("请添加图片");
                }
              } else {
                this.$message.error("拼团人数或需求人数不能少于两人");
              }
            } else {
              this.$message.error("活动开始时间必须大于拼团结束时间");
            }
          } else {
            this.$message.error("儿童购买数量或成人购买数量不能大于总数量");
          }
        } else {
          console.log("error submit!!");
          return true;
        }
      });
    },
    oks() {
      console.log(this.imgUrl, "22222");
      var img = [];
      let listSx = [];

      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img.push(item.url);
        });
        img = img.join(",");
      }

      console.log(img, "imgurl");

      // 处理时间
      var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
      var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
      var t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      var t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;

      var time3 = this.$moment(this.Gtime[0]).format("YYYY-MM-D");
      var time4 = this.$moment(this.Gtime[1]).format("YYYY-MM-D");
      var Gt1 = (this.$moment(time3, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      var Gt2 = (this.$moment(time4, "YYYY-MM-DD").valueOf() / 1000) * 1000;

      if (this.editForm.isReturn == "退款") {
        this.editForm.isReturn = 0;
      } else if (this.editForm.isReturn == "不退款") {
        this.editForm.isReturn = 1;
      }
      this.labelSx.forEach((item) => {
        listSx.push(item.attrName);
      });
      let EditGr = {
        actId: this.editForm.actId,
        actName:
          this.actinfo == "" ? this.editForm.actName : this.actinfo.actName,
        catId: this.actinfo == "" ? this.editForm.catId : this.actinfo.catId,
        actStart: t1,
        actEnd: t2 + 86400000,
        groupStart: Gt1,
        groupEnd: Gt2 + 86399999,
        adultNum: this.editForm.adultNum,
        childNum: this.editForm.childNum,
        groupDesc: this.editForm.groupDesc,
        groupPic: img,
        groupPrice: this.editForm.groupPrice,
        isReturn: this.editForm.isReturn,
        joinNumber: 0,
        childBuy: Number(this.editForm.childBuy),
        adultBuy: Number(this.editForm.adultBuy),
        needNumber: this.editForm.needNumber,
        shopId: this.actinfo == "" ? this.editForm.shopId : this.actinfo.shopId,
        shopName:
          this.actinfo == "" ? this.editForm.shopName : this.actinfo.shopName,
        id: this.editForm.id,
        groupIntro: this.editForm.groupIntro,
        label: listSx.join("-"),
      };
      if (
        Number(this.editForm.adultNum) + Number(this.editForm.childNum) < 2 ||
        Number(this.editForm.needNumber) < 2
      ) {
        this.$message.error("拼团人数或需求人数不能少于两人");
      } else if (this.time[0] < this.Gtime[1]) {
        this.$message.error("活动开始时间必须大于拼团结束时间");
      } else if (
        this.addForm.adultmin <= this.addForm.adultNum ||
        this.addForm.childmin <= this.addForm.childNum
      ) {
        this.$message.error("儿童购买数量或成人购买数量不能大于总数量");
      } else {
        this.$api.EditGr(EditGr).then((res) => {
          if (res.status == 200) {
            this.dialogFormVisibles = false;
            this.$message.success(res.result);
            this.GroupList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    search() {
      let List = {
        page: this.currPage,
        pageSize: this.pageSize,
        actName: this.form.actName,
        groupStatus: this.form.groupStatus,
        groupState: Number(this.form.groupState),
      };
      this.$api.groupOn(List).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    GroupList() {
      let Group = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.groupOn(Group).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    selectUnitName(e) {
      let obj = {};
      this.actlist.map((item) => {
        //  this.unitList 是你 select option遍历的集合 e 是选中的id
        if (item.id === e) {
          obj = item;
        }
      });
      this.actinfo = obj;
      console.log(this.actinfo);
    },
    getLabelSx(item) {
      this.labelValueSx = item;
      console.log(item, "item");
    },
    act() {
      this.$api.Searchgroup().then((res) => {
        if (res.status == 200) {
          this.actlist = res.result;
        }
      });
    },
    labelSxlist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 1,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labelsSx = res.result.records;
        }
      });
    },
  },
  created() {
    this.GroupList();
    this.act();
    this.labelSxlist();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 180px;
    height: 100px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>