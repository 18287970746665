<template>
  <div id="app" >
      
       <!-- 定义路由出口 -->
       <router-view></router-view>
     
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>
#app{
  width: 100%;
  height: 100%;
  /deep/ .el-upload--picture-card i{
    font-size: 14px;
  }
}
</style>