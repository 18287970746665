
<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">修改密码</span>
          <div slot="content">
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="账户" prop="phone">
                <el-input
                  type="text"
                  v-model="ruleForm.phone"
                  autocomplete="off"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="Pwd">
                <el-input
                  type="text"
                  v-model="ruleForm.Pwd"
                  autocomplete="off"
                  style="width: 220px"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入acc
// 引入local
import local from "@/utils/local";
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      id: "",
      msg: {},
      ruleForm: {
        phone: "",
        Pwd: "",
      },
      rules: {
        phone: [{ required: true, message: "请输入新的账户", trigger: "blur" }],
        Pwd: [
          { required: true, message: "请输入新的密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm() {
      // this.id = localStorage.getItem("id");
      this.msg = JSON.parse(localStorage.getItem("loginMsg"));
      let usermsg = {
        id: this.msg.loginUser.id,
        phone: this.msg.loginUser.phone,
        password: this.ruleForm.Pwd,
        name: this.msg.loginUser.name,
        username: this.ruleForm.phone,
      };
      this.$api.editPWd(usermsg).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
        } else {
          this.$message.success(res.result);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.add {
  background: #f0f2f5;
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    .rigth-top {
      width: 100%;
      height: 65px;
      background: #fff;
    }
    .rigth-bottom {
      flex: 1;
      margin: 20px;
    }
  }
}
</style>
