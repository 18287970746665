<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">记录管理</span>

          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增记录</el-button
            >
            <!-- 记录列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- <el-table-column label="记录图" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img class="bannerImg" :src="scope.row.head" alt="" />
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column label="内容" width="200">
                  <template slot-scope="scope">
                    <span>{{ scope.row.time }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDel(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <!-- 编辑的弹窗 -->
              <el-dialog title="编辑列表" :visible.sync="dialogFormVisible">
                <el-form ref="editForm" :model="editForm">
                  <!-- <el-form-item label="记录编辑:">
                    <el-upload
                      action="https://admin.bbtedu03.com:444/product/file_upload/uploadImg"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemovesindex"
                      :on-success="shopheadersuccessIndex"
                      :default-value="timeData"
                      :file-list="imgUrlsIndex"
                      :limit="1"
                    >
                      <i class=""></i>
                  
                    </el-upload>
                  </el-form-item> -->

                  <el-form-item label="记录内容:">
                    <el-input v-model="editForm.time"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>

              <!-- 新增记录的弹窗 -->
              <el-dialog title="新增记录" :visible.sync="dialogFormVisibles">
                <el-form :model="addForm">
                  <!-- <el-form-item label="记录头像:">
                    <el-upload
                      ref="upload"
                      action="https://admin.bbtedu03.com:444/product/file_upload/uploadImg"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemovesindexs"
                      :on-success="shopheadersuccessIndexs"
                      :default-value="timeData"
                      :limit="1"
                    >
                      <i class=""></i>
                      <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                      </el-dialog>
                    </el-upload>
                  </el-form-item> -->

                  <el-form-item label="记录内容:">
                    <el-input v-model="addForm.time"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  data() {
    return {
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      editForm: {},
      addForm: { time: "" },
      dialogImageUrl: "",
      dialogVisible: false,
      // 表格数据
      tableData: [],
      time: "",
      timeData: "",
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      imgUrlsIndexs: "",
      imgUrlsIndex: [],
      img: "",
    };
  },
  created() {
    this.recordList();
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.recordList();
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogFormVisible = true;
      this.imgUrlsIndex = [];
      this.imgUrlsIndex.push({ url: row.url });
      this.editForm = row;
    },
    handleDel(index, row) {
      let that = this;
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let id = row.id;
          that.$api.Delrecord(id).then((res) => {
            if (res.status == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              that.recordList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    add() {
      this.dialogFormVisibles = true;
    },
    // 记录列表
    recordList() {
      let list = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.record(list).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          // this.tableData.forEach((item) => {
          //   console.log(item.time);
          // });
          this.total = res.result.total;
        }
      });
    },
    ok() {
      if (this.addForm.time === "") {
        this.$message.error("请输入记录内容!");
        return;
      }
      var moment = require("moment");
      moment().format();
      let add = {
        // head: this.imgUrlsIndexs,
        time: this.addForm.time,
      };
      this.$api.Addrecord(add).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibles = false;
          this.$message.success(res.result);
          this.addForm.time = "";
          this.recordList();
        }
      });
      // console.log(moment(this.time).format("YYYY-MM-DD"))
    },

    oks() {
      let editrecord = {
        id: this.editForm.id,
        // head: this.img,
        time: this.editForm.time,
      };
      this.$api.Editrecord(editrecord).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisible = false;
          this.$message.success("修改成功");
        }
      });
    },
    // 放大
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 新增单图删除
    handleRemovesindexs(file, fileList) {
      this.imgUrlsIndexs.splice(0, 1);
    },
    // 新增单图
    shopheadersuccessIndexs(file) {
      this.imgUrlsIndexs = file.result;
      console.log(this.imgUrlsIndexs);
    },
    // 编辑单图删除
    handleRemovesindex(file, fileList) {
      this.imgUrlsIndex.splice(0, 1);
    },
    // 编辑单图
    shopheadersuccessIndex(file) {
      this.imgUrlsIndex = [];
      this.imgUrlsIndex.push(file.result);
      console.log(this.imgUrlsIndex);
      this.img = file.result;
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>