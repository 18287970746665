<template>
  <div class="content">
    <!-- 右上 面包屑-->
    <div class="rigth-top">
      <HeaderNav> </HeaderNav>
    </div>
    <!-- 右下 -->
    <div class="rigth-bottom">
      <Card>
        <span slot="title">{{ useMsg.name }}</span>
        <div slot="content" class="contentbox">
          <el-form ref="form" :model="form" label-width="100px">
            <editor-bar
              v-model="form.description"
              :isClear="isClear"
              @change="change"
            ></editor-bar>
          </el-form>

          <div class="cut">
            <el-button type="primary" size="medium" @click="edit">{{
              disabled == true ? "编辑/修改" : "保存"
            }}</el-button>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
// 引入卡片
// 引入面包屑
import HeaderNav from "@/components/HeaderNav.vue";
import Card from "@/components/Card.vue";
import EditorBar from "@/components/wangEnduit.vue";
export default {
  name: "WriteArticle",
  components: {
    HeaderNav,
    Card,
    EditorBar,
  },
  data() {
    return {
      useMsg: {},
      isClear: false,
      disabled: false,
      form: {
        description: "",
      },
    };
  },
  methods: {
    getText(name, val) {
      let id = {
        id: "1",
      };
      this.$api.manager(id).then((res) => {
        if (res.status == 200) {
          this.useMsg = res.result;
          this.form.description = this.useMsg.content;
        }
      });
    },
    edit() {
      if (this.disabled == true) {
        this.disabled = false;
      }
      let updata = {
        content: this.form.description,
        id: 1,
      };
      if (this.text != this.useMsg.content) {
        this.$api.update(updata).then((res) => {
          if (res.status == 200) {
            this.disabled = true;
          }
        });
      }
    },
    change(val) {
      console.log(val, "......................");
    },
  },
  created() {
    this.getText();
  },
};
</script>

<style lang="less" scoped>
.content {
  .contentbox {
    display: flex;
    div {
      flex: 1;
      height: 300px;
    }
    .tip {
      font-size: 40px;
      text-align: center;
      line-height: 300px;
    }
    .card {
      border: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      letter-spacing: 1px;
    }
    .cut {
      text-align: center;
      line-height: 300px;
    }
  }
}
</style>