<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">首页活动</span>
          <div slot="content">
            <!-- 商家列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%" v-if="type == 1">
                <!-- <el-table-column prop="actDesc" label="活动ID" width="180">
                </el-table-column> -->
                <el-table-column prop="actName" label="活动名称">
                  <template slot-scope="scope">
                    <span>{{ scope.row.seckInfo[0].actName }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="img" label="活动广告图" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img class="bannerImg" :src="scope.row.address" alt="" />
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="shopName" label="类型">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.type == 2 ? "秒杀" : "团购" }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="childPrice" label="现价">
                  <template slot-scope="scope">
                    <span>{{ scope.row.seckInfo[0].childPrice }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="adultPrice" label="原价">
                  <template slot-scope="scope">
                    <span>{{ scope.row.seckInfo[0].adultPrice }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="childStock" label="库存">
                  <template slot-scope="scope">
                    <span>{{ scope.row.seckInfo[0].childStock }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-table :data="grData" style="width: 100%" v-if="type == 2">
                <!-- <el-table-column prop="actDesc" label="活动ID" width="180">
                </el-table-column> -->
                <el-table-column prop="actName" label="活动名称" width="180">
                  <template slot-scope="scope">
                    <span>{{ scope.row.groupInfo[0].actName }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="img" label="活动广告图" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img class="bannerImg" :src="scope.row.address" alt="" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="儿童">
                  <template slot-scope="scope">
                    <span> {{ scope.row.groupInfo[0].childNum }}人 </span>
                  </template>
                </el-table-column>
                <el-table-column label="成人">
                  <template slot-scope="scope">
                    <span> {{ scope.row.groupInfo[0].adultNum }}人 </span>
                  </template>
                </el-table-column>
                <el-table-column label="拼团价格">
                  <template slot-scope="scope">
                    <span> {{ scope.row.groupInfo[0].groupPrice }}元</span>
                  </template>
                </el-table-column>
                <el-table-column prop="shopName" label="类型">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.type == 2 ? "秒杀" : "团购" }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 表格数据
      tableData: [],
      grData: [],
      type: "",
    };
  },
  computed: {},
  methods: {
    topactList() {
      this.$api.pushSearch().then((res) => {
        if (res.status == 200) {
          if (res.result[0].type == 1) {
            this.type = 1;
            this.tableData = res.result;
          } else if (res.result[0].type == 2) {
            this.type = 2;
            this.grData = res.result;
          }
          console.log(this.tableData);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.topactList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>