<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">订单列表</span>
          <div slot="content">
            <!-- <el-button @click="NOpayment">待付款</el-button>
            <el-button @click="Tobeopened">待开团</el-button>
            <el-button @click="Completed">已完成</el-button>
            <el-button @click="lose">失败团</el-button> -->
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.actName"
                  placeholder="请输入活动名称"
                  style="width: 200px; margin-right: 30px"
                ></el-input>
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>

                <el-select
                  v-model="form.buyType"
                  placeholder="活动类型"
                  style="margin-left: 30px"
                >
                  <el-option label="个体" value="1"></el-option>
                  <el-option label="拼团" value="2"></el-option>
                </el-select>
                <el-select
                  v-model="form.status"
                  placeholder="活动状态"
                  style="margin-left: 30px; margin-right: 30px"
                >
                  <el-option label="全部" value="null"></el-option>
                  <el-option label="待付款" value="1"></el-option>
                  <el-option label="待使用" value="2"></el-option>
                  <el-option label="已完成" value="4"></el-option>
                  <el-option label="取消" value="5"></el-option>
                  <el-option label="待开团" value="6"></el-option>
                  <el-option label="团购失败" value="7"></el-option>
                  <el-option label="已过期" value="8"></el-option>
                  <el-option label="申请退款" value="9"></el-option>
                  <el-option label="已退款" value="10"></el-option>
                </el-select>
                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 订单列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 订单ID -->
                <!-- <el-table-column label="订单ID" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.id }}</span>
                  </template>
                </el-table-column> -->
                <!-- 用户名称 -->
                <el-table-column label="用户名称" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.username
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 活动名称 -->
                <el-table-column label="活动名称" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.orderItem.actName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 所属商家 -->
                <el-table-column label="所属商家" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.shopName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 商家广告图 -->
                <el-table-column label="活动图" width="250">
                  <template slot-scope="scope">
                    <div>
                      <img
                        class="bannerImg"
                        :src="scope.row.orderItem.actPic"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 电子券码 -->
                <el-table-column label="电子券码" width="155">
                  <template slot-scope="scope">
                    <span>{{ scope.row.orderItem.actCode }}</span>
                  </template>
                </el-table-column>
                <!-- 活动类型 -->
                <el-table-column label="活动类型" width="150">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.buyType == "1" ? "个体" : "拼团"
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 数量 -->
                <el-table-column label="数量" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.orderItem.quantity }}</span>
                  </template>
                </el-table-column>
                <!-- 活动状态 -->
                <el-table-column label="活动状态" width="150">
                  <template slot-scope="scope">
                    {{
                      scope.row.status == 1
                        ? "待付款"
                        : scope.row.status == 2
                        ? "待使用"
                        : scope.row.status == 4
                        ? "已完成"
                        : scope.row.status == 5
                        ? "取消 "
                        : scope.row.status == 6
                        ? "待开团"
                        : scope.row.status == 7
                        ? "团购失败"
                        : scope.row.status == 8
                        ? "已过期"
                        : scope.row.status == 9
                        ? "申请退款"
                        : scope.row.status == 10
                        ? "已退款"
                        : ""
                    }}
                  </template>
                </el-table-column>
                <!-- 价格 -->
                <el-table-column label="价格" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.payAmount }}</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="150">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>

                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                   <el-button size="mini" type="success" v-if=" (scope.row.actType===1 || scope.row.actType===2 || scope.row.actType===5) && scope.row. isHave" @click="get_insure(scope.row)">查看保险</el-button>
                    <el-button
                      type="primary"
                      style="margin-left: 0;margin-top: 10px"
                      size="mini"
                      @click="see(scope.$index, scope.row)"
                    >
                      {{
                        scope.row.buyType == "1"
                          ? "查看用户"
                          : scope.row.buyType == "2"
                          ? "开团人数"
                          : "无"
                      }}</el-button
                    >
                    <el-button
                        style="margin-left: 0"
                      size="mini"
                      type="primary"
                      v-if="scope.row.status == 2"
                      @click="trueReture(scope.$index, scope.row)"
                      >退款</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 查看用户 -->
              <el-dialog title="查看用户" :visible.sync="dialogFormVisible">
                <el-form :model="typeForm">
                  <div class="head">
                    用户头像：
                    <div class="headimg">
                      <img :src="typeForm.header" alt="" />
                    </div>
                  </div>
                  <el-form-item label="用户昵称:">
                    <el-input
                      v-model="typeForm.username"
                      disabled
                    ></el-input> </el-form-item
                  ><el-form-item label="电话号码:">
                    <el-input v-model="typeForm.mobile" disabled></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >返 回</el-button
                  >
                </div>
              </el-dialog>
              <!-- 开团人数 -->
              <el-dialog title="开团人数" :visible.sync="dialogFormVisibles">
                <div class="head">
                  <div class="headimg" v-for="(item, i) in memberList" :key="i">
                    <img class="img" :src="item.header" alt="" />
                    <div class="names">{{ item.userName }}</div>
                  </div>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >返 回</el-button
                  >
                </div>
              </el-dialog>
              <!-- 退款备注 -->
              <el-dialog title="退款备注" :visible.sync="dialogContent">
                <el-form :model="form" label-width="80px">
                  <el-form-item label="退款备注">
                    <el-input type="textarea" v-model="content"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="no">返 回</el-button>
                  <el-button @click="ok">确认</el-button>
                </div>
              </el-dialog>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

   <!--  查看保险信息  -->
    <el-dialog
        title="保险信息"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="()=>{this.dialogVisible=false}">
      <div>
        <el-table
            :data="list"
            style="width: 100%">
          <el-table-column
              prop="orderSn"
              label="订单编号">
          </el-table-column>
          <el-table-column
              prop="infoName"
              label="投保人姓名">
          </el-table-column>
          <el-table-column
              prop="idCard"
              label="身份证号">
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      dialogVisible:false,
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogContent: false,
      typeForm: {},
      content: "",
      form: {
        name: "",
      },
      // 时间数据
      time: "",
      // 表格数据
      tableData: [],
      btn: "primary",
      btns: "info",
      memberList: [],
      t1: null,
      t2: null,
      list:[]
    };
  },
  created() {
    this.orderList();
  },
  methods: {
    get_insure(row){
      this.dialogVisible = true;
      this.$api.get_insureInfo({orderSn:row.orderSn}).then(res=>{
        this.list = res.result;
      });
    },
    Grouplist() {
      let Grouplist = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.Grouplist(Grouplist).then((res) => {
        if (res.status == 200) {
          // this.tableData = res.result.records;
          // console.log(this.tableData);
          // this.total = res.result.total;
        }
      });
    },
    // 退款按钮
    trueReture(index, row) {
      (this.dialogContent = true),
        sessionStorage.setItem("ordersn", row.orderSn);
    },
    ok() {
      let btn = {
        orderSn: sessionStorage.getItem("ordersn"),
        refundContent: this.content,
      };
      this.$api.returnIn(btn).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.content = "";
          this.orderList()
          this.dialogContent = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    no() {
      this.dialogContent = false;
      this.content = "";
    },
    // 搜索
    search() {
      // console.log(t1, t2);
      let that = this;
      var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
      var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
      // var t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      // var t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      if (this.time.length == 2) {
        that.t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
        that.t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      }
      let orderList = {
        actName: this.form.actName,
        buyType: this.form.buyType,
        startTime: this.t1,
        endTime: this.t2,
        status: Number(this.form.status),
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.orderList(orderList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    // 查看详情
    see(index, row) {
      console.log(row);
      if (row.buyType == "1") {
        this.dialogFormVisible = true;
      } else if (row.buyType == "2") {
        let member = row.groupSn;
        this.$api.Gpmember(member).then((res) => {
          if (res.status == 200) {
            this.memberList = res.result;
          }
        });

        this.dialogFormVisibles = true;
      }
      this.typeForm = row;
    },

    on(index, row) {},
    // 分页函数
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.search();
    },
    orderList() {
      let orderList = {
        page: this.currPage,
        pageSize: this.pageSize,
        status: null,
      };
      this.$api.orderList(orderList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--primary {
  margin-bottom: 10px;
}

.table {
  margin-top: 20px;
  .bannerImg {
    width: 150px;
    height: 100px;
  }

  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
.headimg {
  display: flex;
  align-items: center;
}
.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
.names {
}
</style>
