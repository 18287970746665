// 引入ali-oss
let OSS = require('ali-oss')
/**
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
 */
export function client() {//data后端提供数据
  return new OSS({
    region: 'oss-cn-chengdu',
    accessKeyId: 'LTAI5tKQXSX4AKCa2AyNY5es',
    accessKeySecret: 'SCZ034mMGJnbhV1TG9SOFQmHmDE3Hy',
    bucket: 'bbt-img-store'
  })
}

/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}
    //  //注意哦，这里指定文件夹'image/'，尝试过写在配置文件，但是各种不行，写在这里就可以
    //   var fileName =
    //     "bbt-img-store/" + "img" + `${Date.parse(new Date())}` + ".jpg";
    //   //定义唯一的文件名，打印出来的uid其实就是时间戳
    //   this.$client()
    //     .multipartUpload(fileName, file.file, {
    //       progress: function (percentage, cpt) {
    //         console.log("打印进度", percentage);
    //       },
    //     })
    //     .then((res) => {
    //       //此处赋值，是相当于上传成功之后，手动拼接服务器地址和文件名
    //       this.imgUrl.push(
    //         "http://bbt-img-store.oss-cn-chengdu.aliyuncs.com/" + fileName
    //       );
    //       console.log(this.imgUrl,'11111111111111111');
    //     });