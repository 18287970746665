<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">热门搜索列表</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增热门搜索</el-button
            >
            <!-- 搜索列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 图片名称 -->
                <el-table-column label="热门名称">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.hotName
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="热门类型">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.hotType == 1 ? "商家" : "活动"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="列表类型">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.listType == 1 ? "排行" : "搜索"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="排序">
                  <template slot-scope="scope" v-if="scope.row.listType == 1">
                    <span style="margin-left: 10px">{{ scope.row.sort }}</span>
                  </template>
                </el-table-column>
                <!-- 轮播图 -->
                <el-table-column label="热门图" width="300">
                  <template slot-scope="scope">
                    <div>
                      <img
                        class="bannerImg"
                        style="width: 80px; height: 80px"
                        :src="scope.row.hotPic"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
              <!-- 新增的弹窗 -->
              <el-dialog title="新增热门搜索" :visible.sync="dialogFormVisible">
                <el-form :model="addForm">
                  <el-upload
                    ref="upload"
                    action
                    :before-upload="beforeUpload"
                    :http-request="uploadimg1"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :limit="1"
                  >
                    <i class="">建议尺寸:117*67</i>
                  </el-upload>
                  <!-- <div style="margin: 20px 0; color: red; font-size: 14px">
                  </div> -->
                  <el-form-item label="热门类型:">
                    <el-radio v-model="radio" label="1">商家</el-radio>
                    <el-radio v-model="radio" label="2">活动</el-radio>
                  </el-form-item>
                  <el-form-item label="活动跳转地址" v-if="radio == 2">
                    <el-select
                      ref="nationalityIdSel"
                      v-model="addForm.actUrl"
                      placeholder="请选择跳转的活动"
                    >
                      <el-option
                        :label="item.actName"
                        :value="item.id"
                        v-for="(item, index) in actlist"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="商家跳转地址" v-if="radio == 1">
                    <el-select
                      ref="nationalityIdSel"
                      v-model="addForm.shopUrl"
                      placeholder="请选择跳转的商家"
                    >
                      <el-option
                        :label="item.shopName"
                        :value="item.id"
                        v-for="(item, index) in shops"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="列表类型:">
                    <el-radio v-model="radios" label="1">排行</el-radio>
                    <el-radio v-model="radios" label="2">搜索</el-radio>
                  </el-form-item>
                  <el-form-item label="排序:">
                    <el-input v-model="addForm.sort"></el-input>
                  </el-form-item>
                  <el-form-item label="热门描述:">
                    <el-input v-model="addForm.desc"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="ok">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 编辑的弹窗 -->
              <el-dialog
                title="编辑热门搜索"
                :visible.sync="dialogFormVisibles"
              >
                <el-form :model="editForm">
                  <el-upload
                    action
                    :before-upload="beforeUpload"
                    :http-request="uploadimg1"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :limit="1"
                    :file-list="imgUrl"
                  >
                    <i class="">建议尺寸:117*67</i>
                  </el-upload>
                  <!-- <div style="margin: 20px 0; color: red; font-size: 14px">
                    建议图片尺寸:117*67
                  </div> -->
                  <el-form-item label="热门类型:">
                    <el-radio v-model="radio" label="1">商家</el-radio>
                    <el-radio v-model="radio" label="2">活动</el-radio>
                  </el-form-item>
                  <el-form-item label="活动跳转地址" v-if="radio == 2">
                    <el-select
                      @change="AddSelectDept"
                      v-model="editForm.actUrl"
                      placeholder="请选择跳转的活动"
                    >
                      <el-option
                        :label="item.actName"
                        :value="item.id"
                        v-for="(item, index) in actlist"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="商家跳转地址" v-if="radio == 1">
                    <el-select
                      @change="AddSelectDept"
                      v-model="editForm.shopUrl"
                      placeholder="请选择跳转的商家"
                    >
                      <el-option
                        :label="item.shopName"
                        :value="item.id"
                        v-for="(item, index) in shops"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="列表类型:">
                    <el-radio v-model="radios" label="1">排行</el-radio>
                    <el-radio v-model="radios" label="2">搜索</el-radio>
                  </el-form-item>
                  <el-form-item label="排序:">
                    <el-input v-model="editForm.sort"></el-input>
                  </el-form-item>
                  <el-form-item label="热门描述:">
                    <el-input v-model="editForm.hotDesc"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      imgUrl: [], //商品图片
      img: "",
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogVisible: false,
      dialogImageUrl: "",
      editForm: {},
      addForm: {},
      form: {
        name: "",
      },
      // 表格数据
      tableData: [],
      actlist: [],
      shops: [],
      radio: "",
      radios: "",
      name: "",
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },
    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({ url: v.split("?")[0] });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove() {
      this.imgUrl.splice(0, 1);
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;

      // 重绘视图
      this.hotsearchList();
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(index, row);
      this.editForm = row;
      this.imgUrl = [];
      this.imgUrl.push({ url: row.hotPic });
      this.img = row.hotPic;
      this.dialogFormVisibles = true;
    },
    handleDelete(index, row) {
      console.log(index, row);

      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该搜索, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.id;
        that.$api.hotsearchDel({ id: id }).then((res) => {
          if (res.status == 200) {
            that.hotsearchList();
          }
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.hotsearchList();
      });
    },
    AddSelectDept(val) {
      let obj = {};
      if (this.radio == 1) {
        obj = this.shops.find((item) => {
          return item.id === val;
        });
        this.name = obj.shopName;
        console.log(this.name);
      } else if (this.radio == 2) {
        obj = this.actlist.find((item) => {
          return item.id === val;
        });
        this.name = obj.actName;
        console.log(this.name);
      }
    },
    // 新增
    add() {
      this.dialogFormVisible = true;
    },
    ok() {
      let hotid = "";
      let img = "";
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img = item.url;
        });
        console.log(img, "imgUrl");
      }
      if (this.radio == 1) {
        hotid = this.addForm.shopUrl;
      } else if (this.radio == 2) {
        hotid = this.addForm.actUrl;
      }
      console.log(this.addForm.actUrl);
      let Add = {
        hotName: this.$refs.nationalityIdSel.selectedLabel,
        hotId: hotid,
        hotPic: img,
        hotType: this.radio,
        listType: this.radios,
        hotDesc: this.addForm.desc,
        sort: this.addForm.sort,
      };
      this.$api.hotsearchAdd(Add).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.addForm = { brand_right: 0 };
          this.$refs.upload.clearFiles();
          this.hotsearchList();
          this.dialogFormVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    oks() {
      let hotid = "";
      let img = "";
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img = item.url;
        });
        console.log(img, "imgUrl");
      }
      if (this.radio == 1) {
        hotid = this.editForm.shopUrl;
      } else if (this.radio == 2) {
        hotid = this.editForm.actUrl;
      }

      let Edit = {
        hotName: this.name,
        hotId: hotid,
        hotPic: img,
        hotType: this.radio,
        listType: this.radios,
        hotDesc: this.editForm.hotDesc,
        sort: this.editForm.sort,
        id: this.editForm.id,
      };

      this.$api.hotsearchEdit(Edit).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibles = false;
          this.$message.success(res.result);
          this.hotsearchList();
        }
      });
    },
    hotsearchList() {
      let list = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.hotsearchList(list).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    shopList() {
      let list = {
        page: this.currPage,
        pageSize: 999,
        status: 1,
      };
      this.$api.searchShop(list).then((res) => {
        if (res.status == 200) {
          this.shops = res.result;
        }
      });
    },
    act() {
      let list = {
        page: this.currPage,
        pageSize: 999,
        pubStatus: 1,
      };
      this.$api.activityList(list).then((res) => {
        if (res.status == 200) {
          this.actlist = res.result.records;
        }
      });
    },
  },
  created() {
    this.hotsearchList();
    this.act();
    this.shopList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 250px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>