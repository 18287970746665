<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">会员列表</span>
          <div slot="content">
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.name"
                  placeholder="请输入会员名"
                  style="width: 250px; margin-right: 30px"
                ></el-input>
                <el-select
                  v-model="form.region"
                  placeholder="活动状态"
                  style="margin-right: 30px"
                >
                  <el-option label="通过" value="2"></el-option>
                  <el-option label="驳回" value="3"></el-option>
                  <el-option label="审核中" value="1"></el-option>
                </el-select>
                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 会员列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 会员名称 -->
                <el-table-column label="会员名称" width="100">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <!-- 会员头像 -->
                <el-table-column label="会员头像" width="100">
                  <template slot-scope="scope">
                    <div>
                      <img
                        class="bannerImg"
                        style="width: 50px; height: 50px"
                        :src="scope.row.headImg"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 身份证号 -->
                <el-table-column label="身份证号" width="180">
                  <template slot-scope="scope">
                    <span>{{ scope.row.idCard }}</span>
                  </template>
                </el-table-column>
                <!-- 电话号 -->
                <el-table-column label="电话号" width="120">
                  <template slot-scope="scope">
                    <span>{{ scope.row.mobile }}</span>
                  </template>
                </el-table-column>
                <!-- 年卡区域 -->
                <!-- <el-table-column label="年卡区域" width="150">
                  <template slot-scope="scope">
                    <span>{{ scope.row.region }}</span>
                  </template>
                </el-table-column> -->
                <!-- 电子券码 -->
                <!-- <el-table-column label="电子券码" width="200">
                  <template slot-scope="scope">
                    <span>{{ scope.row.cardCode }}</span>
                  </template>
                </el-table-column> -->

                <el-table-column label="年卡地址" width="80">
                  <template slot-scope="scope">
                      <span>{{ scope.row.address }}</span>
                  </template>
                </el-table-column>

                <!-- 状态 -->
                <el-table-column label="状态" width="60">
                  <template slot-scope="scope">
                    <span>
                      {{
                        scope.row.state == 1
                          ? "审核中"
                          : scope.row.state == 2
                          ? "通过"
                          : scope.row.state == 3
                          ? "驳回"
                          : "再次申请"
                      }}</span
                    >
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.state == 1"
                      size="mini"
                      type="primary"
                      @click="adopt(scope.$index, scope.row)"
                      >通过</el-button
                    >
                    <el-button
                      v-if="scope.row.state == 1"
                      size="mini"
                      type="primary"
                      @click="reject(scope.$index, scope.row)"
                      >驳回</el-button
                    >
                    <el-button
                      v-if="scope.row.state != 1"
                      size="mini"
                      type="primary"
                      @click="returnV(scope.$index, scope.row)"
                      >重置审核</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      form: {},
      // 表格数据
      tableData: [],
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  created() {
    this.userList();
  },
  methods: {
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.search();
    },
    // 通过
    adopt(index, row) {
      console.log(index, row);
      if (row.state == 3) {
        this.$message("审核状态为拒绝");
      } else if (row.state == 2) {
        this.$message("已是通过状态");
      } else if (row.state == 1) {
        let examine = {
          id: row.id,
          state: 2,
          note: "dsadadsad",
        };
        this.$api.examine(examine).then((res) => {
          if (res.status == 200) {
            this.userList();
          }
        });
      }
    },
    // 驳回
    reject(index, row) {
      console.log(index, row);
      if (row.state == 2) {
        this.$message("审核状态为通过");
      } else if (row.state == 2) {
        this.$message("已是驳回状态");
      } else if (row.state == 1) {
        let examine = {
          id: row.id,
          state: 3,
        };
        this.$api.examine(examine).then((res) => {
          if (res.status == 200) {
            this.userList();
          }
        });
      }
    },
    returnV(index, row) {
      let that = this;
      // 删除框
      this.$confirm("此操作将重置状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        that.$api.returninit({ id: row.id }).then((res) => {
          if (res.status == 200) {
            that.userList();
            that.$message.success(res.result);
          } else {
            that.$message.error(res.message);
          }
        });
      });
    },
    handleDelete(index, row) {
      let that = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = row.id;
        that.$api.examineDel(id).then((res) => {
          if (res.status == 200) {
            that.userList();
            that.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    // 列表
    userList() {
      let userList = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.userList(userList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    search() {
      let userList = {
        name: this.form.name,
        state: this.form.region,
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.userList(userList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    add() {
      this.dialogFormVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 200px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>
