//1.引入两个核心文件
import Vue from 'vue'
import VueRouter from 'vue-router'
// 引入local
import local from "@/utils/local";
// 2.需要在vue实例中，注入vue-router
Vue.use(VueRouter)
// 引入组件
import Login from '@/views/Login.vue'
import LayOut from '@/views/LayOut.vue'

// 定义自己的路由
export const asyncRoutes = [
  // 在数组中配置自己的路由
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login,
    isshow: false,
  },

  // -------------------------------------------------------------------------------------------------------
  // 平台管理
  {
    path: '/platform',
    component: LayOut,
    meta: { title: "平台管理" },
    isshow: true,
    redirect: '/platform/banner',
    icon: 'el-icon-shopping-bag-1',
    children: [
      { path: 'banner', component: () => import('@/views/platform/Banner.vue'), meta: { title: "banner管理" }, isshow: true }, //banner管理
      { path: 'use', component: () => import('@/views/platform/Use.vue'), meta: { title: "年卡使用须知" }, isshow: true }, //年卡使用须知
      { path: 'purchase', component: () => import('@/views/platform/Purchase.vue'), meta: { title: "年卡购买须知" }, isshow: true }, //年卡使用须知
      { path: 'about', component: () => import('@/views/platform/About.vue'), meta: { title: "关于我们" }, isshow: true }, //关于我们
      { path: 'service', component: () => import('@/views/platform/Service.vue'), meta: { title: "联系客服" }, isshow: true }, //联系客服
      { path: 'notice', component: () => import('@/views/platform/Notice.vue'), meta: { title: "平台公告" }, isshow: true }, //平台公告
      { path: 'agreement', component: () => import('@/views/platform/Agreement.vue'), meta: { title: "服务协议" }, isshow: true }, //服务协议
      { path: 'problem', component: () => import('@/views/platform/Problem.vue'), meta: { title: "常见问题" }, isshow: true }, //常见问题
      { path: 'indeximg', component: () => import('@/views/platform/Indeximg.vue'), meta: { title: "首页图片" }, isshow: true }, //首页图片
      { path: 'tourtip', component: () => import('@/views/platform/Tourtip.vue'), meta: { title: "旅游提示" }, isshow: true }, //旅游提示
      { path: 'cancel', component: () => import('@/views/platform/Cancel.vue'), meta: { title: "退订政策" }, isshow: true }, //退订政策
      { path: 'contract', component: () => import('@/views/platform/Contract.vue'), meta: { title: "合同范本" }, isshow: true }, //合同范本
      { path: 'reserve', component: () => import('@/views/platform/Reserve.vue'), meta: { title: "预定须知" }, isshow: true }, //预定须知
      { path: 'safe', component: () => import('@/views/platform/Safe.vue'), meta: { title: "安全提示" }, isshow: true }, //安全提示
      { path: 'qzact', component: () => import('@/views/platform/Qzact.vue'), meta: { title: "亲子活动说明" }, isshow: true }, //亲子活动说明
      { path: 'ptact', component: () => import('@/views/platform/Ptact.vue'), meta: { title: "普通活动说明" }, isshow: true }, //普通活动说明
    ]

  },
  // 商家管理
  {
    path: '/store',
    component: LayOut,
    meta: { title: "商家管理" },
    isshow: true,
    redirect: '/store/newstore',
    icon: 'el-icon-shopping-bag-1',
    children: [
      { path: 'newstore', component: () => import('@/views/store/Newstore.vue'), meta: { title: "新增商家" }, isshow: true }, //新增商家
      { path: 'storelist', component: () => import('@/views/store/Storelist.vue'), meta: { title: "商家列表" }, isshow: true }, //商家列表
      { path: 'storeactivity', component: () => import('@/views/store/Storeactivity.vue'), meta: { title: "商家活动" }, isshow: true }, //商家活动
      { path: 'newactivity', component: () => import('@/views/store/Newactivity.vue'), meta: { title: "新增活动" }, isshow: true }, //新增活动
      { path: 'storetype', component: () => import('@/views/store/Storetype.vue'), meta: { title: "商家分类" }, isshow: true }, //商家分类
      { path: 'qftions', component: () => import('@/views/store/Qftions.vue'), meta: { title: "商家资质" }, isshow: true }, //商家资质
      { path: 'shopgoods', component: () => import('@/views/store/Shopgoods.vue'), meta: { title: "商家商品" }, isshow: true }, //商家商品
      { path: 'labellist', component: () => import('@/views/parenting/Labellist.vue'), meta: { title: "标签列表" }, isshow: true }, //标签
    ]

  },
  // 年卡会员
  {
    path: '/annual',
    component: LayOut,
    meta: { title: "年卡会员" },
    isshow: true,
    redirect: '/annual/annualrun',
    icon: 'el-icon-shopping-bag-1',
    children: [
      { path: 'annualrun', component: () => import('@/views/annual/Annualrun.vue'), meta: { title: "年卡管理" }, isshow: true }, //年卡管理
      { path: 'annuarecord', component: () => import('@/views/annual/Annuarecord.vue'), meta: { title: "记录管理" }, isshow: true }, //记录管理
      { path: 'annuallist', component: () => import('@/views/annual/Annuallist.vue'), meta: { title: "会员列表" }, isshow: true }, //会员列表
      { path: 'annuaremarks', component: () => import('@/views/annual/Annuaremarks.vue'), meta: { title: "年卡备注" }, isshow: true }, //年卡备注
    ]

  },
  // 出行人
  {
    path: '/traveler',
    component: LayOut,
    meta: { title: "出行人管理" },
    isshow: true,
    redirect: '/traveler/travelerlist',
    icon: 'el-icon-shopping-bag-1',
    children: [
      { path: 'travelerlist', component: () => import('@/views/traveler/Travelerlist.vue'), meta: { title: "出行人管理" }, isshow: true }, //出行人管理
    ]

  },
  // 热门搜索
  {
    path: '/hotsearch',
    component: LayOut,
    meta: { title: "热门搜索" },
    isshow: true,
    redirect: '/hotsearch/hotsearchlist',
    icon: 'el-icon-shopping-bag-1',
    children: [
      { path: 'hotsearchlist', component: () => import('@/views/hotsearch/Hotsearchlist.vue'), meta: { title: "热门搜索" }, isshow: true }, //热门搜索
    ]
  },
  // 热门搜索
  {
    path: '/voucher',
    component: LayOut,
    meta: { title: "代金券管理" },
    isshow: true,
    redirect: '/voucher/voucherlist',
    icon: 'el-icon-shopping-bag-1',
    children: [
      { path: 'voucherlist', component: () => import('@/views/voucher/Voucherlist.vue'), meta: { title: "代金券管理" }, isshow: true }, //代金券管理
    ]
  },
  // 亲子活动
  {
    path: '/parenting',
    component: LayOut,
    meta: { title: "亲子活动" },
    isshow: true,
    redirect: '/parenting/parentinglist',
    icon: 'el-icon-shopping-bag-1',
    children: [
      { path: 'parentinglist', component: () => import('@/views/parenting/Parentinglist.vue'), meta: { title: "亲子活动列表" }, isshow: true }, //活动列表
      { path: 'topact', component: () => import('@/views/parenting/topAct.vue'), meta: { title: "首页活动" }, isshow: true }, //首页活动
      { path: 'parentingtype', component: () => import('@/views/parenting/Parentingtype.vue'), meta: { title: "亲子活动分类" }, isshow: true }, //亲子活动分类
      { path: 'grouplist', component: () => import('@/views/parenting/Grouplist.vue'), meta: { title: "拼团列表" }, isshow: true }, //拼团列表
      { path: 'actseckill', component: () => import('@/views/store/Actseckill.vue'), meta: { title: "限时秒杀" }, isshow: true }, //限时秒杀
    ]
  },
  // 会员管理
  {
    path: '/member',
    component: LayOut,
    meta: { title: "会员管理" },
    isshow: true,
    redirect: '/member/memberlist',
    icon: 'el-icon-shopping-bag-1',
    children: [
      { path: 'memberlist', component: () => import('@/views/member/Memberlist.vue'), meta: { title: "会员列表" }, isshow: true }, //会员列表
      { path: 'passwordInfomodify', component: () => import('@/views/member/PasswordInfomodify.vue'), meta: { title: "信息修改" }, isshow: true }, //会员列表
    ]
  },
  // 订单管理
  {
    path: '/order',
    component: LayOut,
    meta: { title: "订单管理" },
    isshow: true,
    redirect: '/order/tobepaid',
    icon: 'el-icon-s-claim',
    children: [
      { path: 'tobepaid', component: () => import('@/views/order/Tobepaid.vue'), meta: { title: "订单列表" }, isshow: true }, //订单列表
      // { path: 'grouplist', component: () => import('@/views/order/Grouplist.vue'), meta: { title: "团购列表" }, isshow: true }, //团购列表
      { path: 'refund', component: () => import('@/views/order/Refund.vue'), meta: { title: "退款申请" }, isshow: true }, //退款申请
      { path: 'returnlist', component: () => import('@/views/order/Returnlist.vue'), meta: { title: "退款申请" }, isshow: true }, //退款申请
    ]
  },
  // 核销管理
  {
    path: '/writeoffs',
    component: LayOut,
    meta: { title: "核销管理" },
    isshow: true,
    redirect: '/writeoffs/writeoff',
    icon: 'el-icon-s-claim',
    children: [
      { path: 'writeoff', component: () => import('@/views/writeoffs/Writeoff.vue'), meta: { title: "核销查询" }, isshow: true }, //核销查询
      { path: 'shopwriteoff', component: () => import('@/views/writeoffs/Shopwriteoff.vue'), meta: { title: "商家核销" }, isshow: true }, //核销查询
      { path: 'actwriteoff', component: () => import('@/views/writeoffs/Actwriteoff.vue'), meta: { title: "活动核销" }, isshow: true }, //核销查询
    ]
  },
  // 意见反馈
  {
    path: '/feedback',
    component: LayOut,
    meta: { title: "意见反馈" },
    isshow: true,
    redirect: '/feedback/feedback',
    icon: 'el-icon-s-claim',
    children: [
      { path: 'feedback', component: () => import('@/views/feedback/Feedback.vue'), meta: { title: "意见反馈" }, isshow: true }, //意见反馈
    ]
  },
  // // 订单管理
  // {
  //   path: '',
  //   component: LayOut,
  //   isshow: true,
  //   icon: 'el-icon-document',
  //   children: [
  //     {
  //       path: '/order',
  //       component: () => import('@/views/order/Order.vue'),
  //       meta: { title: "订单管理" },
  //     }
  //   ]
  // },

  // 动态路由
  {
    path: '/404',
    isshow: false,
    component: () => import('@/views/Error404.vue')
  },
  {
    path: '*',
    isshow: false,
    redirect: '/404'
  }

]


const router = new VueRouter({
  routes: asyncRoutes
})
// // 路由守卫
// // next代表放行
// router.beforeEach((to, from, next) => {
//   let tk = local.get('tk')
//   //  判断当to去login永远放行
//   if (to.path == '/login') {
//     next()
//   } else if (tk) {
//     //判断如果当token存在就放行
//     next()
//   } else {
//     // 如果没有token就返回到登录页
//     router.push('/login')
//   }
// })
// // 点击相同路由报警告的解决办法
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
// 3.暴露vue实例对象
export default router
