<template>
  <!-- 卡片 -->
  <div class="bread" style="margin: 20px">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <!-- 标题 -->
        <slot name="title"></slot>
      </div>
      <!-- 内容 -->
      <slot name="content"></slot>
    </el-card>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
</style>