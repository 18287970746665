<template>
    <div>
        <div>很抱歉,页面丢失了...</div>
        <div @click="$router.go(-1)">返回</div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>

</style>