<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">退款列表</span>
          <div slot="content">
            <!-- <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.actName"
                  placeholder="请输入活动名称"
                  style="width: 200px; margin-right: 30px"
                ></el-input>
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>

                <el-select
                  v-model="form.buyType"
                  placeholder="活动类型"
                  style="margin-left: 30px"
                >
                  <el-option label="个体" value="1"></el-option>
                  <el-option label="拼团" value="2"></el-option>
                </el-select>
                <el-select
                  v-model="form.status"
                  placeholder="活动状态"
                  style="margin-left: 30px; margin-right: 30px"
                >
                  <el-option label="全部" value="null"></el-option>
                  <el-option label="待付款" value="1"></el-option>
                  <el-option label="待使用" value="2"></el-option>
                  <el-option label="已完成" value="4"></el-option>
                  <el-option label="取消" value="5"></el-option>
                  <el-option label="待开团" value="6"></el-option>
                  <el-option label="团购失败" value="7"></el-option>
                  <el-option label="已过期" value="8"></el-option>
                  <el-option label="申请退款" value="9"></el-option>
                  <el-option label="已退款" value="10"></el-option>
                </el-select>
                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form> -->
            <!-- 退款列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 订单ID -->
                <!-- <el-table-column label="订单ID" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.id }}</span>
                  </template>
                </el-table-column> -->
                <!-- 用户名称 -->
                <el-table-column label="用户名称" width="150">
                  <template slot-scope="scope">
                    <span>{{ scope.row.refundMember }}</span>
                  </template>
                </el-table-column>
                <!-- 活动名称 -->
                <el-table-column label="活动名称">
                  <template slot-scope="scope">
                    <span>{{ scope.row.actName }}</span>
                  </template>
                </el-table-column>
                <!-- 金额 -->
                <el-table-column label="金额" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.refundFee }}</span>
                  </template>
                </el-table-column>
                <!-- 退款备注 -->
                <el-table-column label="退款备注" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.refundContent
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 所属商家 -->
                <el-table-column label="所属商家" width="150">
                  <template slot-scope="scope">
                    <span>{{ scope.row.shopName }}</span>
                  </template>
                </el-table-column>
                <!-- 状态 -->
                <el-table-column label="退款状态" width="150">
                  <template slot-scope="scope">
                    {{
                      scope.row.refundStatus == 1
                        ? "退款成功"
                        : scope.row.refundStatus == 2
                        ? "退款失败"
                        : scope.row.refundStatus == 0
                        ? "退款中"
                        : ""
                    }}
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="150">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="seereture(scope.$index, scope.row)"
                      >查询退款
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      typeForm: {},
      form: {
        name: "",
      },
      // 时间数据
      time: "",
      // 表格数据
      tableData: [],
      memberList: [],
      t1: null,
      t2: null,
    };
  },
  created() {
    this.returnList();
  },
  methods: {
    // 搜索
    search() {
      // console.log(t1, t2);
      let that = this;
      var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
      var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
      // var t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      // var t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      if (this.time.length == 2) {
        that.t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
        that.t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      }
      let returnList = {
        actName: this.form.actName,
        buyType: this.form.buyType,
        startTime: this.t1,
        endTime: this.t2,
        status: Number(this.form.status),
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.returnLi(returnList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    seereture(index, row) {
      let outRefundNo = row.id;
      this.$api.returnSee(outRefundNo).then((res) => {
        if (res.status == 200) {
          if (res.result.result == "ERROR") {
            this.$alert("退款失败，原因：" + res.result.errCodeDes, "退款信息", {
              confirmButtonText: "确定",
              //   callback: (action) => {
              //     this.$message({
              //       type: "info",
              //       message: `action: ${action}`,
              //     });
              //   },
            });
          } else {
            this.$alert("退款成功", "退款信息", {
              confirmButtonText: "确定",
            });
          }
        }
      });
    },
    // 分页函数
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.returnList();
    },
    returnList() {
      let returnList = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.returnLi(returnList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--primary {
  margin-bottom: 10px;
}

.table {
  margin-top: 20px;
  .bannerImg {
    width: 150px;
    height: 100px;
  }

  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
.headimg {
  display: flex;
  align-items: center;
}
.img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}
.names {
}
</style>