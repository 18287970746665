<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">商家列表</span>
          <div slot="content">
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="">
                <el-input
                  v-model="form.name"
                  placeholder="请输入商家名字或者商家地址"
                  style="width: 250px; margin-right: 120px"
                ></el-input>
                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 商家列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 商家id -->
                <!-- <el-table-column label="商家ID" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.id }}</span>
                  </template>
                </el-table-column> -->
                <!-- 商家名称 -->
                <el-table-column label="商家名称" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.shopName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 地区 -->
                <el-table-column label="地区" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.address
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 商家广告图 -->
                <el-table-column label="商家广告图" width="100">
                  <template slot-scope="scope">
                    <div>
                      <img
                        class="bannerImg"
                        style="width: 80px; height: 80px"
                        :src="scope.row.headImg"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 权重 -->
                <el-table-column label="权重" width="60">
                  <template slot-scope="scope">
                    <span>{{ scope.row.sw }}</span>
                  </template>
                </el-table-column>
                <!-- 商家状态 -->
                <el-table-column label="商家状态" width="80">
                  <template slot-scope="scope">
                    <span>{{ scope.row.state == 1 ? "启用" : "禁用" }} </span>
                  </template>
                </el-table-column>
                <!-- 商家状态 -->
                <el-table-column label="商家状态" width="80">
                  <template slot-scope="scope">
                    <span>{{ scope.row.status == 0 ? "下架" : "上架" }} </span>
                  </template>
                </el-table-column>
                <!-- 商家账号 -->
                <el-table-column label="联系方式(账号)" width="150">
                  <template slot-scope="scope">
                    <span>{{ scope.row.username }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.status == 0"
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="on(scope.$index, scope.row)"
                      >{{ scope.row.state == 0 ? "启用" : "禁用" }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="PutontheShelves(scope.$index, scope.row)"
                      >{{ scope.row.status == 0 ? "上架" : "下架" }}</el-button
                    >

                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                    <el-button
                      size="mini"
                      @click="changepwd(scope.$index, scope.row)"
                      >修改密码</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>

              <!-- 编辑 -->
              <el-dialog title="编辑列表" :visible.sync="dialogFormVisible">
                <el-form ref="editForm" :model="editForm">
                  <el-form-item label="商家名称:" style="width: 380px">
                    <el-input v-model="editForm.shopName">{{
                      editForm.shopName
                    }}</el-input>
                  </el-form-item>

                  <el-form-item label="浏览量:">
                    <el-input
                      v-model="editForm.shopView"
                      style="width: 230px"
                    ></el-input>
                  </el-form-item>

                  <!-- 商家副标题 -->
                  <el-form-item label="副标题:" prop="secondName">
                    <el-input
                      v-model="editForm.secondName"
                      style="width: 530px"
                      >{{ editForm.secondName }}</el-input
                    >
                  </el-form-item>
                  <el-form-item label="首页展示:">
                    <el-upload
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg1"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemovesindex"
                      :file-list="imgUrlsIndex"
                      :limit="1"
                    >
                      <i class=""> 建议尺寸:170*128</i>
                    </el-upload>
                  </el-form-item>
                  <!-- <div style="margin: 20px 0; color: red; font-size: 14px">
                  </div> -->

                  <el-form-item label="商家门店:">
                    <el-upload
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg2"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :file-list="imgUrl"
                    >
                      <i class="">建议尺寸:375*180</i>
                    </el-upload>
                  </el-form-item>
                  <!-- <div style="margin: 20px 0; color: red;font-size:14px"></div> -->

                  <el-form-item label="商家资质:">
                    <el-upload
                      action
                      :before-upload="beforeUpload"
                      :http-request="uploadimg3"
                      list-type="picture-card"
                      :headers="headers"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemoves"
                      :file-list="imgUrls"
                    >
                      <i class="">建议尺寸:375*180</i>
                    </el-upload>
                  </el-form-item>
                  <!-- 营业时间 -->
                  <el-form-item label="营业时间:" style="width: 380px">
                    <el-time-picker
                      style="width: 370px"
                      is-range
                      v-model="time"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围"
                    >
                    </el-time-picker>
                  </el-form-item>
                  <!-- 分类 -->
                  <el-form-item label="商家分类:">
                    <el-select v-model="goodsType" placeholder="选择分类">
                      <el-option
                        v-for="(item, index) in typeList"
                        :key="index"
                        :label="item.catName"
                        :value="item.catId"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <!-- 推广标签 -->
                  <el-form-item label="标签类型">
                    <el-select v-model="extend" placeholder="请选择标签类型">
                      <el-option
                        v-for="(item, index) in labeList"
                        :key="index"
                        :label="item.attrName"
                        :value="item.attrName"
                      ></el-option>
                    </el-select>
                  </el-form-item>

<!--                  <el-form-item label="是否为年卡活动">-->
<!--                    <span v-if="editForm.isCard===1">是</span>-->
<!--                    <span v-else>否</span>-->
<!--                  </el-form-item>-->

                  <!-- 年龄段 -->
                  <el-form-item label="年龄段:">
                    <el-select v-model="labelage" placeholder="选择年龄段">
                      <el-option
                        v-for="(item, index) in labelsage"
                        :key="index"
                        :label="item.attrName"
                        :value="item.attrName"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <!-- 活动标签 -->
                  <el-form-item label="活动标签:">
                    <el-checkbox-group v-model="label" @change="getLabel">
                      <el-checkbox
                        v-for="(item, index) in labels"
                        :key="item.id"
                        :label="item"
                        :name="index.id"
                        >{{ item.attrName }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                  <!-- 活动属性 -->
                  <el-form-item label="活动属性:">
                    <el-checkbox-group v-model="labelSx" @change="getLabelSx">
                      <el-checkbox
                        v-for="(item, index) in labelsSx"
                        :key="item.id"
                        :label="item"
                        :name="index.id"
                        >{{ item.attrName }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>

                  <el-form-item label="权重:" style="width: 380px">
                    <el-input type="number" v-model="editForm.sw">{{
                      editForm.sw
                    }}</el-input>
                  </el-form-item>
                  <el-form-item label="商家状态:">
                    {{ editForm.state == 1 ? "启用" : "禁用" }}
                  </el-form-item>
                  <el-form-item label="商家账户:" style="width: 380px">
                    <el-input v-model="editForm.username">{{
                      editForm.username
                    }}</el-input>
                  </el-form-item>
                  <el-form-item label="商家简介:">
                    <editor-bar
                      v-model="editForm.shopDescription"
                      :isClear="isClear"
                    ></editor-bar>
                  </el-form-item>
                  <!-- <el-form-item label="商家密码:" style="width: 380px">
                    <el-input v-model="editForm.password">{{
                      editForm.pwd
                    }}</el-input>
                  </el-form-item> -->
                  <el-form-item label="地区:">
                    <el-amap-search-box
                      class="search-box"
                      :search-option="searchOption"
                      :on-search-result="onSearchResult"
                    ></el-amap-search-box>
                  </el-form-item>
                  <!-- 商家地址 -->
                  <el-form-item label="商家地址:" prop="region">
                    <el-select
                      v-model="value1"
                      placeholder="请选择"
                      @change="Cprovince"
                    >
                      <el-option
                        v-for="item in province"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="value2"
                      placeholder="请选择"
                      @change="Cprovincecity"
                    >
                      <el-option
                        v-for="item in city"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>

                    <el-select
                      v-model="value3"
                      placeholder="请选择"
                      @change="Cprovincereg"
                    >
                      <el-option
                        v-for="item in regions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div style="color: red; font-size: 14px">
                      *
                      商家地址和年卡地址相对应，如年卡区域为四川成都青羊区，商家地址也需为四川成都青羊区
                    </div>
                  </el-form-item>

                  <!-- 地图 -->
                  <div
                    class="amap-page-container"
                    style="width: 80%; height: 500px; margin: 30px 10%"
                  >
                    <el-amap
                      ref="map"
                      vid="amapDemo"
                      :amap-manager="amapManager"
                      :center="center"
                      :zoom="zoom"
                      :plugin="plugin"
                      :events="events"
                      class="amap-demo"
                    >
                    </el-amap>
                    <p style="font-size: 14px; margin-top: 10px">
                      详细地址：{{ address }}
                    </p>
                    <!-- <p>坐标经纬度：{{ center }}</p> -->
                  </div>
                </el-form>

                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="oks">确 定</el-button>
                </div>
              </el-dialog>
              <el-dialog title="修改密码" :visible.sync="dialogFormVisibles">
                <el-form :model="changeForm">
                  <el-form-item label="重置密码:">
                    <el-input
                      style="width: 200px"
                      v-model="changeForm.pwd"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisibles = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="okchange">确 定</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
import EditorBar from "@/components/wangEnduit.vue";

// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
    EditorBar,
  },
  data() {
    return {
      // 地图相关数据
      searchOption: {
        city: "", //搜索范围
        citylimit: false, //限制搜索范围
        //city: "", citylimit: false, // 不限制搜索范围搜索，比如想全国搜索
      },
      isClear: false,
      lng: "",
      lat: "",
      address: "",
      marker: "",
      amapManager,
      zoom: 12,
      center: [30.661598, 104.094119],
      events: {
        init: (o) => {},
        moveend: () => {},
        zoomchange: () => {},
        click: (e) => {
          //   console.log(e.lnglat);
          self.lng = e.lnglat.lng;
          self.lat = e.lnglat.lat;
          self.center = [self.lng, self.lat];
          //   console.log(e.lnglat, 1999);

          let o = amapManager.getMap();
          if (!self.marker) {
            self.marker = new AMap.Marker({
              position: e.lnglat,
            });
            self.marker.setMap(o);
          }
          self.marker.setPosition(e.lnglat);
          let geocoder = new AMap.Geocoder({});

          geocoder.getAddress(e.lnglat, function (status, result) {
            if (status === "complete" && result.regeocode) {
              self.address = result.regeocode.formattedAddress;

              //   console.log(self.address, "999地址");
            } else {
              log.error("根据经纬度查询地址失败");
            }
          });
        },
      },
      plugin: [
        "ToolBar",
        {
          pName: "MapType",
          defaultType: 0,
          events: {
            init(o) {
              //   console.log(o);
            },
          },
        },
      ],
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              let par =
                document.getElementsByClassName("search-box-wrapper")[0];
              par.firstChild.setAttribute(
                "placeholder",
                "请输入新增商家详细地址"
              );

              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                // console.log(JSON.stringify(result));
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.address = result.formattedAddress;
                  self.center = [self.lng, self.lat];
                  //   console.log(self.center, 99666);
                  let o = amapManager.getMap();
                  if (!self.marker) {
                    self.marker = new AMap.Marker({
                      position: self.center,
                    });
                    self.marker.setMap(o);
                  }
                  self.marker.setPosition(self.center);
                }
              });
            },
          },
        },
      ],
      imageUrl: "", //商品图片
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      pageSizes: 999,
      // 总条数
      total: 1,
      size: 10,
      // 编辑弹窗
      dialogFormVisible: false,
      dialogFormVisibles: false,
      dialogVisible: false,
      dialogImageUrl: "",
      editForm: {
        usn: "",
        isCard:0
      },
      changeForm: {},
      form: {
        name: "",
      },
      // 表格数据
      tableData: [],
      timeData: false,
      // 图片
      imgUrl: [],
      imglist: [],
      imgslist: [],
      imgUrls: [],
      imgUrlsIndex: [],
      time: "",
      province: [],
      city: [],
      regions: [],
      value1: "",
      value2: "",
      value3: "",
      typeList: [],
      goodsType: "",
      labels: [],
      label: [],
      labelValue: [],
      labelsSx: [],
      labelSx: [],
      labelValueSx: [],
      labelsage: [],
      labelage: "",
      labelValueage: [],
      labeList: [],
      extend: "",
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  created() {
    this.storeList();
    this.Searchaddress();
    this.typeSel();
    this.labellist();
    this.labelSxlist();
    this.labelagelist();
    this.labellist2();
  },
  methods: {
    labellist2() {
      let label = {
        page: 1,
        pageSize: 999,
        attrType: 3,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labeList = res.result.records;
        }
      });
    },
    storeList() {
      let storeList = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.storeList(storeList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    changepwd(index, row) {
      this.changeForm = row;
      this.dialogFormVisibles = true;
    },
    okchange() {
      let id = this.changeForm.id;
      let pwd = this.changeForm.pwd;
      this.$api.changepwd({ id: id, pwd: pwd }).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.dialogFormVisibles = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 上传图片
    // 上传
    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrlsIndex.push({ url: v.split("?")[0] });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex");
            } else {
              that.imgUrlsIndex.push({ url: v });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex1111");
            }
          });
      }
      multipartUpload();
    },
    // 门店拍照
    uploadimg2(file) {
      const that = this;

      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v, name: file.file.name });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    uploadimg3(file) {
      const that = this;

      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrls.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrls, "that.imgUrls");
            } else {
              that.imgUrls.push({ url: v, name: file.file.name });
              console.log(that.imgUrls, "that.imgUrls1111");
            }
          });
      }
      multipartUpload();
    },
    // 门店删除
    handleRemove(file, fileList) {
      const filePath = file.url;
      this.imgUrl.forEach((val, index) => {
        if (val.url == filePath) {
          this.imgUrl.splice(index, 1);
        }
      });
    },
    // 资质删除
    handleRemoves(file, fileList) {
      const filePath = file.url;
      this.imgUrls.forEach((val, index) => {
        if (val.url == filePath) {
          this.imgUrls.splice(index, 1);
        }
      });
    },
    // 展示删除
    handleRemovesindex(file, fileList) {
      this.imgUrlsIndex.splice(0, 1);
    },
    // 放大
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.storeList();
    },
    PutontheShelves(index, row) {
      let id = row.id;
      if (row.status == 1) {
        this.$api.offShelf(id).then((res) => {
          if (res.status == 200) {
            this.storeList();
          }
        });
      } else if (row.status == 0) {
        this.$api.putShelves(id).then((res) => {
          if (res.status == 200) {
            this.storeList();
          }
        });
      }
    },
    // 编辑
    handleEdit(index, row) {
      console.log(row);
      this.editForm = row;
      this.extend = row.extend;
      this.imgUrlsIndex = [];
      this.imgUrl = [];
      this.imgUrls = [];
      this.imgslist = [];
      this.imglist = [];
      this.label = [];
      this.labelSx = [];
      this.time = [row.startTime, row.endTime];
      this.goodsType = row.catId;
      this.labelage = row.age;
      this.imgUrlsIndex.push({ url: row.headImg });
      this.dialogFormVisible = true;

      // 商家门店
      row.images.forEach((item) => {
        this.imgUrl.push({
          url: item.url,
        });
      });

      // 商家资质
      row.imgQua.forEach((item) => {
        this.imgUrls.push({
          url: item.url,
        });
      });
      // this.Labelsx = row.attr;
      let Label = row.label.split("-");
      // let Labelsx = row.attr.split("-");
      let Labelsx = [];
      row.attrs.forEach((item) => {
        Labelsx.push(item.attrName);
      });
      // 获取已有标签和返回的标签对象对比赋值到页面
      for (let i = 0; i < this.labels.length; i++) {
        for (let j = 0; j < Label.length; j++) {
          if (this.labels[i].attrName == Label[j]) {
            this.label.push(this.labels[i]);
          }
        }
      }
      console.log(this.labelSx, "this.labelSx");
      for (let i = 0; i < this.labelsSx.length; i++) {
        for (let j = 0; j < Labelsx.length; j++) {
          if (this.labelsSx[i].attrName == Labelsx[j]) {
            this.labelSx.push(this.labelsSx[i]);
          }
        }
      }
    },
    handleDelete(index, row) {
      let that = this;
      // 删除框
      this.$confirm(
        "此操作将永久删除该店铺,也会删除对应的轮播图片请先修改轮播跳转页面,是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      ).then(() => {
        let id = {
          id: row.id,
        };
        this.$api.storeDel(id).then((res) => {
          if (res.status == 200) {
            this.storeList();
          } else if (res.status == 406) {
            this.$message(res.message);
          }
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.bannerList();
      });
    },
    // 搜索
    search() {
      let storeList = {
        key: this.form.name,
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.storeList(storeList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    // 启用禁用
    on(index, row) {
      let ONoff = {
        id: row.id,
        state: row.state == 1 ? 0 : 1,
      };
      this.$api.ONoff(ONoff).then((res) => {
        if (res.status == 200) {
          this.storeList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 地图
    onSearchResult(pois) {
      if (pois.length > 0) {
        let { lng, lat, name, location } = pois[0];
        let center = [lng, lat];
        this.lng = lng;
        this.lat = lat;
        this.center = [lng, lat];
        let o = amapManager.getMap();
        if (!this.marker) {
          this.marker = new AMap.Marker({
            position: center,
          });
          this.marker.setMap(o);
        }
        this.marker.setPosition(center);
        // 近来补充  根据经纬度查询地址
        let geocoder = new AMap.Geocoder({});
        let that = this;
        geocoder.getAddress(location, function (status, result) {
          console.log(status, result);
          if (status === "complete" && result.regeocode) {
            that.address = result.regeocode.formattedAddress;
          } else {
            console.log("根据经纬度查询地址失败");
          }
        });
      }
    },
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },
    // 确定编辑
    oks() {
      let img1 = [];
      let img2 = [];
      let img3 = "";
      if (this.imgUrlsIndex.length) {
        this.imgUrlsIndex.forEach((item) => {
          img3 = item.url;
        });
        console.log(img3, "imgUrlsIndex");
      }
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img1.push(item.url);
        });
        console.log(img1, "imgurl");
      }
      if (this.imgUrls.length) {
        this.imgUrls.forEach((item) => {
          img2.push(item.url);
        });
        console.log(img2, "imgurls");
      }
      console.log(this.address);
      var moment = require("moment");
      moment().format();
      var time1 = this.$moment(this.time[0]).format("h:mm:ss");
      var time2 = this.$moment(this.time[1]).format("h:mm:ss");
      var t1 = (this.$moment(time1, "h:mm:ss").valueOf() / 1000) * 1000;
      var t2 = (this.$moment(time2, "h:mm:ss").valueOf() / 1000) * 1000;
      let headerImg = "";
      this.imgUrlsIndex.forEach((item) => {
        headerImg = item.url;
      });
      let regionId = "";
      if (this.value3 == "") {
        regionId = this.value2;
      } else {
        regionId = this.value3;
      }
      let list = [];
      let listSx = [];
      this.label.forEach((item) => {
        list.push(item.attrName);
      });
      this.labelSx.forEach((item) => {
        listSx.push(item.id);
      });
      let storeedit = {
        address: this.address,
        regionId: regionId,
        region: sessionStorage.getItem("name"),
        headImg: img3,
        id: this.editForm.id,
        lat: this.center[0],
        lng: this.center[1],
        shopName: this.editForm.shopName,
        sw: Number(this.editForm.sw),
        username: this.editForm.username,
        password: this.editForm.password,
        shopQuaImg: img2,
        shopDesImg: img1,
        startTime: t1,
        endTime: t2,
        catId: this.goodsType,
        secondName: this.editForm.secondName,
        label: list.join("-"),
        attr: listSx.join("-"),
        age: this.labelage,
        shopDescription: this.editForm.shopDescription,
        extend: this.extend,
        shopView: this.editForm.shopView,
      };
      console.log(storeedit);
      this.$api.storeedit(storeedit).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          console.log(storeedit, 123);
          this.storeList();
          this.dialogFormVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    typeSel() {
      let type = {
        page: this.currPage,
        pageSize: 999,
        catType: 1,
      };
      this.$api.storetype(type).then((res) => {
        if (res.status == 200) {
          this.typeList = res.result.records;
          console.log(res);
        }
      });
    },
    labellist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 0,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labels = res.result.records;
        }
      });
    },
    labelSxlist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 1,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labelsSx = res.result.records;
        }
      });
    },
    labelagelist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 2,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labelsage = res.result.records;
        }
      });
    },
    // 省
    Searchaddress() {
      this.$api.Searchaddress().then((res) => {
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.province = prsArrs;
        }
      });
    },
    // 城市
    Searchcity() {
      let pid = this.value1;
      this.$api.citys(pid).then((res) => {
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.city = prsArrs;
        }
      });
    },
    // 城市
    searchreg() {
      let pid = this.value2;
      this.$api.citys(pid).then((res) => {
        if (res.status == 200) {
          let newArr = [];
          newArr = res.result;
          var keyMap = {
            id: "value",
            name: "label",
          };
          for (var i = 0; i < newArr.length; i++) {
            var obj = newArr[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          let prsArrs = [];
          newArr.forEach((item) => {
            // console.log(item)
            prsArrs.push({
              label: item.label,
              value: item.value,
            });
          });
          this.regions = prsArrs;
          console.log(this.regions, 22222);
        }
      });
    },
    Cprovince() {
      console.log(this.value1, 222);
      this.Searchcity();
    },
    Cprovincecity() {
      this.searchreg();
      console.log(this.value2, 222);
    },
    Cprovincereg(e) {
      console.log(this.value3, 222);
      this.regions.forEach((item) => {
        if (item.value == e) {
          sessionStorage.setItem("name", item.label);
        }
      });
    },
    getLabel(item) {
      this.labelValue = item;
      console.log(item, "item");
    },
    getLabelSx(item) {
      this.labelValueSx = item;
      console.log(item, "item");
    },
    getLabelage(item) {
      this.labelValueage = item;
      console.log(item, "item");
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 200px;
    height: 110px;
  }
  // .el-form {
  //   height: 500px;
  //   overflow: scroll;
  // }
  /deep/.el-card__body {
    overflow: auto;
  }
  /deep/.amap-page-container {
    width: 600px !important;
    height: 400px !important;
    margin: 0 !important;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  /deep/.el-input__inner {
    width: 100% !important;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }

  /deep/.el-input__inner {
    width: 60%;
  }
}
/deep/.el-dialog {
  width: 60%;
}
</style>
