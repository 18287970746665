<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <div class="right-bottom">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="">新增活动</span>
          </div>
          <!-- 表单 -->
          <el-form
            ref="formItemName"
            :model="form"
            label-width="110px"
            :rules="rules"
          >
            <!-- 库存 -->
            <el-form-item label="库存(儿童):" prop="stock">
              <el-input v-model="form.stock" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="库存(成人):" prop="adult_stock">
              <el-input
                v-model="form.adult_stock"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item label="最低数量(成):" prop="adultmin">
              <el-input
                v-model="form.adultmin"
                style="width: 215px"
                placeholder="填写成人最低购买数量	"
              ></el-input>
            </el-form-item>
            <el-form-item label="最低数量(童):" prop="childmin">
              <el-input
                v-model="form.childmin"
                style="width: 215px"
                placeholder="填写儿童最低购买数量"
              ></el-input>
            </el-form-item>

            <!-- 权重 -->
            <el-form-item label="权重:" prop="sw">
              <el-input v-model="form.sw" style="width: 300px"></el-input>
            </el-form-item>

            <!-- 价格 -->
            <el-form-item label="价格:" prop="money">
              <el-input v-model="form.money" style="width: 300px"></el-input>
            </el-form-item>

            <!-- 原价 -->
            <el-form-item
              label="原价/成人价格:"
              label-width="120px"
              prop="Ymoney"
            >
              <el-input v-model="form.Ymoney" style="width: 300px"></el-input>
            </el-form-item>

            <!-- 拼团 -->
            <el-form-item label="团购" porp="isGroup">
              <el-select v-model="form.isGroup" placeholder="请选择是否团购">
                <el-option label="非团购" value="0"></el-option>
                <el-option label="团购" value="1"></el-option>
              </el-select>
            </el-form-item>

            <!-- 浏览量 -->
            <el-form-item label="浏览量:" porp="view">
              <el-input v-model="form.view" style="width: 300px"></el-input>
            </el-form-item>

            <!-- 购买量/销量 -->
            <el-form-item label="购买量:" porp="saleCount">
              <el-input
                v-model="form.saleCount"
                style="width: 300px"
              ></el-input>
            </el-form-item>

            <!-- 店铺名称 -->
            <el-form-item label="活动名:" prop="activity">
              <el-input v-model="form.activity" style="width: 230px"></el-input>
            </el-form-item>

            <!-- 加入年卡 -->
            <!-- <el-form-item label="加入年卡:" prop="join" label-width="90px">
              <el-select
                style="width: 230px"
                v-model="form.join"
                placeholder="是/否"
              >
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="加入年卡:" prop="join">
              <!-- <el-select
                style="width: 230px"
                v-model="form.join"
                placeholder="是/否"
              >
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select> -->
              <el-radio-group v-model="form.join">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- 分类 -->
            <el-form-item label="活动分类:" prop="actType">
              <el-select v-model="form.actType" placeholder="选择分类">
                <el-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :label="item.catName"
                  :value="item.catId"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- 活动区域 -->
            <el-form-item label="活动区域" prop="isout">
              <el-select v-model="form.isout" placeholder="请选择活动区域">
                <el-option label="室内" value="0"></el-option>
                <el-option label="室外" value="1"></el-option>
              </el-select>
            </el-form-item>
            <!-- 时间 -->
            <el-form-item label="活动时间:">
              <el-date-picker
                type="dates"
                v-model="time"
                placeholder="选择一个或多个日期"
              >
              </el-date-picker>
            </el-form-item>

            <!-- 活动标签 -->
            <el-form-item label="活动标签:" porp="label">
              <el-checkbox-group v-model="form.label" @change="getLabel">
                <el-checkbox
                  v-for="(item, index) in labels"
                  :key="item.id"
                  :label="item"
                  :name="index.id"
                  >{{ item.attrName }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <!-- 活动属性 -->
            <el-form-item label="活动属性:" prop="labelSx">
              <el-checkbox-group v-model="form.labelSx" @change="getLabelSx">
                <el-checkbox
                  v-for="(item, index) in labelsSx"
                  :key="item.id"
                  :label="item"
                  :name="index.id"
                  >{{ item.attrName }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>

            <!-- 类型 -->
            <!-- <el-form-item label="活动类型:" prop="type">
              <el-select v-model="form.type" placeholder="">
                <el-option label="普通活动" value="1"></el-option>
                <el-option label="亲子活动" value="2"></el-option>
              </el-select>
            </el-form-item> -->
            <!-- 商户 -->
            <el-form-item label="所属商家:" prop="shops">
              <el-select v-model="form.shops" filterable placeholder="">
                <el-option
                  v-for="(item, i) in form.shop"
                  :key="i"
                  :label="item.shopName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <!-- 活动描述 -->
            <el-form-item label="活动描述:" porp="intro">
              <el-input v-model="form.intro" style="width: 300px"></el-input>
            </el-form-item>

            <!-- 上传图片 -->
            <!-- on-preview	点击文件列表中已上传的文件时的钩子 -->
            <!--on-remove 文件列表移除文件时的钩子  -->
            <el-form-item label="活动展示:">
              <el-upload
                ref="upload"
                action
                :before-upload="beforeUpload"
                :http-request="uploadimg1"
                list-type="picture-card"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemovesindex"
                :limit="1"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="活动多图:">
              <el-upload
                ref="uploads"
                action
                :before-upload="beforeUpload"
                :http-request="uploadimg2"
                list-type="picture-card"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="">建议尺寸:390*208</i>
              </el-upload>
            </el-form-item>

            <!-- 活动详情 -->
            <el-form-item label="活动详情:" porp="desc">
              <editor-bar
                v-model="form.desc"
                :isClear="isClear"
                @change="change"
              ></editor-bar>
            </el-form-item>
            <el-button type="primary" @click="upMsg(formItemName)"
              >添加提交</el-button
            >
          </el-form>
        </el-card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import EditorBar from "@/components/wangEnduit.vue";

export default {
  components: {
    HeaderNav,
    Card,
    EditorBar,
  },
  data() {
    return {
      currPage: 1,
      // 分页的条数
      pageSize: 999,
      // 总条数
      total: 1,
      isClear: false,
      form: {
        activity: "",
        join: 1,
        money: "",
        stock: "",
        desc: "",
        sw: "",
        type: "2",
        shops: "",
        shop: [],
        saleCount: "",
        view: "",
        label: [],
        labelSx: [],
        actType: "",
        isGroup: "",
      },
      // labelSx: [],
      t1: null,
      t2: null,
      formItemName: {},
      rules: {
        adultmin: [
          {
            required: true,
            message: "请选择成人最低购买数量",
            trigger: "blur",
          },
        ],
        childmin: [
          {
            required: true,
            message: "请选择儿童最低购买数量",
            trigger: "blur",
          },
        ],
        activity: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 8, message: "长度在 3 到 8 个字符", trigger: "blur" },
        ],
        join: [
          { required: true, message: "请选择是否加入年卡", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择一个活动类型", trigger: "change" },
        ],
        sw: [
          {
            required: true,
            message: "请输入权重，四位数以下",
            trigger: "blur",
          },
        ],
        shops: [
          { required: true, message: "请选择一个商户", trigger: "change" },
        ],
        money: [{ required: true, message: "请输入活动价格", trigger: "blur" }],
        Ymoney: [
          { required: true, message: "请输入原价/成人价格", trigger: "blur" },
        ],
        stock: [{ required: true, message: "请输入活动库存", trigger: "blur" }],
        label: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动标签",
            trigger: "change",
          },
        ],
        labelSx: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动属性",
            trigger: "change",
          },
        ],
        actType: [
          { required: true, message: "请选择商家分类", trigger: "change" },
        ],
        isout: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        isGroup: [
          { required: true, message: "请选择是否团购", trigger: "change" },
        ],
        saleCount: [{ required: true, message: "请填写销量", trigger: "blur" }],
        view: [{ required: true, message: "请填写浏览量", trigger: "blur" }],
        intro: [
          {
            required: true,
            message: "请输入描述",
            trigger: "blur",
          },
        ],
        desc: [{ required: true, message: "请填写描述", trigger: "blur" }],
      },
      time: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      // 店铺图片
      dialogVisible: false,
      dialogImageUrl: "",
      imgUrl: [],
      timeData: "",
      imgUrlsIndex: "",
      typeList: [],
      labels: [],
      labelValue: [],
      labelsSx: [],
      labelValueSx: [],
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },

    // 上传
    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrlsIndex.push({ url: v.split("?")[0] });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex");
            } else {
              that.imgUrlsIndex.push({ url: v });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex1111");
            }
          });
      }
      multipartUpload();
    },
    uploadimg2(file) {
      const that = this;

      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v, name: file.file.name });
            }
          });
      }
      multipartUpload();
    },
    // 删除
    handleRemove(file, fileList) {
      const filePath = file.name;
      this.imgUrl.forEach((val, index) => {
        if (val.name == filePath) {
          this.imgUrl.splice(index, 1);
        }
      });
    },

    // 展示删除
    handleRemovesindex(file, fileList) {
      this.imgUrlsIndex.splice(0, 1);
      // console.log(this.imgUrlsIndex);
    },
    // 让图片可以放大
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    chang() {
      console.log(this.time);
    },
    change() {},
    searchShop() {
      this.$api.searchShop().then((res) => {
        if (res.status == 200) {
          this.form.shop = res.result;
        }
      });
    },
    getLabel(item) {
      this.labelValue = item;
      console.log(item, "item");
    },
    getLabelSx(item) {
      this.labelValueSx = item;
      console.log(item, "item");
      let listSx = [];
    },
    upMsg() {
      let img1 = [];
      let img2 = [];
      let img3 = "";
      let arrtime = [];
      let list = [];
      let listSx = [];
      if (this.imgUrlsIndex.length) {
        this.imgUrlsIndex.forEach((item) => {
          img3 = item.url;
        });
        console.log(img3, "imgUrlsIndex");
      }
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img1.push(item.url);
        });
        console.log(img1, "imgurl");
      }
      if (this.labelValue.length) {
        this.labelValue.forEach((item) => {
          list.push(item.attrName);
        });
      }
      if (this.labelValueSx.length) {
        this.labelValueSx.forEach((item) => {
          listSx.push(item.id);
        });
      }
      this.$refs.formItemName.validate((valid) => {
        if (valid) {
          var moment = require("moment");
          moment().format();
          var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
          if (this.time.length == 2) {
            var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
            this.t1 =
              (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
            this.t2 =
              (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
          } else if (this.time.length === 1) {
            this.t1 = new Date().getTime();
            this.t2 =
              (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
          }

          let upMsg = {
            actDefaultImg: img3,
            img: img1,
            actDesc: this.form.desc,
            actName: this.form.activity,
            endTime: this.t2,
            startTime: this.t1,
            isJoin: this.form.join,
            price: this.form.money,
            stock: this.form.stock,
            shopId: this.form.shops,
            sw: this.form.sw,
            saleTimeList: arrtime,
            shopId: this.form.shops,
            type: this.form.type,
            saleCount: this.form.saleCount,
            catId: this.form.actType,
            originalPrice: this.form.Ymoney,
            isOut: this.form.isout,
            label: list.join("-"),
            attr: listSx.join("-"),
            adultBuy: this.form.adultmin,
            childBuy: this.form.childmin,
            actIntro: this.form.intro,
            isGroup: this.form.isGroup,
            actView: this.form.view,
            adult_stock: this.form.adult_stock,
          };
          if (this.time == "") {
            this.$message.error("请选择活动时间");
          } else {
            this.time.forEach((item) => {
              arrtime.push(this.$dateFormat(new Date(item).getTime(), "Y-m-d"));
            });
            if (this.imgUrlsIndex != "" && this.imgUrl.length != 0) {
              this.$api.addAct(upMsg).then((res) => {
                if (res.status == 200) {
                  this.$message.success(res.result);
                  this.form = { brand_right: 0 };
                  this.form.label = [];
                  this.form.labelSx = [];
                  this.time = [];
                  this.$refs.upload.clearFiles();
                  this.$refs.uploads.clearFiles();
                  this.searchShop();
                } else {
                  this.$message.error(res.message);
                  this.searchShop();
                }
              });
            } else {
              this.$message.error("请添加图片");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    typeSel() {
      let type = {
        page: this.currPage,
        pageSize: this.pageSize,
        catType: 2,
      };
      this.$api.storetype(type).then((res) => {
        if (res.status == 200) {
          this.typeList = res.result.records;
        }
      });
    },
    labellist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 0,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labels = res.result.records;
        }
      });
    },
    labelSxlist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 1,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labelsSx = res.result.records;
        }
      });
    },
  },
  created() {
    this.searchShop();
    this.typeSel();
    this.labellist();
    this.labelSxlist();
  },
};
</script>

<style lang="less" scoped>
.content {
  .rigth-top {
    // width: 100%;
    height: 65px;
    background: #fff;
    overflow: hidden;
  }
  .right-bottom {
    overflow: auto;
    padding: 10px;
    margin: 10px;
    // background: #fff;
    height: 100%;
    /deep/ .el-textarea__inner {
      height: 90px;
      width: 600px;
    }
    /deep/.avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 140px;
      height: 140px;
      line-height: 140px;
      text-align: center;
    }
    /deep/.avatar {
      width: 140px;
      height: 140px;
      border-radius: 5px;
    }
    // 店铺图片
    /deep/ .el-upload--picture-card {
      width: 130px;
      height: 130px;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item-actions {
      width: 130px;
      height: 130px;
    }
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 130px;
    height: 130px;
  }

  /deep/.el-input__inner {
    width: 250px;
  }
  .el-button {
    margin-left: 50%;
    transform: translateZ(-50%);
  }
  .demonstration {
    font-size: 14px;
    line-height: 70px;
  }
}
</style>