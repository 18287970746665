<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">退款列表</span>
          <div slot="content">
            <!-- <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.name"
                  placeholder="请输入会员名"
                  style="width: 250px; margin-right: 30px"
                ></el-input>
                <el-select
                  v-model="form.isCard"
                  placeholder="会员类型"
                  style="margin-right: 30px"
                >
                  <el-option label="普通" value="1"></el-option>
                  <el-option label="年卡" value="2"></el-option>
                </el-select>
                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form> -->
            <!-- 商家列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 活动名称 -->
                <el-table-column label="活动名称" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.actName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 价格 -->
                <el-table-column label="价格" width="80">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.actPrice
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 会员名字 -->
                <el-table-column label="会员名字" width="150">
                  <template slot-scope="scope">
                    <span> {{ scope.row.memberUsername }}</span>
                  </template>
                </el-table-column>
                <!-- 描述 -->
                <!-- <el-table-column label="描述" width="250">
                  <template slot-scope="scope">
                    <span> {{ scope.row.description }}</span>
                  </template>
                </el-table-column> -->
                <!-- 状态 -->
                <el-table-column label="状态" width="150">
                  <template slot-scope="scope">
                    <span>{{ scope.row.status }}</span>
                  </template>
                </el-table-column>
                <!-- 创建时间 -->
                <el-table-column label="创建时间" width="150">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.createTime | dateFmt("YYYY-MM-DD")
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="medium "
                      type="primary"
                      v-if="scope.row.status == '待处理'"
                      @click="handleapply(scope.$index, scope.row)"
                      >通过</el-button
                    >
                    <el-button
                      size="medium "
                      type="danger"
                      v-if="scope.row.status == '待处理'"
                      @click="handlerefuse(scope.$index, scope.row)"
                      >拒绝</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <el-pagination
              style="margin: 20px"
              background
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
            >
            </el-pagination>
            <!-- 流水弹窗 -->
            <el-dialog title="会员流水" :visible.sync="dialogFormVisible">
              <div class="table">
                <el-table :data="waterData" style="width: 100%">
                  <!-- 商品名称 -->
                  <el-table-column label="商品名称" width="200">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{
                        scope.row.actName
                      }}</span>
                    </template>
                  </el-table-column>
                  <!-- 购买日期 -->
                  <el-table-column label="购买日期" width="200">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{
                        scope.row.buyTime | dateFmt("YYYY-MM-DD")
                      }}</span>
                    </template>
                  </el-table-column>
                  <!-- 金额 -->
                  <el-table-column label="金额" width="200">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{
                        scope.row.price
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">返回</el-button>
              </div>
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChanges"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </el-dialog>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
export default {
  components: {
    Card,
    HeaderNav,
  },
  data() {
    return {
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      // 总条数
      total: 1,
      size: 10,
      form: {
        name: "",
      },
      // 表格数据
      tableData: [],
      waterData: [],
      dialogFormVisible: false,
    };
  },
  methods: {
    handleCurrentChanges() {},
    handleapply(index, row) {
      console.log(row);
      if (row.status == 3) {
        this.$message.error("订单已是拒绝状态");
      } else {
        let msg = {
          status: 2,
          returnId: row.id,
        };
        this.$api.refundOk(msg).then((res) => {
          if (res.status == 200) {
            this.$message.success(res.result);
            this.refundList();
          } else {
            this.$message.error(res.message);
          }
          this.getNewsNum2();
        });
      }
    },
    handlerefuse(index, row) {
      console.log(row);
      if (row.status == 2) {
        this.$message.error("订单已是通过状态");
      } else {
        let msg = {
          status: 3,
          returnId: row.id,
        };
        this.$api.refundOk(msg).then((res) => {
          if (res.status == 200) {
            this.$message.success(res.result);
            this.refundList();
          } else {
            this.$message.error(res.message);
          }
          this.getNewsNum2();
        });
      }
    },
    refundList() {
      let RefundList = {
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.refundList(RefundList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.refundList();
    },
    getNewsNum2() {
      this.$api.getNewsNum2().then((res) => {
        this.$store.commit("setNum2", res.result);
      });
    },
  },
  created() {
    this.refundList();
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 20px;
  .bannerImg {
    width: 300px;
    height: 150px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
  .el-button + .el-button {
    margin: 0;
    margin-top: 5px;
  }
  .el-button {
    margin-right: 10px;
  }
}
</style>