
// 引入req
import axios from '@/utils/req'
// let baseUrl = 'http://192.168.110.19:444';
// let baseUrl = 'http://139.159.192.141:444';
let baseUrl = 'https://bbtedu03.com';
// let baseUrl = 'http://nkttest.mynatapp.cc';
// let baseUrl = 'https://adminapi.bbtedu03.com:444';
const api = {
    login(params) {
        return axios.post(`${baseUrl}/other/admin/adminLogin`, params);
    },
    // 店铺登陆
    shopLogin(params) {
        return axios.post(`${baseUrl}/product/shopLogin`, params)
    },
    // 查询某个文章
    manager(params) {
        return axios.get(`${baseUrl}/other/manager/get/`, { params: params });
    },
    // 须知联系查询所有文章
    otherList(params) {
        return axios.post(`${baseUrl}/other/otherList`, params);
    },
    // 修改须知联系
    update(params) {
        return axios.post(`${baseUrl}/other/manager/update`, params);
    },
    // 禁用图片
    stopimg(params) {
        return axios.get(`${baseUrl}/other/manager/stop`, { params: params });
    },
    // 启用图片
    onimg(params) {
        return axios.get(`${baseUrl}/other/manager/start`, { params: params });
    },
    //--------------------------------------------------------------------
    // 平台公告查询所有文章
    articleList(params) {
        return axios.post(`${baseUrl}/other/articleList`, params);
    },
    // 新增文档
    add(params) {
        return axios.post(`${baseUrl}/other/article/add`, params);
    },
    //修改文档
    edit(params) {
        return axios.post(`${baseUrl}/other/article/update`, params);
    },
    //删除文档
    del(params) {
        return axios.get(`${baseUrl}/other/article/del/` + params);
    },
    // 轮播查询
    banner(params) {
        return axios.post(`${baseUrl}/other/bannerList`, params);
    },
    // 新增广告图
    bannerAdd(params) {
        return axios.post(`${baseUrl}/other/banner/add`, params)
    },
    // 修改广告图
    bannerEdit(params) {
        return axios.post(`${baseUrl}/other/banner/update`, params)
    },
    // 删除广告图
    bannerDel(params) {
        return axios.get(`${baseUrl}/other/banner/del/` + params)
    },
    //--------------------------------------------------------------------
    // 新增店铺
    storage(params) {
        return axios.post(`${baseUrl}/product/addShop`, params)
    },
    // 商家列表
    storeList(params) {
        return axios.post(`${baseUrl}/product/shopInfo/pageList`, params)
    },
    // 商家分类
    storetype(params) {
        return axios.post(`${baseUrl}/product/category/pageList`, params)
    },
    // 新增分类
    storetypeAdd(params) {
        return axios.post(`${baseUrl}/product/category/add`, params)
    },
    // 删除分类
    storetypeDel(params) {
        return axios.get(`${baseUrl}/product/category/del`, { params: params })
    },
    // 编辑分类
    storetypeEdit(params) {
        return axios.post(`${baseUrl}/product/category/update`, params)
    },
    // 修改商家列表
    storeedit(params) {
        return axios.post(`${baseUrl}/product/updateShop`, params)
    },
    // 启用禁用店铺账号
    ONoff(params) {
        return axios.get(`${baseUrl}/product/startOrStopShop/`, { params: params })
    },
    // 删除店铺
    storeDel(params) {
        return axios.get(`${baseUrl}/product/delShop/`, { params: params })
    },
    // 下架店铺
    offShelf(params) {
        return axios.get(`${baseUrl}/product/downShop/` + params)
    },
    // 上架店铺
    putShelves(params) {
        return axios.get(`${baseUrl}/product/upShop/` + params)
    },
    // 店铺活动列表
    activityList(params) {
        return axios.post(`${baseUrl}/product/actInfo/pageList`, params)
    },
    //图片上传
    uploadImg(params) {
        return axios.post(`${baseUrl}/product/file_upload/uploadImg`, params)
    },
    // 下架活动
    offAct(params) {
        return axios.get(`${baseUrl}/product/downAct/` + params)
    },
    // 上架活动
    onAct(params) {
        return axios.get(`${baseUrl}/product/upAct/` + params)
    },
    // 编辑活动
    editAct(params) {
        return axios.post(`${baseUrl}/product/updateAct`, params)
    },
    // 删除活动
    delAct(params) {
        return axios.get(`${baseUrl}/product/delAct`, { params: params })
    },
    // 添加活动
    addAct(params) {
        return axios.post(`${baseUrl}/product/addAct`, params)
    },
    // 查询所有商家列表
    searchShop(params) {
        return axios.post(`${baseUrl}/product/shopInfo/list`, params)
    },
    //-------------------------------------------------------------------------
    // 年卡列表
    annuaList(params) {
        return axios.post(`${baseUrl}/product/getCardPage`, params)
    },
    // 启用年卡
    onAnnua(params) {
        return axios.get(`${baseUrl}/product/upCard/` + params)
    },
    // 添加年卡
    addcard(params) {
        return axios.post(`${baseUrl}/product/saveCard`, params)
    },
    // 禁用年卡
    offAnnua(params) {
        return axios.get(`${baseUrl}/product/downCard/` + params)
    },
    // 修改年卡
    editAnnua(params) {
        return axios.post(`${baseUrl}/product/updateCard`, params)
    },
    //删除年卡
    delCard(params) {
        return axios.get(`${baseUrl}/product/delCard/` + params)
    },
    // 会员列表
    userList(params) {
        return axios.post(`${baseUrl}/product/infoCheckList`, params)
    },
    // 审核通过拒绝
    examine(params) {
        return axios.post(`${baseUrl}/product/infoCheck`, params)
    },
    // 删除审核
    examineDel(params) {
        return axios.get(`${baseUrl}/product/infoCheck/del/` + params)
    },
    //-------------------------------------------------------------------------
    //亲子活动列表
    ParentingList(params) {
        return axios.get(`${baseUrl}/product/getGroupActInfo`, params)
    },
    //修改拼团规则
    groupEdit(params) {
        return axios.post(`${baseUrl}/product/updateGroupAct`, params)
    },
    //增加拼团规则
    groupAdd(params) {
        return axios.post(`${baseUrl}/product/addGroupAct`, params)
    },
    //查询拼团
    groupSearch(params) {
        return axios.get(`${baseUrl}/product/getGroupActInfo/` + params)
    },
    // 推首页
    push(params) {
        return axios.post(`${baseUrl}/product/toTop`, params)
    },
    grouppush(params) {
        return axios.post(`${baseUrl}/order/toTop`, params)
    },
    // 查询推首页
    pushSearch(params) {
        return axios.get(`${baseUrl}/product/getTop`, params)
    },
    // 拼团列表
    groupOn(params) {
        return axios.post(`${baseUrl}/order/pageGroup`, params)
    },
    // 查询拼团活动
    Searchgroup(params) {
        return axios.post(`${baseUrl}/product/finAllJoinGroup`, params)
    },
    // 启用团购
    groupons(params) {
        return axios.get(`${baseUrl}/order/startGroup/` + params)
    },
    // 禁用团购
    groupoff(params) {
        return axios.get(`${baseUrl}/order/endGroup/` + params)
    },
    //删除团购
    groupDel(params) {
        return axios.get(`${baseUrl}/order/delGroupAct`, { params: params })
    },
    //新增团购
    Addgr(params) {
        return axios.post(`${baseUrl}/order/addGroupAct`, params)
    },
    // 编辑团购
    EditGr(params) {
        return axios.post(`${baseUrl}/order/updateGroupAct`, params)
    },
    // 团购推首页
    uptopGr(params) {
        return axios.get(`${baseUrl}/order/toTop/` + params)
    },
    // 会员列表
    memberList(params) {
        return axios.post(`${baseUrl}/other/member/pageList`, params)
    },
    //新增审核
    Addexamine(params) {
        return axios.post(`${baseUrl}/product/infoCheck/add`, params)
    },
    // 查询流水
    waterSearch(params) {
        return axios.post(`${baseUrl}/order/findMemberOrder`, params)
    },
    // 订单列表
    orderList(params) {
        return axios.post(`${baseUrl}/order/findOrderPageAdmin`, params)
    },
    // 订单详情
    orderDetailed(params) {
        return axios.post(`${baseUrl}/order/orderInfo/` + params)
    },
    // 团购订单
    Grouplist(params) {
        return axios.post(`${baseUrl}/order/findGroupBuyingCondition`, params)
    },
    // 平台核销查询
    Writeoff(params) {
        return axios.post(`${baseUrl}/order/consume/adminPageList`, params)
    },
    // 店铺核销
    shopWriteoff(params) {
        return axios.post(`${baseUrl}/order/consume/shopPageList`, params)
    },
    // 年卡核销
    cardWrite(params) {
        return axios.post(`${baseUrl}/order/consumeCard`, params)
    },
    //核销活动按钮
    WriteBtn(params) {
        return axios.get(`${baseUrl}/order/consume?code=` + params)
    },
    // 查询核销用户
    cardUser(params) {
        return axios.get(`${baseUrl}/product/infoCheck/code`, { params: params })
    },
    // 查询年卡活动
    searchAct(params) {
        return axios.post(`${baseUrl}/product/cardActByShop`, params)
    },
    //拼团成员
    Gpmember(params) {
        return axios.get(`${baseUrl}/order/getGroupMember/` + params)
    },
    //意见反馈
    feedback(params) {
        return axios.post(`${baseUrl}/other/advicePage`, params)
    },
    //删除建议
    Delfeedback(params) {
        return axios.get(`${baseUrl}/other/advice/del/` + params)
    },
    //省份
    Searchaddress(params) {
        return axios.post(`${baseUrl}/product/region/list`, params)
    },
    //下一级
    citys(params) {
        return axios.post(`${baseUrl}/product/region/restList/` + params)
    },
    //记录查询
    record(params) {
        return axios.post(`${baseUrl}/other/buyTimePage`, params)
    },
    //新增记录
    Addrecord(params) {
        return axios.post(`${baseUrl}/other/buyTime/add`, params)
    },
    //删除记录
    Delrecord(params) {
        return axios.get(`${baseUrl}/other/buyTime/del/` + params)
    },
    //修改记录
    Editrecord(params) {
        return axios.post(`${baseUrl}/other/buyTime/update`, params)
    },
    // 退款申请
    refundList(params) {
        return axios.post(`${baseUrl}/order/returnApplyAll/cancelRequestRefund`, params)
    },
    // 退款状态
    refundOk(params) {
        return axios.post(`${baseUrl}/order/returnApply/processingRefund`, params)
    },
    // 主动退款
    returnIn(params) {
        return axios.post(`${baseUrl}/order/initiative/processingRefund`, params)
    },
    // 退款列表
    returnLi(params) {
        return axios.post(`${baseUrl}/order/refund/page`, params)
    },
    // 退款查询
    returnSee(params) {
        return axios.get(`${baseUrl}/order/getWXReturnResult/` + params)
    },
    //修改用户
    editPWd(params) {
        return axios.post(`${baseUrl}/other/user/updateUser`, params)
    },
    // 问题列表
    problemList(params) {
        return axios.post(`${baseUrl}/product/question/page`, params)
    },
    // 新增问题
    problemAdd(params) {
        return axios.post(`${baseUrl}/product/question/add`, params)
    },
    //删除问题
    problemDel(params) {
        return axios.get(`${baseUrl}/product/question/del/` + params)
    },
    // 修改问题
    problemEdit(params) {
        return axios.post(`${baseUrl}/product/question/update`, params)
    },
    // 隐藏问题
    problemHide(params) {
        return axios.get(`${baseUrl}/product/question/down/` + params)
    },
    // 展示问题
    problemShow(params) {
        return axios.get(`${baseUrl}/product/question/up/` + params)
    },
    // 标签列表
    labelList(params) {
        return axios.post(`${baseUrl}/product/attr/pageList`, params)
    },
    // 新增标签
    labelAdd(params) {
        return axios.post(`${baseUrl}/product/attr/add`, params)
    },
    // 编辑标签
    labelEdit(params) {
        return axios.post(`${baseUrl}/product/attr/update`, params)
    },
    // 删除标签
    labelDel(params) {
        return axios.get(`${baseUrl}/product/attr/del`, { params: params })
    },
    // 出行人列表
    travelerList(params) {
        return axios.post(`${baseUrl}/other/outPage`, params)
    },
    //热门搜索列表
    hotsearchList(params) {
        return axios.post(`${baseUrl}/product/hot/pageList`, params)
    },
    //新增搜索
    hotsearchAdd(params) {
        return axios.post(`${baseUrl}/product/hot/add`, params)
    },
    //编辑搜索
    hotsearchEdit(params) {
        return axios.post(`${baseUrl}/product/hot/update`, params)
    },
    //删除搜索
    hotsearchDel(params) {
        return axios.get(`${baseUrl}/product/hot/del`, { params: params })
    },
    changepwd(params) {
        return axios.get(`${baseUrl}/product/updatePwd`, { params: params })
    },
    // 代金券列表
    voucherList(params) {
        return axios.post(`${baseUrl}/order/coupon/pageList`, params)
    },
    // 新增代金券
    voucheradd(params) {
        return axios.post(`${baseUrl}/order/coupon/add`, params)
    },
    // 修改代金券
    voucheredit(params) {
        return axios.post(`${baseUrl}/order/coupon/update`, params)
    },
    // 删除代金券
    voucherDel(params) {
        return axios.get(`${baseUrl}/order/coupon/del`, { params: params })
    },
    // 启用代金券
    voucherstart(params) {
        return axios.get(`${baseUrl}/order/coupon/start`, { params: params })
    },
    // 禁用代金券
    voucherstop(params) {
        return axios.get(`${baseUrl}/order/coupon/stop`, { params: params })
    },
    //核销代金券
    voucherhx(params) {
        return axios.get(`${baseUrl}/order/consumeCoupon`, { params: params })
    },
    //查询代金券绑定商家
    voucherShop(params) {
        return axios.get(`${baseUrl}/order/coupon/detailAdmin`, { params: params })
    },
    // 秒杀活动列表
    actseckill(params) {
        return axios.post(`${baseUrl}/product/seck/pageList`, params)
    },
    // 新增秒杀活动
    actseckillAdd(params) {
        return axios.post(`${baseUrl}/product/addSeckAct`, params)
    },
    // 编辑秒杀活动
    actseckillEdit(params) {
        return axios.post(`${baseUrl}/product/updateSeckAct`, params)
    },
    // 删除秒杀活动
    actseckillDel(params) {
        return axios.get(`${baseUrl}/product/delSeckAct`, { params: params })
    },
    // 下架秒杀活动
    actseckilloff(params) {
        return axios.get(`${baseUrl}/product/downSeckAct/` + params)
    },
    // 上架秒杀活动
    actseckillon(params) {
        return axios.get(`${baseUrl}/product/upSeckAct/` + params)
    },
    // 商家产品列表
    shopgoods(params) {
        return axios.post(`${baseUrl}/order/pro/pageList`, params)
    },
    returninit(params) {
        return axios.get(`${baseUrl}/product/infoCheck/init`, { params: params })
    },
    getCardList(params) {
        return axios.post(`${baseUrl}/product/getCardList`, params)
    },
    // 查询意见反馈数
    getNewsNum1(params) {
        return axios.get(`${baseUrl}/other/advice/num`, { params: params })
    },
    // 标记已读意见反馈
    readOpinion(params) {
        return axios.post(`${baseUrl}/other/advice/read`, params)
    },
    // 查询退款订单数量
    getNewsNum2(params) {
        return axios.get(`${baseUrl}/order/returnApplyAll/num`, { params: params })
    },
    deleteMemberById(params) {
        return axios.post(`${baseUrl}/other/member/del?id=${params.id}`)
    },
    get_insureInfo(params) {
        return axios.get(`${baseUrl}/order/findInfo/${params.orderSn}`)
    }
}

export default api;

