<template>
  <div class="add">
    <div class="content">
      <!-- 右上 面包屑-->
      <div class="rigth-top">
        <HeaderNav> </HeaderNav>
      </div>
      <!-- 右下 -->
      <div class="rigth-bottom">
        <Card>
          <span slot="title">亲子活动列表</span>
          <div slot="content">
            <el-button type="primary" size="medium" @click="add"
              >新增活动</el-button
            >
            <el-form ref="form" :model="form" style="margin-top: 30px">
              <el-form-item label="搜索">
                <el-input
                  v-model="form.actName"
                  placeholder="请输入活动名称"
                  style="width: 150px; margin-right: 30px"
                ></el-input>
                <el-input
                  v-model="form.shopName"
                  placeholder="商家搜索"
                  style="width: 150px; margin-right: 30px"
                ></el-input>
                <!-- <el-select
                  v-model="form.type"
                  placeholder="活动状态"
                  style="width: 150px; margin-right: 30px"
                >
                  <el-option label="进行中" value="2"></el-option>
                  <el-option label="过期" value="0"></el-option>
                </el-select> -->
                <el-select
                  v-model="form.isGroup"
                  placeholder="活动类型"
                  style="width: 150px; margin-right: 30px"
                >
                  <el-option label="拼团" value="1"></el-option>
                  <el-option label="个体" value="0"></el-option>
                </el-select>
                <el-button type="primary" size="medium" @click="search"
                  >点击搜索</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 商家列表 -->
            <div class="table">
              <el-table :data="tableData" style="width: 100%">
                <!-- 活动ID
                <el-table-column label="活动ID" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.id }}</span>
                  </template>
                </el-table-column> -->
                <!-- 活动名称 -->
                <el-table-column label="活动名称" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.actName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 所属商家 -->
                <el-table-column label="所属商家" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.shopName
                    }}</span>
                  </template>
                </el-table-column>
                <!-- 活动广告图 -->
                <el-table-column label="活动广告图" width="100">
                  <template slot-scope="scope">
                    <div>
                      <img
                        class="bannerImg"
                        :src="scope.row.actDefaultImg"
                        alt=""
                      />
                    </div>
                  </template>
                </el-table-column>
                <!-- 类型 -->
                <el-table-column label="类型" width="100">
                  <template slot-scope="scope">
                    <span>
                      <!-- {{ scope.row.isGroup == 0 ? "个体" : "拼团" }} -->
                      {{
                        scope.row.isGroup == 0
                          ? "个体"
                          : scope.row.isGroup == 1
                          ? "拼团"
                          : scope.row.isGroup == "非团购"
                          ? "个体"
                          : scope.row.isGroup == "团购"
                          ? "拼团"
                          : ""
                      }}
                    </span>
                  </template>
                </el-table-column>
                <!-- 权重 -->
                <el-table-column label="权重" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.sw }}</span>
                  </template>
                </el-table-column>
                <!-- 拼团价格 -->
                <el-table-column label="拼团价格" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.price }}</span>
                  </template>
                </el-table-column>
                <!-- 上下架 -->
                <el-table-column label="是否上下架" width="150">
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.publishStatus == 0 ? "下架" : "上架" }}</span
                    >
                  </template>
                </el-table-column>
                <!-- 库存 -->
                <el-table-column label="库存" width="150">
                  <template slot-scope="scope">
                    <span>{{ scope.row.stock }}</span>
                  </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" width="300">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      @click="on(scope.$index, scope.row)"
                    >
                      {{
                        scope.row.publishStatus == 1 ? "下架" : "上架"
                      }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-pagination
                style="margin: 20px"
                background
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>

            <!-- 编辑的弹窗 -->
            <el-dialog
              title="编辑"
              width="30%"
              :visible.sync="dialogFormVisibless"
            >
              <!-- 表单 -->
              <el-form ref="forms" label-width="110px" :model="editForm">
                <!-- 店铺名称 -->
                <el-form-item label="活动名:">
                  <el-input v-model="editForm.actName"></el-input>
                </el-form-item>
                <el-form-item label="所属平台或商家:">
                  <el-input v-model="editForm.shopName" disabled></el-input>
                </el-form-item>
                <el-form-item label="加入年卡:">
                  <el-select v-model="editForm.isJoin" placeholder="是/否">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 拼团 -->
                <el-form-item label="团购">
                  <el-select
                    v-model="editForm.isGroup"
                    placeholder="请选择是否团购"
                  >
                    <el-option label="非团购" value="0"></el-option>
                    <el-option label="团购" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 上传图片 -->
                <!-- on-preview	点击文件列表中已上传的文件时的钩子 -->
                <!--on-remove 文件列表移除文件时的钩子  -->
                <el-form-item label="活动图:">
                  <el-upload
                    action
                    :before-upload="beforeUpload"
                    :http-request="uploadimg1"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemovesindex"
                    :limit="1"
                    :file-list="imgUrlsIndex"
                  >
                    <i class=""> 建议尺寸:170*128</i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="imageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
                <el-form-item label="活动多图:">
                  <el-upload
                    action
                    :before-upload="beforeUpload"
                    :http-request="uploadimg2"
                    list-type="picture-card"
                    :headers="headers"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :file-list="imgUrl"
                  >
                    <i class=""> 建议尺寸:375*180</i>
                  </el-upload>
                </el-form-item>
                <!-- 活动标签 -->
                <el-form-item label="活动标签:">
                  <el-checkbox-group v-model="label" @change="getLabel">
                    <el-checkbox
                      v-for="(item, index) in labels"
                      :key="item.id"
                      :label="item"
                      :name="index.id"
                      >{{ item.attrName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
                <!-- 活动属性 -->
                <el-form-item label="活动属性:">
                  <el-checkbox-group v-model="labelSx" @change="getLabelSx">
                    <el-checkbox
                      v-for="(item, index) in labelsSx"
                      :key="item.id"
                      :label="item"
                      :name="index.id"
                      >{{ item.attrName }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
                <!-- 分类 -->
                <el-form-item label="活动分类:">
                  <el-select v-model="actType" placeholder="选择分类">
                    <el-option
                      v-for="(item, index) in typeList"
                      :key="index"
                      :label="item.catName"
                      :value="item.catId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- 活动区域 -->
                <el-form-item label="活动区域">
                  <el-select
                    v-model="editForm.isOut"
                    placeholder="请选择活动区域"
                  >
                    <el-option label="室内" value="0"></el-option>
                    <el-option label="室外" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 时间 -->
                <el-form-item label="活动时间:">
                  <el-date-picker
                    type="dates"
                    v-model="time"
                    placeholder="选择一个或多个日期"
                  >
                  </el-date-picker>
                </el-form-item>

                <!-- 价格 -->
                <el-form-item label="价格:">
                  <el-input v-model="editForm.price" type="number"></el-input>
                </el-form-item>
                <!-- 成人价格 -->
                <el-form-item label="原价/成人价格:">
                  <el-input v-model="editForm.originalPrice"></el-input>
                </el-form-item>
                <el-form-item label="最低数量(成):" prop="adultmin">
                  <el-input
                    v-model="editForm.adultBuy"
                    placeholder="填写成人最低购买数量	"
                  ></el-input>
                </el-form-item>
                <el-form-item label="最低数量(童):" prop="childmin">
                  <el-input
                    v-model="editForm.childBuy"
                    placeholder="填写儿童最低购买数量"
                  ></el-input>
                </el-form-item>
                <!-- 已拼 -->
                <el-form-item label="已拼:">
                  <el-input v-model="editForm.saleCount"></el-input>
                </el-form-item>
                <!-- 权重 -->
                <el-form-item label="权重:">
                  <el-input v-model="editForm.sw" type="number"></el-input>
                </el-form-item>
                <!-- 库存 -->
                <el-form-item label="库存(儿童):">
                  <el-input v-model="editForm.stock" type="number"></el-input>
                </el-form-item>
                <el-form-item label="库存(成人):">
                  <el-input
                    v-model="editForm.adultStock"
                    type="number"
                  ></el-input>
                </el-form-item>
                <!-- 活动描述 -->
                <el-form-item label="活动描述:">
                  <el-input v-model="editForm.actIntro"></el-input>
                </el-form-item>
                <!-- 活动详情 -->
                <el-form-item label="活动详情:">
                  <editor-bar
                    v-model="editForm.actDesc"
                    :isClear="isClear"
                    @change="change"
                  ></editor-bar>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibless = false"
                  >取 消</el-button
                >
                <el-button type="primary" @click="okEdit">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </Card>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="500px" height="500px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
// 引入正则
import HeaderNav from "@/components/HeaderNav.vue";
import EditorBar from "@/components/wangEnduit.vue";
import {Message} from "element-ui";

export default {
  components: {
    Card,
    HeaderNav,
    EditorBar,
  },
  data() {
    return {
      isClear: false,
      imageUrl: "", //商品图片
      // 分页
      currPage: 1,
      // 分页的条数
      pageSize: 5,
      pageSizes: 999,
      // 总条数
      total: 1,
      size: 10,
      // 新增弹窗
      dialogFormVisible: false,
      dialogVisible: false,
      dialogFormVisibles: false,
      dialogFormVisibless: false,
      dialogFormVisibleSreach: false,
      dialogImageUrl: "",
      Addform: {
        groupPrice: 0,
      },
      editForm: {},
      Newform: {},
      needNumber: 0,
      groupPrice: 0,
      form: {},
      time: "",
      // 表格数据
      tableData: [],
      timeData: "",
      // 图片
      imgUrl: [],
      imglist: [],
      imgUrlsIndex: [],
      t1: null,
      t2: null,
      shops: [],
      shop: [],
      actType: "",
      typeList: [],
      labels: [],
      label: [],
      labelValue: [],
      labelsSx: [],
      labelSx: [],
      labelValueSx: [],
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  computed: {
    headers() {
      return {
        "backstage-path": localStorage.getItem("token_access"),
      };
    },
  },
  methods: {
    // 上传图片时验证
    beforeUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      }
    },
    uploadimg1(file) {
      const that = this;
      that.imgUrlsIndex = [];
      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrlsIndex.push({ url: v.split("?")[0] });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex");
            } else {
              that.imgUrlsIndex.push({ url: v });
              console.log(that.imgUrlsIndex, "that.imgUrlsIndex1111");
            }
          });
      }
      multipartUpload();
    },
    uploadimg2(file) {
      const that = this;

      async function multipartUpload() {
        console.log(file.file.name);
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = that.$getFileNameUUID() + "." + fileFormat;
        that
          .$client()
          .put(`img/${fileName}`, file.file)
          .then((response) => {
            let v = response.url;
            if (v.split(":")[0] != "https") {
              v = v.replace("http", "https");
            }
            // that.ImageUrl.push({ url: v });
            if (v.indexOf("?") != -1) {
              that.imgUrl.push({
                url: v.split("?")[0],
                name: file.file.name,
              });
              console.log(that.imgUrl, "that.imgUrl");
            } else {
              that.imgUrl.push({ url: v, name: file.file.name });
              console.log(that.imgUrl, "that.imgUrl1111");
            }
          });
      }
      multipartUpload();
    },
    search() {
      let activityList = {
        isGroup: this.form.isGroup,
        actName: this.form.actName,
        shopName: this.form.shopName,
        type: 2,
        page: this.currPage,
        pageSize: this.pageSize,
      };
      this.$api.activityList(activityList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    // 上传图片
    shopheadersuccessIndex(file) {
      this.imgUrlsIndex = [];
      this.imgUrlsIndex.push({
        url: file.result,
      });
    },
    shopheadersuccess(file, fileList) {
      this.imgUrl.push({
        url: file.result,
      });
      this.imglist.push(file.result);
      console.log(this.imgUrl, 9999999999);
    },
    // 展示删除
    handleRemovesindex(file, fileList) {
      this.imgUrlsIndex.splice(-1);
    },
    handleRemove(file, fileList) {
      const filePath = file.url;
      this.imgUrl.forEach((val, index) => {
        if (val.url == filePath) {
          this.imgUrl.splice(index, 1);
        }
      });
      console.log(this.imgUrl, "url");
    },
    // 放大
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // 表格编辑删除
    handleAdd(index, row) {
      console.log(index, row);
      this.Addform = row;
      this.dialogFormVisibles = true;
    },
    // 表格编辑删除
    handleEdit(index, row) {
      console.log(row);
      this.label = [];
      this.labelSx = [];
      this.dialogFormVisibless = true;
      this.editForm = row;
      this.t1 = row.createTime;
      this.t2 = row.endTime;
      this.imgUrlsIndex = [];
      this.imgUrl = [];
      this.imglist = [];
      this.time = [];
      this.time = row.saleTimeList;
      this.actType = row.catId;
      row.isJoin == 1
        ? (this.editForm.isJoin = "是")
        : (this.editForm.isJoin = "否");
      row.isOut == 1
        ? (this.editForm.isOut = "室外")
        : (this.editForm.isOut = "室内");
      row.isGroup == 0
        ? (this.editForm.isGroup = "非团购")
        : (this.editForm.isGroup = "团购");
      this.imgUrlsIndex.push({ url: row.actDefaultImg });
      row.images.forEach((item) => {
        this.imgUrl.push({
          url: item.url,
        });
      });
      row.images.forEach((item) => {
        this.imglist.push(item.url);
      });
      let Label = row.label.split("-");
      // let Labelsx = row.attr.split("-");
      let Labelsx = [];
      row.attrs.forEach((item) => {
        Labelsx.push(item.attrName);
      });

      // 获取已有标签和返回的标签对象对比赋值到页面
      for (let i = 0; i < this.labels.length; i++) {
        for (let j = 0; j < Label.length; j++) {
          if (this.labels[i].attrName == Label[j]) {
            this.label.push(this.labels[i]);
          }
        }
      }
      for (let i = 0; i < this.labelsSx.length; i++) {
        for (let j = 0; j < Labelsx.length; j++) {
          if (this.labelsSx[i].attrName == Labelsx[j]) {
            this.labelSx.push(this.labelsSx[i]);
          }
        }
      }
    },
    handleDelete(index, row) {
      console.log(row);
      let that = this;
      // 删除框
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let id = {
          id: row.id,
        };
        this.$api.delAct(id).then((res) => {
          if (res.status == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.pageList();
          }
        });
        console.log(1111);
      });
    },
    handleSearch(index, row) {
      this.dialogFormVisibleSreach = true;
      let id = row.id;
      this.$api.groupSearch(id).then((res) => {
        if (res.status == 200) {
          this.Addform = row;
          console.log(row);
          this.needNumber = res.result.needNumber;
          this.groupPrice = res.result.groupPrice;
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      // 给当前页数重新赋值
      this.currPage = val;
      // 重绘视图
      this.pageList();
    },

    // 新增广告
    add() {
      this.$router.push("/store/newactivity");
    },
    ok() {},
    oks() {
      let act = {
        actId: this.Addform.id,
        groupPrice: this.Addform.groupPrice,
        needNumber: this.Addform.number,
        shopId: this.Addform.shopId,
      };
      this.$api.groupAdd(act).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibles = false;
          this.$message.success(res.result);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 上下架
    on(index, row) {
      let id = row.id;
      if (row.publishStatus == 0) {
        this.$api.onAct(id).then((res) => {
          if (res.status == 200) {
            this.pageList();
          }else{
            Message.warning(res.message);
          }
        });
      } else if (row.publishStatus == 1) {
        this.$api.offAct(id).then((res) => {
          if (res.status == 200) {
            this.pageList();
          }else{
            Message.warning(res.message);
          }
        });
      }
    },
    okEdit() {
      let arrtime = [];
      this.time.forEach((item) => {
        arrtime.push(this.$dateFormat(new Date(item).getTime(), "Y-m-d"));
      });
      let list = [];
      let listSx = [];
      this.label.forEach((item) => {
        list.push(item.attrName);
      });
      this.labelSx.forEach((item) => {
        listSx.push(item.id);
      });
      var moment = require("moment");
      if (this.editForm.isJoin == "是") {
        this.editForm.isJoin = 1;
      } else if (this.editForm.isJoin == "否") {
        this.editForm.isJoin = 0;
      }
      if (this.editForm.isGroup == "团购") {
        this.editForm.isGroup = 1;
      } else if (this.editForm.isGroup == "非团购") {
        this.editForm.isGroup = 0;
      }
      // this.editForm.isJoin == "加入"
      //   ? (this.editForm.isJoin = 1)
      //   : (this.editForm.isJoin = 0);
      if (this.editForm.isOut == "室外") {
        this.editForm.isOut = 1;
      } else if (this.editForm.isOut == "室内") {
        this.editForm.isOut = 0;
      }
      // this.editForm.isOut == "室内"
      //   ? (this.editForm.isJoin = 0)
      //   : (this.editForm.isJoin = 1);
      moment().format();
      var time1 = this.$moment(this.time[0]).format("YYYY-MM-D");
      var time2 = this.$moment(this.time[1]).format("YYYY-MM-D");
      if (this.time.length == 2) {
        this.t1 = (this.$moment(time1, "YYYY-MM-DD").valueOf() / 1000) * 1000;
        this.t2 = (this.$moment(time2, "YYYY-MM-DD").valueOf() / 1000) * 1000;
      }
      let headerImg = "";
      let img1 = "";
      let img2 = [];
      if (this.imgUrlsIndex.length) {
        this.imgUrlsIndex.forEach((item) => {
          img1 = item.url;
        });
        console.log(img1, "imgUrlsIndex");
      }
      if (this.imgUrl.length) {
        this.imgUrl.forEach((item) => {
          img2.push(item.url);
        });
        console.log(img2, "imgurl");
      }
      let Edit = {
        actDefaultImg: img1,
        img: img2,
        actDesc: this.editForm.actDesc,
        actName: this.editForm.actName,
        isJoin: this.editForm.isJoin,
        price: this.editForm.price,
        shopId: this.editForm.shopId,
        saleTimeList: arrtime,
        id: this.editForm.id,
        stock: this.editForm.stock,
        sw: Number(this.editForm.sw),
        type: this.editForm.type,
        startTime: this.t1,
        endTime: this.t2,
        adultBuy: this.editForm.adultBuy,
        childBuy: this.editForm.childBuy,
        saleCount: this.editForm.saleCount,
        catId: this.actType,
        originalPrice: this.editForm.originalPrice,
        isOut: this.editForm.isOut,
        label: list.join("-"),
        attr: listSx.join("-"),
        actIntro: this.editForm.actIntro,
        isGroup: this.editForm.isGroup,
        adultStock: this.editForm.adultStock,
      };
      console.log(Edit);
      this.$api.editAct(Edit).then((res) => {
        if (res.status == 200) {
          this.$message.success(res.result);
          this.dialogFormVisibless = false;
          this.pageList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    change() {},
    okSearch() {
      let act = {
        actId: this.Addform.id,
        groupPrice: this.Addform.groupPrice,
        needNumber: this.needNumber,
        shopId: this.Addform.shopId,
      };
      this.$api.groupEdit(act).then((res) => {
        if (res.status == 200) {
          this.dialogFormVisibleSreach = false;
          this.$message.success(res.result);
        }
      });
    },
    pageList() {
      let pageList = {
        page: this.currPage,
        pageSize: this.pageSize,
        type: 2,
      };
      this.$api.activityList(pageList).then((res) => {
        if (res.status == 200) {
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    typeSel() {
      let type = {
        page: this.currPage,
        pageSize: this.pageSizes,
        catType: 2,
      };
      this.$api.storetype(type).then((res) => {
        if (res.status == 200) {
          this.typeList = res.result.records;
          console.log(res);
        }
      });
    },
    getLabel(item) {
      this.labelValue = item;
      console.log(item, "item");
    },
    getLabelSx(item) {
      this.labelValueSx = item;
      console.log(item, "item");
    },
    labellist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 0,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labels = res.result.records;
        }
      });
    },
    labelSxlist() {
      let label = {
        page: this.currPage,
        pageSize: 999,
        attrType: 1,
      };
      this.$api.labelList(label).then((res) => {
        if (res.status == 200) {
          this.labelsSx = res.result.records;
        }
      });
    },
  },
  created() {
    this.pageList();
    this.typeSel();
    this.labellist();
    this.labelSxlist();
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input {
  width: 180px;
}
.el-button {
  margin-bottom: 5px;
}
.table {
  margin-top: 20px;
  .bannerImg {
   width: 100px;
    height: 80px;
  }
  .avatar-uploader-icon[data-v-3c47ad84] {
    width: 200px;
    height: 130px;
    line-height: 130px;
  }
  // 商品图片
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border-radius: 5px;
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
}
</style>
